import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import Layout from "@/layout";
import { getToken } from '@/utils/token-manage'
import setting from '@/setting'

export const constantRoutes = [
    {
        path: '/login',
        component: () => import('@/views/login/index'),
        hidden: true
    },

    {
        path: '/404',
        component: () => import('@/views/404'),
        hidden: true
    },
    {
        path: '/',
        component: Layout,
        redirect: '/dashboard',
        meta: { title: '/', icon: 'dashboard' },
        children: [{
            path: 'dashboard',
            name: 'dashboard',
            component: () => import('@/views/dashboard/index'),
            meta: { title: '晓峰云管家', icon: 'icon-yibiaopan1' }
        }]
    },

]

// 需要权限控制
// 可以多级嵌套，但当children只有一个时只显示children，而不显示父路由
export const asyncRoutes = [
    // 系统资料
    {
        path: '/acl',
        component: Layout,
        name: '系统资料',
        redirect: '/acl/menu/list',
        meta: { title: '系统资料', icon: 'icon-jiesuo', permissions: ['code_rule', 'audit_property', 'audit_process', 'certificate', 'permission.user', 'permission.role', 'permission.menu', 'dept', 'group'] },
        children: [
            {
                path: 'user/list',
                name: '用户管理',
                component: () => import('@/views/acl/user/list'),
                meta: { title: '用户管理', icon: 'icon-ziyuan', permissions: ['permission.user'] },
            },
            {
                path: 'staff/list',
                name: 'staff-list',
                component: () => import('@/views/acl/staff/staff-list'),
                meta: { title: '员工管理', icon: 'icon-yonghu', permissions: ['permission.user'] }
            },
            {
                path: 'staff/add-staff',
                name: 'add-staff',
                component: () => import('@/views/acl/staff/add-staff'),
                meta: { title: '添加员工', icon: 'icon-yonghu', permissions: ['permission.user'] },
                hidden: true
            },
            {
                path: 'staff/edit-staff/:staffId',
                name: 'edit-staff',
                component: () => import('@/views/acl/staff/edit-staff'),
                meta: { title: '编辑员工', icon: 'icon-yonghu', permissions: ['permission.user'] },
                props: true,
                hidden: true
            },
            {
                path: 'role/list',
                name: '角色管理',
                component: () => import('@/views/acl/role/list'),
                meta: { title: '角色管理', icon: 'icon-jiaoseguanli', permissions: ['permission.role'] }
            },
            {
                path: 'role/distribution/:id',
                name: '角色权限',
                component: () => import('@/views/acl/role/roleForm'),
                meta: { title: '角色权限', icon: 'icon-jiaoseguanli', permissions: ['permission.role'] },
                hidden: true
            },
            {
                path: 'role/data-role/:id',
                name: '数据权限',
                component: () => import('@/views/acl/role/dataForm'),
                meta: { title: '数据权限', icon: 'icon-jiaoseguanli', permissions: ['permission.role'] },
                hidden: true
            },
            {
                path: 'menu/tree-menu',
                name: '资源管理',
                component: () => import('@/views/acl/menu/tree-menu'),
                meta: { title: '资源管理', icon: 'icon-ziyuan', permissions: ['permission.menu'] }
            },
            {
                path: 'menu/list-menu',
                name: '资源管理-列表',
                component: () => import('@/views/acl/menu/list-menu'),
                meta: { title: '资源管理-列表', icon: 'icon-ziyuan', permissions: ['permission.menu'] },
                hidden: true,
            },
            {
                path: 'company_dept/company_dept',
                name: '部门管理',
                component: () => import('@/views/acl/company_dept/company_dept'),
                meta: { title: '部门管理', icon: 'icon-ziyuan', permissions: ['dept'] },
            },
            {
                // 未控权
                path: 'company_dept/add-company-dept/:companyDeptId',
                name: '添加部门',
                component: () => import('@/views/acl/company_dept/add-company-dept'),
                meta: { title: '添加部门', icon: 'icon-ziyuan', permissions: ['0'] },
                props: true,
                hidden: true,
            },
            {
                // 未控权
                path: 'company_dept/edit-company-dept/:companyDeptId/:parentId',
                name: '编辑部门',
                component: () => import('@/views/acl/company_dept/edit-company-dept'),
                meta: { title: '编辑部门', icon: 'icon-ziyuan', permissions: ['0'] },
                props: true,
                hidden: true,
            },
            {
                // 未控权
                path: 'business_group/group_list',
                name: '业务组管理',
                component: () => import('@/views/acl/group/group_list'),
                meta: { title: '业务组管理', icon: 'icon-ziyuan', permissions: ['group'] },
            },
            {
                // 未控权
                path: 'station',
                name: '本企业职位',
                component: () => import('@/views/acl/stationManage/stationManage'),
                meta: { title: '本企业职位', icon: 'icon-ziyuan', permissions: ['0'] },
                // hidden: true,
            },
            {
                // 未控权
                path: 'certificate',
                name: '证书管理',
                component: () => import('@/views/acl/certificate_type/certificate'),
                meta: { title: '证书管理', icon: 'icon-ziyuan', permissions: ['certificate'] },
                // hidden: true,
            },
            {
                // 未控权
                path: 'auditProcessList',
                name: '审核流程管理',
                meta: { title: '审核流程管理', icon: 'icon-ziyuan', permissions: ['audit_process'] },
                component: () => import('@/views/approval/auditProcessList'),
            },
            {
                // 未控权
                path: 'auditProcess/:id/:audit',
                name: '审核流程编辑',
                meta: { title: '审核流程编辑', icon: 'icon-ziyuan', permissions: ['0'] },
                component: () => import('@/views/approval/auditProcess'),
                hidden: true,
            },
            {
                // 未控权
                path: 'auditProperty',
                name: '审核节点管理',
                meta: { title: '审核节点管理', icon: 'icon-ziyuan', permissions: ['audit_property'] },
                component: () => import('@/views/approval/auditProperty'),
            },
            {
                // 未控权
                path: 'code_rule',
                name: '编号规则管理',
                component: () => import('@/views/acl/receiptCodeRule/ReceiptCodeRuleManage'),
                meta: { title: '编号规则管理', icon: 'icon-ziyuan', permissions: ['code_rule'] },
                // hidden: true,
            },

        ]
    },

    // 文件管理
    {
        path: '/file_system',
        component: Layout,
        name: '文件管理',
        meta: { title: '文件管理', icon: 'icon-jiesuo', permissions: ['busibase.fileDirectory.add', 'busibase.fileDirectory.uploadFile', 'busibase.fileDirectory.editProperty'] },
        children: [

            {
                // 未控权
                path: 'file_directory',
                name: 'file_directory',
                meta: { title: '文件系统', icon: 'icon-ziyuan', permissions: ['busibase.fileDirectory.add', 'busibase.fileDirectory.uploadFile', 'busibase.fileDirectory.editProperty'] },
                component: () => import('@/views/fileSystem/fileDirectory'),
            },



            {
                // 未控权
                path: 'file_property',
                name: 'file_property',
                meta: { title: '文件目录管理', icon: 'icon-ziyuan', permissions: ['busibase.fileDirectory.add', 'busibase.fileDirectory.uploadFile', 'busibase.fileDirectory.editProperty'] },
                component: () => import('@/views/fileSystem/EditFileProperty'),
                hidden: true
            }

        ]
    },

    // 需求申请
    {
        path: '/requirement',
        component: Layout,
        name: 'requirement',
        meta: { title: '需求申请', icon: 'icon-jiesuo', permissions: ['require.list', 'require.edit'] },
        children: [
            {
                path: 'require-list',
                name: 'require-list',
                component: () => import('@/views/requirement/RequireManage'),
                meta: { title: '需求申请管理', icon: 'icon-ziyuan', permissions: ['require.list'] },
            },
            {
                path: 'require-order/:requireOrderId',
                name: 'require-order',
                component: () => import('@/views/requirement/RequireOrder'),
                meta: { title: '需求申请编辑', icon: 'icon-ziyuan', permissions: ['require.edit'] },
                hidden: true
            },


        ]
    },

    // 进货管理
    {
        path: '/purchase',
        component: Layout,
        name: '进货管理',
        meta: { title: '进货管理', icon: 'icon-jiesuo', permissions: ['purchase.list', 'purchase.add', 'purchase.edit'] },
        children: [
            {
                path: 'purchase-list',
                name: '进货单据查询',
                component: () => import('@/views/purchase/purchase-list'),
                meta: { title: '进货单据查询', icon: 'icon-ziyuan', permissions: ['purchase.list'] },
            },
            {
                path: 'purchase-add',
                name: '进货入库',
                component: () => import('@/views/purchase/purchase-add'),
                meta: { title: '进货入库', icon: 'icon-ziyuan', permissions: ['purchase.add'] },
            },
            {
                path: 'purchase-edit/:purchaseId',
                name: 'purchase-edit',
                component: () => import('@/views/purchase/purchase-edit'),
                meta: { title: '进货单详情编辑', icon: 'icon-ziyuan', permissions: ['purchase.edit'] },
                hidden: true,
                props: true
            },

        ]
    },

    // 基础资料
    {
        // 未控权
        path: '/basicInfomation',
        component: Layout,
        name: '基础资料',
        meta: { title: '基础资料', icon: 'icon-jiesuo', permissions: ['busiBase'] },
        children: [
            {
                // 未控权
                path: 'company/listPage',
                name: '公司列表',
                component: () => import('@/views/basicInfomation/companyListPage.vue'),
                meta: { title: '公司列表', icon: 'icon-ziyuan', permissions: ['0'] },
                hidden: true,
            },
            {
                path: 'supplier/listPage',
                name: '供应商列表',
                component: () => import('@/views/basicInfomation/supplier/listPage'),
                meta: { title: '供应商管理', icon: 'icon-ziyuan', permissions: ['buiBase.supplier.baseInfo'] },
            },
            {
                // 未控权
                path: 'client/listPage',
                name: '客户列表',
                component: () => import('@/views/basicInfomation/client/listPage'),
                meta: { title: '客户管理', icon: 'icon-ziyuan', permissions: ['0'] },
            },
            {
                // 未控权
                path: 'transportComp/listPage',
                name: '运输公司列表',
                component: () => import('@/views/basicInfomation/transportComp/listPage'),
                meta: { title: '运输公司管理', icon: 'icon-ziyuan', permissions: ['0'] },
                // hidden: true,
            },
            {
                // 未控权
                path: 'detailPage/:type/:companyId/:staffId/:id/:approval',
                name: '公司资料',
                component: () => import('@/views/basicInfomation/companyDetail'),
                meta: { title: '公司资料', icon: 'icon-ziyuan', permissions: ['0'] },
                hidden: true,
            },
            {
                // 未控权
                path: 'supplier/permission/:id/:type',
                name: '公司授权',
                component: () => import('@/views/basicInfomation/permissionList'),
                meta: { title: '公司授权', icon: 'icon-jiaoseguanli', permissions: ['0'] },
                hidden: true
            },
            {
                // 未控权
                path: 'staff/permission/:companyId/:companyType/:staffId',
                name: '员工授权',
                component: () => import('@/views/basicInfomation/staffPermission'),
                meta: { title: '员工授权', icon: 'icon-jiaoseguanli', permissions: ['0'] },
                hidden: true
            },
            {
                // 未控权
                path: 'staff/permission/:vendorId/:staffId',
                name: '供应商员工授权',
                component: () => import('@/views/basicInfomation/supplier/staffPermission'),
                meta: { title: '员工授权', icon: 'icon-jiaoseguanli', permissions: ['0'] },
                hidden: true
            },
            {
                // 未控权
                path: 'goods/goods-list',
                name: 'goods-list',
                component: () => import('@/views/basicInfomation/goods/goods-list'),
                meta: { title: '产品管理', icon: 'icon-ziyuan', permissions: ['0'] },
            },
            {
                // 未控权
                path: 'showGood/:id',
                name: '产品资料信息',
                component: () => import('@/views/goods/goodsPage'),
                meta: { title: '产品信息', icon: 'icon-ziyuan', permissions: ['0'] },
                hidden: true,
            },
            {
                // 未控权
                path: 'sort',
                name: '商品分类',
                component: () => import('@/views/sort/sortPage'),
                meta: { title: '产品属性管理', icon: 'icon-ziyuan', permissions: ['0'] },
                // hidden: true,
            },
            {
                // 未控权
                path: 'position',
                name: '职位管理',
                component: () => import('@/views/basicInfomation/positionManage'),
                meta: { title: '职位管理', icon: 'icon-ziyuan', permissions: ['0'] },
                // hidden: true,
            },
            {
                // 未控权
                path: 'corporation',
                name: '企业属性管理',
                component: () => import('@/views/basicInfomation/corporation'),
                meta: { title: '企业属性管理', icon: 'icon-ziyuan', permissions: ['0'] },
                // hidden: true,
            },
        ]

    },

    //合同管理
    {
        // 未控权
        path: '/contract',
        component: Layout,
        name: '合同管理',
        meta: { title: '合同管理', icon: 'icon-jiesuo', permissions: ['contract'] },
        children: [
            {
                // 未控权
                path: 'list',
                name: '合同列表',
                meta: { title: '合同列表', icon: 'icon-ziyuan', permissions: ['0'] },
                component: () => import('@/views/contract/contractListPage'),
                hidden: true
            },
            {
                // 未控权
                path: 'customerContractList',
                name: '客户合同列表',
                meta: { title: '客户合同列表', icon: 'icon-ziyuan', permissions: ['0'] },
                component: () => import('@/views/contract/customerContractList'),
            },
            {
                // 未控权
                path: 'contract/editContract/:id/:type',
                name: '合同信息',
                meta: { title: '合同信息', icon: 'icon-ziyuan', permissions: ['0'] },
                component: () => import('@/views/contract/contractPage'),
                hidden: true,
            },
            {
                // 未控权
                path: 'contractProperty',
                name: '合同属性管理',
                meta: { title: '合同属性管理', icon: 'icon-ziyuan', permissions: ['0'] },
                component: () => import('@/views/contract/contractProperty'),
            },
            {
                // 未控权
                path: 'contractTemplate',
                name: '合同模板管理',
                meta: { title: '合同模板管理', icon: 'icon-ziyuan', permissions: ['0'] },
                component: () => import('@/views/contract/contractTemplate'),
            },
        ]
    },

    //审核管理
    {
        // 未控权
        path: '/approval',
        component: Layout,
        name: '审核管理',
        meta: { title: '审核管理', icon: 'icon-jiesuo', permissions: ['approval.vendorApproval', 'approval.customerApproval', 'approval.transportApproval'] },
        children: [
            {
                // 未控权
                path: 'approvalList/:type',
                name: '审核列表',
                meta: { title: '审核列表', icon: 'icon-jiaoseguanli', permissions: ['0'] },
                component: () => import('@/views/approval/approvalList'),
                hidden: true
            },
            {
                path: 'approval/vendorList',
                name: '供应商审核列表',
                meta: { title: '供应商审核列表', icon: 'icon-jiaoseguanli', permissions: ['approval.vendorApproval'] },
                component: () => import('@/views/approval/vendor/vendorList'),
            },
            {
                path: 'approval/customerList',
                name: '客户审核列表',
                meta: { title: '客户审核列表', icon: 'icon-jiaoseguanli', permissions: ['approval.customerApproval'] },
                component: () => import('@/views/approval/customer/customerList'),
            },
            {
                path: 'approval/transportList',
                name: '运输公司审核列表',
                meta: { title: '运输公司审核列表', icon: 'icon-jiaoseguanli', permissions: ['approval.transportApproval'] },
                component: () => import('@/views/approval/transportComp/transportList'),
            },
            {
                path: 'approval/company/:id',
                name: '公司资料审核',
                component: () => import('@/views/approval/approvalDetail'),
                meta: { title: '公司资料审核', icon: 'icon-jiaoseguanli', permissions: ['0'] },
                hidden: true
            },
        ]
    },

    //销售管理页面
    {
        path: '/sale',
        component: Layout,
        name: '销售管理',
        meta: { title: '销售管理', icon: 'icon-jiesuo', permissions: ['sale.saleEx', 'sale.orderEx', 'sale.orderExList', 'sale.saleExList', 'sale.saleTransfer', 'sale.saleTransport'] },
        children: [
            {
                path: 'order-ex/:orderId',
                name: 'order-ex',
                component: () => import('@/views/sale/order-ex.vue'),
                meta: { title: '订单', icon: 'icon-ziyuan', permissions: ['sale.orderEx'] },
                hidden: true
            },
            {
                path: 'order-list',
                name: '订单管理',
                component: () => import('@/views/sale/order-ex-list.vue'),
                meta: { title: '订单管理', icon: 'icon-ziyuan', permissions: ['sale.orderExList'] }
            },
            {
                path: 'sale-ex',
                name: '销售单',
                component: () => import('@/views/sale/sale-ex.vue'),
                meta: { title: '销售单', icon: 'icon-ziyuan', permissions: ['sale.saleEx'] },
                hidden: true
            },

            {
                path: 'sale-list',
                name: '销售单管理',
                component: () => import('@/views/sale/sale-ex-list.vue'),
                meta: { title: '销售单管理', icon: 'icon-ziyuan', permissions: ['sale.saleExList'] }
            },
            {
                path: 'sale-transfer',
                name: '调拨单',
                component: () => import('@/views/sale/sale-transfer.vue'),
                meta: { title: '调拨单', icon: 'icon-ziyuan', permissions: ['sale.saleTransfer'] }
            },
            {
                path: 'sale-transport',
                name: '运输单',
                component: () => import('@/views/sale/sale-transport.vue'),
                meta: { title: '运输单', icon: 'icon-ziyuan', permissions: ['sale.saleTransport'] }
            }
        ]
    },

    //项目计划
    {
        path: '/project',
        component: Layout,
        name: '项目管理',
        meta: { title: '项目管理', icon: 'icon-jiesuo', permissions: ['sale.saleEx', 'sale.orderEx', 'sale.orderExList', 'sale.saleExList', 'sale.saleTransfer', 'sale.saleTransport'] },
        children: [
            {
                path: 'order-ex',
                name: '订单',
                component: () => import('@/views/sale/order-ex.vue'),
                meta: { title: '订单', icon: 'icon-ziyuan', permissions: ['sale.orderEx'] },
                hidden: true
            },
            {
                path: 'order-ex/list',
                name: '项目计划',
                component: () => import('@/views/sale/order-ex-list.vue'),
                meta: { title: '项目计划', icon: 'icon-ziyuan', permissions: ['sale.orderExList'] }
            },
            {
                path: 'project-manage',
                name: 'project-manage',
                component: () => import('@/views/projectManagement/ProjectManage.vue'),
                meta: { title: '任务安排', icon: 'icon-ziyuan', permissions: ['sale.saleEx'] }
            },
            {
                path: 'project-manage-edit',
                name: 'project-manage-edit',
                component: () => import('@/views/projectManagement/ProjectImplementSlip.vue'),
                meta: { title: '编辑任务安排', icon: 'icon-ziyuan', permissions: ['sale.saleEx'] },
                hidden: true
            },
            {
                path: 'project-impl',
                name: '项目实施',
                component: () => import('@/views/projectManagement/ProjectImplement.vue'),
                meta: { title: '项目实施', icon: 'icon-ziyuan', permissions: ['sale.saleTransport'] }
            }
        ]
    },
   
    //小程序主题管理
    {
        path: '/Minitopic',
        component: Layout,
        name: '小程序管理',
        meta: { title: '小程序管理', icon: 'icon-jiesuo', permissions: ['minitopic'] },
        // alwaysShow: true,
        children: [
            {
                path: "mpSysValue",
                name: '系统参数',
                component: () => import('@/views/topic/topicSysValue'),
                meta: { title: '系统参数', icon: 'icon-ziyuan', permissions: ['0'] },

            },
            {
                path: "Minitopic",
                name: '主题管理',
                component: () => import('@/views/topic/topicManage'),
                meta: { title: '主题模板管理', icon: 'icon-ziyuan', permissions: ['0'] },
                // alwaysShow:true,
                // hidden: true
            },
            {
                // 未控权
                path: '/:topicId/:topicContentId/:layoutType/:layoutTypeLabel',
                name: 'topicContent',
                component: () => import('@/views/topic/topicContentDetail'),
                meta: { title: '主题内容查看', icon: 'icon-ziyuan', permissions: ['0'] },
                hidden: true,
            },
            {
                // 未控权
                path: 'topicPicDetailAd/:id/:goodsId',
                name: '主题内容查看广告',
                component: () => import('@/views/topic/topicContentDetailAd'),
                meta: { title: '主题内容查看广告', icon: 'icon-ziyuan', permissions: ['0'] },
                hidden: true,
            },

            {
                // 未控权
                path: 'deptApp',
                name: 'deptApp',
                component: () => import('@/views/deptApp/DeptAppManage'),
                meta: { title: '小程序管理', icon: 'icon-ziyuan', permissions: ['0'] },
            },

            {
                path: 'mptopic/:deptAppId/:deptId/:deptName',
                name: 'deptAppToptic',
                component: () => import('@/views/topic/topicManage'),
                meta: { title: '内容管理', icon: 'icon-ziyuan', permissions: ['0'] },
                hidden: true,
            }

        ],
        // redirect: 'topic', // 将父路由重定向到子路由路径  
    },
    /*
        // {
        //     path:'/stock',
        //     component:Layout,
        //     name:'库存管理',
        //     meta:{title:'库存管理',icon: 'icon-jiesuo',permissions: ['']},
        //     // alwaysShow: true,
        //     children:[
        //         {
        //             path:"stock",
        //             name:'库存管理',
        //             component:() => import('@/views/stock/stockManage'),
        //             meta:{title:'库存管理',icon: 'icon-ziyuan',permissions: ['0']},
        //             // alwaysShow:true,
        //             // hidden: true
        //         },
        //         {
        //             // 未控权
        //             path: 'topicDetail/:id/:goodsId',
        //             name: '主题内容查看',
        //             component: () => import('@/views/topic/topicPicDetail'),
        //             meta: { title: '主题内容查看',icon: 'icon-ziyuan',permissions: ['0'] },
        //             hidden: true,
        //         },
                          
        //     ],
        //     // redirect: 'topic', // 将父路由重定向到子路由路径  
        // },
        */
    //问卷系统
    {
        path: '/questionnaire',
        component: Layout,
        name: '问卷系统',
        meta: { title: '问卷系统', icon: 'icon-jiesuo', permissions: ['questionnaire'] },
        children: [
            {
                path: 'business',
                name: '查看业务',
                meta: { title: '业务列表', icon: 'icon-jiaoseguanli', permissions: ['0'] },
                component: () => import('@/views/questionnaire/business'),
                // hidden: true,
            },
            {
                path: 'question',
                name: '问题列表',
                meta: { title: '问题列表', icon: 'icon-jiaoseguanli', permissions: ['0'] },
                component: () => import('@/views/questionnaire/question'),
                // hidden: true,
            },

            {
                path: 'checkQuestion/:id',
                name: '查看问题',
                meta: { title: '查看问题', icon: 'icon-jiaoseguanli', permissions: ['0'] },
                component: () => import('@/views/questionnaire/checkQuestion'),
                hidden: true,
            },
            {
                path: 'verifyQuestion/:id',
                name: '审核问题',
                meta: { title: '审核问题', icon: 'icon-jiaoseguanli', permissions: ['0'] },
                component: () => import('@/views/questionnaire/verifyQuestion'),
                hidden: true,
            },

            {
                path: 'addQuestionConment',
                name: '新增问题集',
                meta: { title: '新增问题集', icon: 'icon-jiaoseguanli', permissions: ['0'] },
                component: () => import('@/views/questionnaire/addQuestionConment'),
                hidden: true,
            },
            {
                path: 'questionSet',
                name: '问题集',
                meta: { title: '问题集', icon: 'icon-jiaoseguanli', permissions: ['0'] },
                component: () => import('@/views/questionnaire/questionSet'),
            },
            {
                path: 'checkQuestionComment/:questionSetId/:flag',
                name: '查看问题集',
                meta: { title: '查看问题集', icon: 'icon-jiaoseguanli', permissions: ['0'] },
                component: () => import('@/views/questionnaire/checkQuestionComment'),
                hidden: true,
            },
            {
                path: 'verifyQuestionnaire/:id',
                name: '审核问卷',
                meta: { title: '审核问卷', icon: 'icon-jiaoseguanli', permissions: ['0'] },
                component: () => import('@/views/questionnaire/verifyQuestionnaire'),
                hidden: true,
            },
            {
                path: 'answerPage',
                name: '问卷列表',
                meta: { title: '问卷列表', icon: 'icon-jiaoseguanli', permissions: ['0'] },
                component: () => import('@/views/questionnaire/answerPage'),
                // hidden: true,
            },
            {
                path: 'checkQuestionnaire/:id/:flag',
                name: '编辑问卷',
                meta: { title: '编辑问卷', icon: 'icon-jiaoseguanli', permissions: ['0'] },
                component: () => import('@/views/questionnaire/checkQuestionnaire'),
                hidden: true,
            },

            {
                path: 'checkAllSendQuesionnaire',
                name: '已发送问卷',
                meta: { title: '已发送问卷', icon: 'icon-jiaoseguanli', permissions: ['0'] },
                component: () => import('@/views/questionnaire/checkAllSendQuesionnaire'),
            },
            {
                path: 'replay/:id/:flag/:isCheck/:staffId/:customerId',
                name: '答题页面',
                meta: { title: '答题页面', icon: 'icon-jiaoseguanli', permissions: ['0'] },
                component: () => import('@/views/questionnaire/replay'),
                hidden: true,
            },
            {
                path: 'customerReplay/:id/:customer/:flag',
                name: '用户问卷',
                meta: { title: '用户问卷', icon: 'icon-jiaoseguanli', permissions: ['0'] },
                component: () => import('@/views/questionnaire/customerReplay'),
                hidden: true,
            },
            {
                path: 'myQuestionnaire',
                name: '我的问卷',
                meta: { title: '我的问卷', icon: 'icon-jiaoseguanli', permissions: ['0'] },
                component: () => import('@/views/questionnaire/myQuestionnaire'),
            },
            {
                path: 'questionnaireStatistics/:questionnaireId',
                name: '问卷统计',
                meta: { title: '问卷统计', icon: 'icon-jiaoseguanli', permissions: ['0'] },
                component: () => import('@/views/questionnaire/questionnaireStatistics'),
                hidden: true,
            },

            {
                path: 'analysisOrder',
                name: 'analysisOrder',
                meta: { title: '问卷分析预约', icon: 'icon-jiaoseguanli', permissions: ['0'] },
                component: () => import('@/views/questionnaire/AnalysisOrderManage'),
            },
            {
                path: 'editAnalysisOrder/:orderId',
                name: 'editAnalysisOrder',
                meta: { title: '问卷分析预约详情', icon: 'icon-jiaoseguanli', permissions: ['0'] },
                component: () => import('@/views/questionnaire/EditAnalysisOrder'),
                hidden: true
            },
            {
                path: 'articleCategory',
                name: 'articleCategory',
                meta: { title: '知识库分类', icon: 'icon-jiaoseguanli', permissions: ['0'] },
                component: () => import('@/views/knowledgeBase/ArticleCategoryManage'),
            },
            {
                path: 'knowledgeBasePage',
                name: 'knowledgeBasePage',
                meta: { title: '知识库管理', icon: 'icon-jiaoseguanli', permissions: ['0'] },
                component: () => import('@/views/knowledgeBase/ArticleManage'),
            },
            
            {
                path: 'editArticle/:titleId',
                name: 'editArticle',
                meta: { title: '编辑文章', icon: 'icon-jiaoseguanli', permissions: ['0'] },
                component: () => import('@/views/knowledgeBase/EditArticle'),
                hidden: true
            }
        ]
    },

    // 工厂管理
    {
        // 未控权
        path: '/factory',
        component: Layout,
        name: '工厂管理',
        meta: { title: '工厂管理', icon: 'icon-jiesuo', permissions: ['factory'] },
        children: [
            {
                // 未控权
                path: 'processing/list',
                name: '加工单查询',
                component: () => import('@/views/factory/processing-list.vue'),
                meta: { title: '加工单查询', icon: 'icon-ziyuan', permissions: ['0'] },
                // hidden: true,
            },
            {
                // 未控权
                path: 'processing',
                name: '加工单',
                component: () => import('@/views/factory/processing-detail.vue'),
                meta: { title: '加工单', icon: 'icon-ziyuan', permissions: ['0'] },
                // hidden: true,
            },
        ]
    },
    //对账管理
    {
        path: '/bill',
        component: Layout,
        name: '对账管理',
        meta: { title: '对账管理', icon: 'icon-jiesuo', permissions: ['reconciliation', 'reconciliation.cashregister', 'reconciliation.cashregister', 'reconciliation.voucher', 'reconciliation.access', 'reconciliation.task'] },
        children: [
            {
                path: 'Cron',
                name: '定时任务管理',
                component: () => import('@/views/accountingRecord/ACron.vue'),
                meta: { title: '定时任务管理', icon: 'icon-ziyuan', permissions: ['0'] },
                // hidden: true,
            },
            {
                path: 'payment',
                name: '收银方式管理',
                component: () => import('@/views/accountingRecord/PaymentManage.vue'),
                meta: { title: '收银方式管理', icon: 'icon-ziyuan', permissions: ['reconciliation.cashregister'] },
                // hidden: true,
            },
            {
                path: 'voucher',
                name: '代金券管理',
                component: () => import('@/views/accountingRecord/VoucherManage.vue'),
                meta: { title: '代金券管理', icon: 'icon-ziyuan', permissions: ['reconciliation.voucher'] },
                // hidden: true,
            },

            {
                path: 'template',
                name: '取数规则管理',
                component: () => import('@/views/accountingRecord/TemplateManage.vue'),
                meta: { title: '取数规则管理', icon: 'icon-ziyuan', permissions: ['reconciliation.access'] },
                // hidden: true,
            },
            {
                path: 'task',
                name: 'bill-task',
                component: () => import('@/views/accountingRecord/TaskManage.vue'),
                meta: { title: '对账任务管理', icon: 'icon-ziyuan', permissions: ['reconciliation.task'] },
                // hidden: true,
            },

            {
                path: 'template/edit/:templateId',
                name: '取数规则编辑',
                component: () => import('@/views/accountingRecord/EditTemplate.vue'),
                meta: { title: '取数规则编辑', icon: 'icon-ziyuan', permissions: ['0'] },
                hidden: true
            },

            // {
            //     path: 'import',
            //     name: '数据导入',
            //     component: () => import('@/views/accountingRecord/DataImport.vue'),
            //     meta: { title: '数据导入',icon: 'icon-ziyuan',permissions: [''] },
            //     // hidden: true,
            // },
            // {
            //     path: 'check-balance',
            //     name: '执行对账',
            //     component: () => import('@/views/accountingRecord/CheckBalance.vue'),
            //     meta: { title: '执行对账',icon: 'icon-ziyuan',permissions: [''] },
            //     // hidden: true,
            // },
            {
                path: 'task/import-data/:taskId',
                name: 'import-data',
                component: () => import('@/views/accountingRecord/DataImport.vue'),
                meta: { title: '数据导入', icon: 'icon-ziyuan', permissions: ['0'] },
                hidden: true
            },
            {
                path: 'task/import-cash-data/:taskId',
                name: 'import-cash-data',
                component: () => import('@/views/accountingRecord/CashDataImport.vue'),
                meta: { title: '流水数据导入', icon: 'icon-ziyuan', permissions: ['0'] },
                hidden: true
            },
            {
                path: 'task/check-balance/:taskId',
                name: 'check-balance',
                component: () => import('@/views/accountingRecord/CheckBalance.vue'),
                meta: { title: '执行对账', icon: 'icon-ziyuan', permissions: ['0'] },
                hidden: true
            },

            {
                path: 'task/check-cash-balance/:taskId',
                name: 'check-cash-balance',
                component: () => import('@/views/accountingRecord/CheckCashBalance.vue'),
                meta: { title: '执行银行流水对账', icon: 'icon-ziyuan', permissions: ['0'] },
                hidden: true
            },

            // {
            //     path: 'task/editor',
            //     name: 'editor',
            //     component: () => import('@/views/accountingRecord/TestRichEditor.vue'),
            //     meta: { title: 'WangEditor', icon: 'icon-ziyuan', permissions: ['0'] },
            // },
        ]
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: constantRoutes
})

router.beforeEach(async (to, from, next) => {
    const hasToken = getToken()


    if (hasToken) {
        if (to.path === '/login') {
            next({ path: '/' })
        } else {

            if (setting.enablePermissionControl) {
                const hasRoles = store.state.user.roles && store.state.user.roles.length > 0
                if (hasRoles) {
                    next()
                } else {
                    try {
                        const permissions = await store.dispatch('user/currentUserPermission')
                        const { authorities } = await store.dispatch('user/getLoginInfo')
                        const accessRoutes = await store.dispatch('permission/generateRoutes', {
                            roles: authorities,
                            permissions: permissions
                        })

                        accessRoutes.forEach(item => {
                            router.addRoute(item);
                        });

                        next({ ...to, replace: true })

                    } catch (err) {
                        console.log(err)
                        await store.dispatch('user/resetToken')
                        next(`/login`)
                    }
                }
            } else {
                if (!store.state.permission.addRoutes || store.state.permission.addRoutes.length === 0) {
                    //开发测试使用
                    store.commit('user/SET_ROLES', ['ROLE_ROOT'])
                    const accessRoutes = await store.dispatch('permission/generateRoutes', {
                        roles: ['ROLE_ROOT'],
                        permissions: []
                    })
                    accessRoutes.forEach(item => {
                        router.addRoute(item);
                    });
                    next({ ...to, replace: true })
                } else {
                    next()
                }
            }

        }
    } else {
        if (to.path === '/login') {
            next()
        } else {
            next(`/login`)
        }
    }


})

router.afterEach(() => {

})


export function resetRouter() {
    const newRouter = createRouter({
        history: createWebHistory(process.env.BASE_URL),
        routes: constantRoutes
    })
    router.matcher = newRouter.matcher // reset router
}


export default router
