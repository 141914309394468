import request from '@/utils/request'

export default  {
    getSort() {
        return request({
            method: 'get',
            url: '/buiBase/sort/getSort',
        })
    },

    getSortByParentId(parentId) {
        return request({
            method: 'get',
            url: '/buiBase/sort/getSortByParentId',
            params: {
                parentId: parentId,
            },
        })
    },

    getSortDtoByParentId(parentId) {
        return request({
            method: 'get',
            url: '/buiBase/sort/getSortDtoByParentId',
            params: {
                parentId: parentId,
            },
        })
    },
    
    getAllKindDef() {
        return request({
            method: 'get',
            url: '/buiBase/goods/getAllGoodType',
        })
    },
 //   saveEdit(id, name, code) {
    saveEdit(sortitem) {
        return request({
            method: 'POST',
            url: '/buiBase/sort/editSort',
            data: sortitem
            // params: {
                
                // id: id,
                // name: name,
                // code: code,
            // },
        })
    },

    addSort(data) {
        return request({
            method: 'POST',
            url: '/buiBase/sort/createSort',
            data: data
        })
    },

    delSort(id) {
        return request({
            method: 'GET',
            url: '/buiBase/sort/delSort',
            params: {
                id: id,
            },
        })
    },

    
    getParentIdListBySortId(sortId) {
        return request({
            method: 'GET',
            url: '/buiBase/sort/getParentIdListBySortId',
            params: {
                sortId: sortId,
            },
        })
    },

    getContractTemplateParentId(contractTemplateId) {
        return request({
            method: 'GET',
            url: '/buiBase/sort/getAllParentIdByContractTemplateId',
            params: {
                contractTemplateId: contractTemplateId,
            },
        })
    },
 
    //产品分类属性
    getSortPropertyBySortID(sortId) {
        return request({
            method: 'GET',
            url: '/buiBase/sortProperty/getSortPropertyBySortID',
            params: {
                sortId: sortId,
            },
        })
    },


    addOrUpdateBatch(sortPropertyItem) {
        return request({
            method: 'POST',
            url: '/buiBase/sortProperty/addOrUpdateBatch',
            data: sortPropertyItem
        })
    },

    registerCompany(data) {
        return request({
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'post',
            url: '/buiBase/registerCompany',
            data: data,
        })
    },
    saveChange(goodId, sortId) {
        return request({
            method: 'GET',
            url: '/buiBase/goods/changeGoodSort',
            params: {
                goodId: goodId,
                sortId: sortId,
            }
        })
    },

     //分类的合同模板分页查询
     getSaleContractTemplates() {
        return request({
            method: 'get',
            url: '/buiBase/contract/getSaleContractTemplates',
        })
    },  

    getAllIcon(){
        return request({
            method:'GET',
            url:'/buiBase/function/getAllIcon'
        })
    },

}
