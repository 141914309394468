<template>
    <div id="Mask"> 
        <div class="mask" v-loading="loading"
            :element-loading-spinner="svg"
            element-loading-background="rgba(122, 122, 122, 0.8)"> 
            <el-button type="info" @click="closeMask" class="close-button" circle>
                <el-icon><CloseBold /></el-icon>
            </el-button>
            <slot name="body" style="height: 100%;"></slot>
        </div>
    </div>
</template>
<script>
   

    export default {
        name: "Mask",
        emits:['onClose'],
        props: {
            loading: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
               
            }
        },
        methods: {
            closeMask() {
                this.$emit('onClose');
            }
        },
    }
</script>

<style scoped>

    .mask {
        z-index: 3000;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .5);
        margin: auto;
        display: flex; 
        justify-content: center;
        align-items: center;
    }

    .close-button {
        position: absolute;
        background-color: var(--el-text-color-regular);
        border: none; 
        width: 44px; 
        height: 44px;
        top: 45px; /* 距离顶部20px */  
        right: 45px; /* 距离右边20px */  
        z-index: 4000;
    }

</style>
