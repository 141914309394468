<template>
    <el-dialog title="请选择产品信息" :fullscreen="false" width="90%" @close="handleClose"> 

        <!-- 产品列表 -->
        <el-table ref="tableRef" class="el-table" height="300" :data="tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)" border size="small" @select-all="handleSelectAll" @select="handleSelectionChange">
            <el-table-column fixed type="selection" width="40"/>
            <el-table-column fixed type="index" label="序号" align="center" width="50"/>
            <el-table-column label="产品编号" prop="goodsCode" sortable align="center" width="150" :show-overflow-tooltip="true"/>
            <el-table-column fixed label="产品名称" prop="goodsName" align="center" width="150" :show-overflow-tooltip="true"/>
            <el-table-column label="单位" prop="baseName" sortable align="center" width="70" :show-overflow-tooltip="true"/>
            <el-table-column label="数量" prop="qty" sortable align="center" width="70" :show-overflow-tooltip="true"/>
            <el-table-column label="完成数量" prop="outQty" sortable align="center" width="90" :show-overflow-tooltip="true"/>
            <el-table-column label="单价(元)" prop="salePrice" sortable align="center" width="90" :show-overflow-tooltip="true"/>
            <el-table-column label="税额(元)" prop="taxAmount" sortable align="center" width="90" :show-overflow-tooltip="true"/>
            <el-table-column label="金额(含税)(元)" prop="amount" sortable align="center" width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="税率" prop="saleTax" sortable align="center" width="70" :show-overflow-tooltip="true"/>
            <el-table-column label="折扣率" prop="discount" sortable align="center" width="80" :show-overflow-tooltip="true"/>
            <el-table-column label="实施人" prop="implementer" align="center" width="200" :show-overflow-tooltip="true"/>
            <el-table-column label="有效期起始时间" prop="validityStart" sortable align="center" width="150" :show-overflow-tooltip="true"/>
            <el-table-column label="有效期到期时间" prop="validityEnd" sortable align="center" width="150" :show-overflow-tooltip="true"/>
            <el-table-column label="备注" prop="remarks" align="center" width="150" :show-overflow-tooltip="true"/>
        </el-table>

        <!-- 分页 -->
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, prev, pager, next"
          :total="tableData.length">
        </el-pagination>

        <!-- 确认或取消 -->
        <template #footer>
            <span class="dialog-footer">
                <el-button type="primary" @click="handleConfirm">确认</el-button>
                <el-button @click="handleClose">取消</el-button>
            </span>
        </template>

    </el-dialog>
</template>

<script>
    import contractGoodsItem from "@/api/buiBase/contractGoodsItem"
    import corporation from '@/api/buiBase/corporation'

    export default {
        name: "contractDialog",
        emits:['update:modelValue', 'handleConfirm'],
        props: {
            isSingleChoice: {
                type: String,
                default: false,
            },
            contractId: {
                type: String,
            },
            companyType: {
                type: String,
            },
        },
        data() {
            return {
                tableData: [], //产品列表
                selectedRow: [], //选中行
                currentPage: 1, //当前页
                pageSize: 10, //每页行数
            }
        },
        mounted() {
            //获取产品列表
            this.getGoodsItem()
        },
        methods: {
            //获取产品列表
            async getGoodsItem() {
                await contractGoodsItem.getGoodsItemByContract(this.contractId, this.companyType).then(res=>{
                    if (res != null && res.code === 200) {
                        let goodsList = res.data
                        goodsList.forEach(item => {
                            if(item.amount != null) {
                                item.amount = item.amount.toFixed(2)
                            }
                            if(item.discount != null) {
                                item.discount = item.discount.toFixed(2)
                            }
                            if(item.qty != null) {
                                item.qty = item.qty.toFixed(1)
                            }
                            if(item.outQty != null) {
                                item.outQty = item.outQty.toFixed(1)
                            }
                            if(item.salePrice != null) {
                                item.salePrice = item.salePrice.toFixed(2)
                            }
                            if(item.saleTax != null) {
                                item.saleTax = item.saleTax.toFixed(2)
                            }
                            if(item.taxAmount != null) {
                                item.taxAmount = item.taxAmount.toFixed(2)
                            }
                        })
                        this.tableData = goodsList
                    }else {
                        console.log(res.msg)
                    }
                }).catch(e => {
                    console.log(e)
                })
            },
            //确认选择
            handleConfirm() {
                this.$emit('handleConfirm', this.selectedRow)
                this.$refs.tableRef.clearSelection()
            },
            //关闭弹窗
            handleClose() {
                this.selectedRow = []
                this.$emit('update:modelValue', false)
            },
            //列表单选实现
            handleSelectionChange(selection, val) {
                if(this.isSingleChoice) {
                    this.$refs.tableRef.clearSelection()
                    if (selection.length == 0){
                        this.selectedRow = []
                        return
                    };
                    this.$refs.tableRef.toggleRowSelection(val, true)
                    this.selectedRow = val
                }else {
                    this.selectedRow = selection
                }
            },
            //全选时清空选择
            handleSelectAll(selection) {
                if(this.isSingleChoice) {
                    this.$refs.tableRef.clearSelection()
                    this.selectedRow = []
                }else {
                    this.selectedRow = selection
                }
            },
            //切换页号
            handleCurrentChange(val) {
                this.currentPage = val
            },
        },
    }
</script>

<style scoped>

  .dialog-input {
    width: 460px;
  }

  .el-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .el-table {
      margin-top: 10px;
  }

</style>
