<template>

  <div style="height: 100%">
    <el-scrollbar wrap-class="scrollbar-wrapper" style="height: 100%">
      <el-menu
          :collapse-transition="false"
          :router="true"
          background-color="#404040"
          text-color="#ffffff"
          mode="vertical"
          style="min-height: 100vh;height: 100%; border-right: none;">
        <sidebar-item v-for="(route,index) in routes" v-bind:key="index" :item="route"/>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import SidebarItem from './SidebarItem'
import {mapState} from 'vuex'
export default {
  name: "Sidebar",
  components: {SidebarItem},
  computed: {
    ...mapState('permission',['routes'])
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">

</style>
