<script setup>
  import Editor from '@tinymce/tinymce-vue'
</script>

<template>
  <main id="sample">
    <Editor
      api-key="3oumrq248ox2dnybbhnrdcjrrrtxyivh62oyxy3k2d3krlla"
      :init="initData"
      v-model="content"
    />
   
  </main>
</template>

<script>
   
  export default {
    name: "tinymce",
    props: {
      height: {
        type: Number,
        default: 640
      }  
    },
    components: {
        
    },
      
    data() {
        return {
          content: '',
          initData: {
            language: 'zh_CN', // 设置语言为中文
            language_url: '/tinymce/zh_CN.js', // 中文语言包的路径
            toolbar_mode: 'sliding',
            plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate mentions tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss markdown',
            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
            tinycomments_mode: 'embedded',
            tinycomments_author: 'Author name',
            height: this.height,
            mergetags_list: [
              { value: 'First.Name', title: 'First Name' },
              { value: 'Email', title: 'Email' },
            ]
          }
        }
    },
    created() {
        
    },
    methods: {
        handleSubmit() {
          console.log(this.content)
        }
        
    },

  }
</script>

<style scoped>
@media (min-width: 1024px) {
  #sample {
    display: flex;
    flex-direction: column;
    place-items: center;
    width: 100%;
  }
}
</style>