<template>
  <div :style="styleObj">
    <div @click="dialogVisible = true">
      <slot name="reference"></slot>
    </div>
    <ky-dialog v-model="dialogVisible" :width="width" title="系统提示" :showClose="false" :showFullScreen="false" v-bind="$attrs" >
      <slot></slot>
      <div style="display: flex; justify-content: center; align-items: center;padding: 5px;">
        <el-button @click="dialogVisible = false ;$emit('confirm')" style="background-color: #f5f5f5">{{confirmText}}</el-button>
        <el-button @click="dialogVisible = false ;$emit('cancel')" style="background-color: #f5f5f5">{{cancelText}}</el-button>
      </div>
    </ky-dialog>
  </div>
</template>

<script>
import KyDialog from './ky-dialog'
export default {
  name: "ky-popconfirm",
  inheritAttrs: false,
  components: {KyDialog},
  props: {
    styleObj: {
      type: Object,
      default: {}
    },
    confirmText: {
      type: String,
      default: '确定'
    },
    cancelText: {
      type: String,
      default: '取消'

    },
    width: {
      type: Number,
      default: 300

    }
  },
  emits: ['confirm','cancel'],
  data() {
    return {
      dialogVisible: false,
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
