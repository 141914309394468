<template>
  <div class="navbar-root">
    <span class="user-info">{{nickName}}</span>
    <span class="user-info" v-text="deptName === null ? '所属部门：无' : '所属部门：' + deptName"></span>
    <span class="user-info">登录时间：{{lastLoginTime}}</span>
    <el-dropdown>
      <img class="avatar" width="30" height="30"  src="../../../src/img/login.png" alt="">
      <template #dropdown>
        <el-dropdown-menu>
          <!-- <el-dropdown-item v-text="deptName === null ? '所属部门：无' : '所属部门：' + deptName"></el-dropdown-item>
          <el-dropdown-item>登录时间：{{lastLoginTime}}</el-dropdown-item> -->
          <el-dropdown-item @click="logout">注销</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>

  </div>
</template>

<script>
import store from '@/store'
import {getLoginInfo, updateLastLoginTime} from "@/api/user";
import moment from 'moment';
export default {
  name: "Navbar",
  data(){
    return{
      nickName: JSON.parse(sessionStorage.getItem("nickName")),
      deptName: JSON.parse(sessionStorage.getItem("deptName")),
      lastLoginTime: JSON.parse(sessionStorage.getItem("lastLoginTime")),
    }
  },
  methods: {
    async logout() {
      const res = await getLoginInfo()
      // 获取当前登录用户的信息
      const obj = {
        staffId: res.data.userId,
        lastLogoutTime:  moment(Date()).format("yyyy-MM-DD HH:mm:ss")
      }
      console.log('lastLoginTime:',this.$store.state.staffInfo.lastLoginTime)
      await store.dispatch('user/logout',obj)
      this.$router.push(`/login`)
    }
  }
}
</script>

<style scoped lang="scss">
.navbar-root {
  background-color: #303133;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // padding-top: 10px;
  height: 6vh;
  padding-right: 10px;
  .avatar {
   
    border-radius: 50%;
  }
}

.user-info {  
  color: white;  
  margin-right: 15px;  /* 根据需要调整间距 */  
}
</style>
