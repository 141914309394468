<template>
  <el-dialog title="高级搜索" width="50%" :show-close="false">
    <template #header="{ close, titleId, titleClass}">
      <div class="my-header">
        <p :id="titleId" :class="titleClass">高级搜索</p>
        <el-button type="info" link @click="close">
          <el-icon><CloseBold /></el-icon>
        </el-button>
      </div>
    </template>

    <div class="search-condition">

      <el-form :model="condition" :inline="true" label-width="120px" v-if="propList != null">
        <el-form-item :label="item.label"  v-for="(item,index) in propList" v-bind:key="index">
          <el-input v-model="condition[item.prop]" style="width:13vw;" v-if="item.type==null || item.type == '' || item.type=='input'"></el-input>

          <el-select collapse-tags v-model="condition[item.prop]" 
            :multiple="(item.multiple == null || item.multiple == false)? false:true" 
            style="width:13vw" v-if="item.type!=null && item.type != '' && item.type=='select'">
              <el-option v-for="(option, optionIndex) in item.optionList" :key="optionIndex" 
              :label="option[item.optionLabel]" :value="option[item.optionValue]">
              </el-option>
          </el-select>

          <el-date-picker v-model="condition[item.prop]" :type="item.type" value-format="YYYY-MM-DD HH:mm:ss" 
            style="width:13vw" v-if="item.type!=null && item.type != '' && (item.type=='date' || item.type=='month')">
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>
      
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="handleConfirm">高级搜索</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { isNull } from '@/utils/filters'
import { getCompanyByName } from "@/api/sale/company";

export default{
  emits: ['afterSubmit'],
  props:{
    show: {
      type: Boolean,
      default: false
    },


   
    // 调用组件时传过来的参数，用来对应高级搜索需要搜索哪些数据，如下
    // propList: {
    //   type: Array,
    //   default:  [
    //     {
    //       label: '客户名称：',  // 对应显示的label
    //       prop: 'customerName', // 绑定值的字段名称
    //       type: 'input'        // 搜素类型，用于判断当前的字段使用哪中方式搜索
    //     },
    //     {
    //       label: '日期：',  // 对应显示的label
    //       prop: 'dateTime', // 绑定值的字段名称
    //       type: 'date'        // 搜素类型，用于判断当前的字段使用哪中方式搜索
    //     },
    //     {
    //       label: '客户ss：',
    //       prop: 'cId',
    //       type: 'select',
    //       optionValue: 'id',   // optionValue指定当前select选择的value字段名
    //       optionLabel: 'name',  // optionLabel指定当前select选择的label字段名
    //       multiple: true,  // 是否多选
    //       optionList: [   // select下拉选择类型的需要将选项放到optionList中传过来
    //         { 
    //           'id': 1,
    //           'name': '虾饺妹'
    //         },
    //         {
    //           'id': 2,
    //           'name': '御前'
    //         }
    //       ]
    //     }
    //   ]
    // },
    propList: {
      type: Array,
      default: []
    }
  },

  components: {
    
  },

  data(){
    return{
      condition: {},
    }
  },

  mounted(){
    
  },
  created: function() {
  
  },

  methods:{
    handleConfirm() {
      console.log("subm")
      this.$emit('afterSubmit',this.condition);
    }

  }
}
</script>

<style scoped>
  .my-header {
    background-color: white;
    padding-right: 15px;
    height: 35px;
    margin-right: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .titleClass {
    color: black;
    line-height: 18px;
    font-size: 16px;
    margin-left: 20px;
    font-weight: bold;
     
  }

  .search-condition {
    max-height: 40vh;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
  }


</style>
