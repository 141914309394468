<template>
  <div style="width: 100vw;height: 100vh">

    <router-view />
  </div>
</template>
<script>
import  {setWaterMark,removeWatermark}  from "@/utils/watermark";
import moment  from 'moment';

export default {
  name: 'App',
  created() {
      setWaterMark(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), JSON.parse(sessionStorage.getItem("nickName")) );
  }

  
}
</script>
<style lang="scss">
 
</style>
