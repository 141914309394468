<template>
    <el-dialog :title="title" :fullscreen="false" width="90vw" @close="handleClose" :destroy-on-close="true"> 
        
        <!-- 搜索 -->
        <el-input class="dialog-input" placeholder="请输入合同编号或名称" clearable v-model="condition.condition.searchChar"/>&nbsp;&nbsp;
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="clear">清空</el-button>

        <!-- 公司列表 -->
        <el-table ref="tableRef" class="el-table" height="60vh" :data="tableData" border size="small" @select-all="handleSelectAll" @select="handleSelectionChange">
            <el-table-column fixed type="selection" width="40"/>
            <el-table-column fixed type="index" label="序号" width="50"/>
            <el-table-column  prop="contractInCode" label="内部编号" sortable width="100" :show-overflow-tooltip="true"/>
            <el-table-column  prop="contractOutCode" label="外部编号" sortable width="100" :show-overflow-tooltip="true"/>
            <el-table-column  prop="projectName" label="项目名称" sortable width="auto" :show-overflow-tooltip="true"/>
            <el-table-column  prop="significanceName" label="紧急程度" sortable width="80" :show-overflow-tooltip="true"/>
            <el-table-column  prop="businessTypeName" label="业务类型" sortable width="120" :show-overflow-tooltip="true"/>
            <el-table-column  prop="deptName" label="签约部门" sortable width="180" :show-overflow-tooltip="true"/>
            <el-table-column  prop="clientName" label="委托客户名称" sortable width="180" :show-overflow-tooltip="true"/>
            <el-table-column  prop="businessSubjectName" label="业务主体名称" sortable width="180" :show-overflow-tooltip="true"/>
            <el-table-column  prop="totalAmount" label="总金额" sortable width="80" :show-overflow-tooltip="true"/>
            <el-table-column  prop="projectAddress" label="项目地址" width="220" :show-overflow-tooltip="true"/>
            <el-table-column  prop="appointedDateStart" label="履约起始日期" sortable width="120" :show-overflow-tooltip="true"/>
            <el-table-column  prop="appointedDateEnd" label="履约终止日期" sortable width="120" :show-overflow-tooltip="true"/>
        </el-table>

        <!-- 分页 -->
        <el-pagination
            @current-change="getCompanyPage"
            v-model:current-page="condition.pageNum"
            :page-size="condition.pageSize"
            layout="total, prev, pager, next"
            :total="totalCount">
        </el-pagination>

        <!-- 确认或取消 -->
        <template #footer>
            <span class="dialog-footer">
                <el-button type="primary" @click="handleConfirm">确认</el-button>
                <el-button @click="handleClose">取消</el-button>
            </span>
        </template>

    </el-dialog>
</template>

<script>
    import contract from "@/api/buiBase/contract"
    import corporation from '@/api/buiBase/corporation'

    export default {
        name: "contractDialog",
        emits:['update:modelValue', 'handleConfirm'],
        props: {
            isSingleChoice: {
                type: String,
                default: false,
            },
            companyType: {
                type: String,
            },
            title: {
                type: String,
                default: '请选择合同信息'
            },
        },
        data() {
            return {
                tableData: [], //合同列表
                selectedRow: [], //选中行
                //查询对象
                condition: {
                    pageSize: 20,
                    pageNum: 1, 
                    condition: {
                        searchChar: '', //查询字符串
                    }
                },
                totalCount: 0, //数据总数
            }
        },
        mounted() {
            
        },
        created() {
            //获取合同列表
            this.getContractPage()
        },
        methods: {
            //模糊搜索合同
            search() {
                this.condition.pageNum = 1
                this.getContractPage()
            },
            //清空搜索条件
            clear() {
                this.condition.condition.searchChar = ''
                this.search()
            },
            //获取合同列表
            async getContractPage() {
                if(this.companyType != null && this.companyType != '') {
                    this.condition.condition.companyType = this.companyType
                    await contract.getContractPage(this.condition).then(res=>{
                        if (res != null && res.code == 200) {
                            this.totalCount = res.data.totalSize
                            this.tableData = res.data.contractList
                        }
                    })
                }
            },
            //确认选择
            handleConfirm() {
                this.$emit('handleConfirm', this.selectedRow)
                this.$refs.tableRef.clearSelection()
            },
            //关闭弹窗
            handleClose() {
                this.selectedRow = []
                this.$emit('update:modelValue', false)
            },
            //列表单选实现
            handleSelectionChange(selection, val) {
                if(this.isSingleChoice) {
                    this.$refs.tableRef.clearSelection()
                    if (selection.length == 0){
                        this.selectedRow = []
                        return
                    };
                    this.$refs.tableRef.toggleRowSelection(val, true)
                    this.selectedRow = val
                }else {
                    this.selectedRow = selection
                }
            },
            //全选时清空选择
            handleSelectAll(selection) {
                if(this.isSingleChoice) {
                    this.$refs.tableRef.clearSelection()
                    this.selectedRow = []
                }else {
                    this.selectedRow = selection
                }
            },
        },
    }
</script>

<style scoped>

  .dialog-input {
    width: 460px;
  }

  .el-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .el-table {
      margin-top: 10px;
  }

</style>
