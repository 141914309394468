<template>
    <fieldset style="border: 1px solid #cccf;" v-bind="$attrs">
      <legend>{{title}}</legend>
      <slot></slot>
    </fieldset>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      required: true
    }
  },
  name: "ky-view"
}
</script>

<style scoped>

</style>
