import request from '@/utils/request'

const qs = require('qs')

export default {

  delImg(fileName) {
    return request({
      headers: {
          'Content-Type': 'multipart/form-data'
      },
      method: 'get',
      url: 'sysbase/delImg',
      params: {
          fileName: fileName,
          filePath: 'ky_certificate_img',
      },
    })
  },

  getImgList(id) {
    return request({
      method: 'get',
      url: 'sysbase/user/getImgList/',
      params: {
          id: id,
      }
    })
  },

  getFile(filePath, fileName){
    return request({
      method:'GET',
      url:'sysbase/getFile',
      params:{
          filePath:filePath,
          fileName:fileName
      },
      responseType: 'blob'
    })
  },

  uploadFile(data) {
    return request({
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      method: 'post',
      url: 'sysbase/uploadFile',
      data: data
    })
  },

  updateImg(id, fileName, pictureName, imgTypeName, institution, flag) {
    return request({
      method: 'post',
      url: 'sysbase/user/uploadImg',
      data: {
        pictureFileName: fileName,
        pictureName: pictureName,
        filePath: 'ky_certificate_img',
        pictureType: imgTypeName,
        ownerId: id,
        institution: institution,
      },
      params: {
        flag: flag,
      },
    })
  },
  // 条件分页获得用户列表
  getPageConditionList(current, limit, name) {
    return request({
      url: `/sysbase/user/getPageConditionList`,
      method: 'get',
      params: {
        name: name,
        current: current,
        limit: limit

      }
    })
  },
  // 更新用户
  updateUser(user) {
    return request({
      url: `/sysbase/user/updateUser`,
      method: 'post',
      data: user
    })
  },
  // 根据id删除用户
  deletLoginInfo(userId) {
    return request({
      url: `/sysbase/user/deletLoginInfo`,
      method: 'post',
      params: {
        id: userId
      }
    })
  },
  // 根据id删除员工
  deleteUser(userId) {
    return request({
      url: `/sysbase/user/deleteUser`,
      method: 'post',
      params: {
        id: userId
      }
    })
  },
  // 保存用户
  saveLoginInfo(user) {
    return request({
      url: `/sysbase/user/saveLoginInfo`,
      method: 'post',
      data: user
    })
  },
  // 保存员工
  saveUser(user) {
    return request({
      url: `/sysbase/user/saveUser`,
      method: 'post',
      data: user
    })
  },
  // 保存或删除用户角色
  saveOrRemoveRole(userId, oldRoleIds, newRoleIds) {
    return request({
      url: `/sysbase/role/saveOrRemoveRole`,
      method: 'post',
      data: newRoleIds,
      params: {
        userId: userId
      }
    })
  },
  userListCondition(data) {
    return request({
      url: '/sysbase/user/userListCondition',
      method: 'post',
      data: data
    })
  },
  getStaffPage(condition) {
    return request({
      url: '/sysbase/user/getStaffPage',
      method: 'post',
      data: condition
    })
  },
  getUserInfoByStaffId(staffId) {
    return request({
      url: '/sysbase/user/getUserInfoByStaffId',
      method: 'get',
      params: {
        staffId
      }
    })
  },
  getLoginInfoByStaffId(staffId) {
    return request({
      url: '/sysbase/user/getLoginInfoByStaffId',
      method: 'get',
      params: {
        staffId
      }
    })
  },
  getKey() {
    return request({
      url: '/sysbase/auth/rsaPublicKey',
      method: 'get',
    })
  },
}
