import request from '@/utils/request'
import store from '@/store/index'
// import qs from 'qs'
// import axios from 'axios'

export async function login(data) {
  let arr = []
  for (let item in data) {
    arr.push(item + '=' + data[item])
  }
  let dd = arr.join('&')

  const res = await request({
    url: '/sysbase/oauth/token',
    method: 'post',
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
    data: dd
  })
  // console.log("login",res)
  if (res.code == 200) {
    localStorage.setItem("tokenCreateTime", res.data.tokenCreateTime)
    return res
  }

}

export function getInfo() {
  return request({
    url: '/sysbase/user/info',
    method: 'get',
  })
  // return axios.get('/dev-api/sysbase/user/info')
}
export function getLoginInfo() {
  return request({
    url: '/sysbase/user/loginInfo',
    method: 'get',
  })
  // return axios.get('/dev-api/sysbase/user/info')
}

export async function updateLastLoginTime(data) {
  const res = await request({
    url: '/sysbase/user/updateLastLoginTime',
    method: 'post',
    data: data
  })
 // console.log("res:", res)
  store.dispatch("staffInfo/changeSetting", {
    staffId: res.data.staffId,
    username: res.data.username,
    nickName: res.data.nickName,
    staffName: res.data.staffName,
    deptId: res.data.companyDeptId,
    deptName: res.data.companyDeptName,
    stationId: res.data.stationId,
    lastLoginTime: data.lastLoginTime,
  })
  // localStorage.setItem("tokenCreateTime",res.data.tokenCreateTime)
  return res;
}

export function updateLastLogoutTime(data) {
  return request({
    url: '/sysbase/user/updateLastLogoutTime',
    method: 'post',
    data: data
  })
}

export function currentUserPermission() {
  return request({
    url: '/sysbase/permission/currentUserPermissionCode',
    method: 'get',
  })
  // return axios.get('/dev-api/sysbase/user/info')
}

export function logout() {
  return request({
    url: '/sysbase/auth/logout',
    method: 'post'
  })
}

export function getStaffInfoByStaffId(staffId) {
  return request({
    url: '/sysbase/user/getStaffInfoByStaffId',
    method: 'get',
    params: {
      staffId: staffId
    }
  })
}