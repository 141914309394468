<template>
  <div style="padding: 10px;" class="add-goods-root">
    <ky-view :title="'编辑商品'">

      <el-descriptions :column="3" border>

        <el-descriptions-item label="商品编码">
          <el-input v-model="goods.goodsCode"></el-input>
        </el-descriptions-item>

        <el-descriptions-item label="商品名称">
          <el-input v-model="goods.goodsname"></el-input>
        </el-descriptions-item>

        <el-descriptions-item label="分类">
          <el-select v-model="goods.kindDefId">
            <el-option v-for="item in searchKindOptions" :key="item.kindDefId" 
              :label="item.kindName" :value="item.kindDefId">
            </el-option>
          </el-select>
        </el-descriptions-item>

        <el-descriptions-item label="商品描述">
          <el-input v-model="goods.goodsDesc"></el-input>
        </el-descriptions-item>

        <el-descriptions-item label="基本价格">
          <el-input-number v-model="goods.basePrice"></el-input-number>
        </el-descriptions-item>

        <el-descriptions-item label="进货价格" >
          <el-input-number v-model="goods.inPrice"></el-input-number>
        </el-descriptions-item>

        <el-descriptions-item label="销售价格" >
          <el-input-number v-model="goods.salePrice"></el-input-number>
        </el-descriptions-item>

        <el-descriptions-item label="是否原料" >
          <el-select v-model="goods.isRawMaterial">
            <el-option v-for="item in [{label: '是',value:1},{label: '否',value:0}]"
                       :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-descriptions-item>

        <el-descriptions-item label="是否付费处置" >
          <el-select v-model="goods.isPayToHandle">
            <el-option v-for="item in [{label: '是',value:1},{label: '否',value:0}]"
                       :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-descriptions-item>

        <el-descriptions-item label="规格" >
          <el-input v-model="goods.specType"></el-input>
        </el-descriptions-item>

        <el-descriptions-item label="进货单位" >
          <el-select v-model="goods.purchaseUnit">
            <el-option v-for="item in goodsUnitList" :key="item.value" 
              :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-descriptions-item>

        <el-descriptions-item label="销售单位" >
          <el-select v-model="goods.baseUnit">
            <el-option v-for="item in goodsUnitList" :key="item.value" 
              :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-descriptions-item>

        <el-descriptions-item label="商品分类">
          <el-input v-model="goodsTypeName"></el-input>
          <el-button @click="edit">修改分类</el-button>
        </el-descriptions-item>

      </el-descriptions>

      <el-row style="margin-top: 20px;">
        <el-col :offset="4" :span="4">
          <el-button @click="saveCompanyDept">保存</el-button>
        </el-col>
      </el-row>
      
    </ky-view>
  </div>
</template>

<script>
import goodsReq from '@/api/buiBase/goods'
export default {
  name: "ky-edit-goods",
  components: {},
  props: {
    goodsId: {
      type: Number,
      required: true
    }
  },
  watch: {
    goodsId: async function () {
      await this.getGoods()
    }
  },
  data() {
    return {
      createStaffDialogVis: false,
      goodsUnitMap: new Map([[1,'千克'],[2,'吨']]),
      goodsUnitList: [
        {
          label: '千克',
          value: 1
        },
        {
          label: '吨',
          value: 2
        },
      ],
      goods: {

      },
      editType: false,
      goodsTypeName: '',
    }
  },
  async created() {
    await this.getGoods()
  },
  methods: {
    async getGoods() {
      try {
        const res = await goodsReq.getGoodsDTOById(this.goodsId)
        if (res.code && res.code===200) {
          this.goods = res.data || {}
        }
      } catch (e) {
        console.log(e)
      }
      const data = await goodsReq.getGoodTypeName(this.goods.goodsType)
      if (data.code === 200) {
        this.goodsTypeName = data.data
      } else {
        this.goodsTypeName = "未找到分类"
      }
    },
    confirmChooseCreateStaff(staffInfo) {
      this.goods.createStaffId = staffInfo.staffId
      this.goods.createStaffName = staffInfo.staffName
      this.createStaffDialogVis = false
    },
    async saveCompanyDept(){
      const res = await goodsReq.updateById(this.goods)
      if (res.code && res.code===200) {
        this.ElMessage({
          type: 'success',
          message: '更新成功'
        })
      }
    },
    edit () {
      this.editType = !this.editType
    },
    editRes (name) {
      this.editType = !this.editType
      this.goodsTypeName = name
    },
  }
}
</script>

<style scoped lang="scss">
.add-goods-root {
  :deep(.el-input__wrapper) {
    box-shadow: 0 0 0 0px var(--el-input-border-color, var(--el-border-color)) inset;
    cursor: default;

    .el-input__inner {
      cursor: default !important;
    }
  }
}
</style>
