<template>
  <el-dialog title="请选择问题" @close="handleClose" width="70vw">
    <div>
        <el-row :gutter="15">
          <el-col :span="5">
            <el-input placeholder="请选择问题编号" v-model="questionCodeDialog"/>
          </el-col>
          <el-col :span="8">
          
          <el-select v-model="businessType" clearable placeholder="请选择业务类型" ref="selectUpResId" @clear="handleClear" style="width:100%">
            <el-option hidden key="businessType" :value="businessType" :label="businessName">
            </el-option>
          <el-tree
            :data="businessList"
            :props="defaultProps"
            :expand-on-click-node="false"
            :check-on-click-node="true"
            @node-click="handleNodeClick">
          </el-tree>
        </el-select>
        </el-col>

        <el-col :span="8">
          <el-input v-model="questionName" placeholder="请输入问题名称" style="width:100%">
          </el-input>
        </el-col>
        
        <el-col :span="3">
            <el-button @click="searchQuestion">查找</el-button>
        </el-col>
      </el-row>
    </div>
    <el-table :data="questionListDTO" stripe border
              @selection-change="selectionChange"
              @select="select"
              @row-click="rowClick"
              height="60vh"
              ref="questionSetTable">

      <el-table-column type="selection" width="40"></el-table-column>

      <el-table-column :prop="item.prop" :label="item.label" v-for="(item, index) in options" :key="index" fixed></el-table-column>
          
    </el-table>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import request from '@/api/question/question';
import { Search } from '@element-plus/icons-vue';
export default{
components: {
    Search
  },
  props: {
    businessList: [],
    // required: true
  },
  data(){
    return{
        options : [
          {
            label: '问题ID',
            prop: 'id'
          },
          {
            label: '问题编号',
            prop: 'questionCode'
          },
          {
            label: '问题名称',
            prop: 'name'
          },
          {
            label: '问题描述',
            prop: 'content'
          },
          {
            label: '业务类型',
            prop: 'business'
          },
        ],
        //接收父组件的值

      defaultProps: {
        children: 'children',
        label: 'name'
      },
      questionListDTO: [],
      selectQuestionTemp:[],
      selectQuestion:[],
      relateOptionList:[],
      questionName:"",
      //业务类型
      businessType:'',
      businessName:"",
      questionCodeDialog:"",
    }
  },
  methods : {
    async getQuestions(){
      // const res = await request.getChoiceQuestions();
      const res = await request.getAllQuestion();
      this.questionListDTO = res.data
      this.questionListDTO.forEach((item)=>{
        if(item.type===2){
          item.answers = []
        }
      })
      console.log(this.questionListDTO)
    },
     handleClear() {
      // 将选择器的值置空
      this.businessName = ''
      this.businessType = ''
    },
      selectionChange(val){
        console.log(val)
        this.relateOptionList=[];
        // this.selectQuestionTemp = val;
        this.selectQuestionTemp = [];
        val.forEach(question => {
          console.log("question根题目",question)
          // let aMainQuestion = question
          let aMainQuestion = JSON.parse(JSON.stringify(question))
          aMainQuestion.mainQuestion = true;
          this.selectQuestionTemp.push(aMainQuestion)
          // console.log(question)
          this.cycleSearch(aMainQuestion.id)
          // console.log("this.relateOptionList",this.relateOptionList)
          for(let i = 0; i < this.relateOptionList.length;i++){
            for(let j = 0; j < this.questionListDTO.length;j++){
            // debugger
             if(this.relateOptionList[i].id == this.questionListDTO[j].id && (this.questionListDTO[j].type==1||this.questionListDTO[j].type==2)){
              // console.log(this.questionListDTO[j])
              // debugger
                // let sonQuestion = this.questionListDTO[j];
                let sonQuestion = JSON.parse(JSON.stringify(this.questionListDTO[j]))
                //父节点问题名
                sonQuestion.parentName = this.relateOptionList[i].parentName
                //父节点选项下标
                sonQuestion.optionFlag = this.relateOptionList[i].optionFlag
                sonQuestion.relateFlag = false
                this.selectQuestionTemp.push(sonQuestion)
              }
            }
          }
          this.relateOptionList=[]
        })
      },
     cycleSearch(questionId){
        for(let i = 0;i < this.questionListDTO.length;i++){
          if(this.questionListDTO[i].id == questionId){
            for(let j = 0; j < this.questionListDTO[i].answer.length; j++){
                if(this.questionListDTO[i].answer[j].jumpId!==null){
                let JumpIdList = this.questionListDTO[i].answer[j].jumpId.split(',')
                JumpIdList.forEach(jumpId=>{
                  if(jumpId!==''){
                  if(this.relateOptionList.indexOf(parseInt(jumpId))==-1){
                      let answer = {
                        id:parseInt(jumpId),
                        parentName:this.questionListDTO[i].name,
                        //选项位置，表示A\B\C\D
                        optionFlag:j,
                      };
                      this.relateOptionList.push(answer)
                    }
                    this.cycleSearch(parseInt(jumpId))
                  }
                })
              }
          }
          break;
         }
       }
      },
    handleNodeClick(data) {
      console.log(data)
      // 这里主要配置树形组件点击节点后，设置选择器的值；自己配置的数据，仅供参考
      this.businessName = data.name
      this.businessType = data.id
      // 选择器执行完成后，使其失去焦点隐藏下拉框的效果
      this.$refs.selectUpResId.blur()
    // console.log("选择后数据为",this.data)
    },
      //判断一个数组中是否存在某个值
      contains(arr,obj){
          var i = arr.length;
          while(i--){
            if(arr[i].id===obj){
              return true;
            }
          }
          return false;
      },
      handleConfirm(){
        if(this.selectQuestion.length == 0){
          //取消ES6代理，使Proxy(Object)变为普通对象
            this.selectQuestion = JSON.parse(JSON.stringify(this.selectQuestionTemp))
            console.log(this.selectQuestion)
          }else{
               
                this.selectQuestionTemp.forEach((item)=>{
                  var isCommon = this.contains(this.selectQuestion,item.id)
                  //如果数组中不存在该问题，则直接插入
                  if(!isCommon){
                    this.selectQuestion.push(item)
                  }
                  // console.log(this.contains(this.selectQuestion,item.id))
             })
             console.log("已选择的",this.selectQuestion)
          }
          this.$refs.questionSetTable.clearSelection();
          this.$emit('selectQuestionList',this.selectQuestion)
          
      },
      async searchQuestion(){
        console.log("this.businessType",this.businessType,"this.businessName",this.questionName)
         const res =  await request.getQuestionByBusinessAndName(this.businessType,this.questionName,this.questionCodeDialog)
         this.questionListDTO = res.data
         console.log(res.data)
      },
      // 对话框-取消
       handleClose(){
        this.$emit('closeDialog', false)
      }
  },
  mounted(){
    this.getQuestions()
  },
}
// 定义表格表头
</script>

<style scoped>
.el-dialog {
  width: 50%;
}

.el-input {
  margin: 0 0 10px 10px;
  width: 60%;
}

.el-table {
  width: 100%;
}

#pagination-container {
  margin: 10px auto 0 auto;
  width: fit-content;
}
  /* .el-select .el-input {
    width: 130px;
  } */
  /* .input-with-select .el-input-group__prepend {
    background-color: #fff;
  } */

  
  
</style>
