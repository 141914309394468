<template>
    <div id="modal">
        <el-dialog ref="dialog" v-if="show" class="hm-modal" :fullscreen="fullScreen" v-dialogDrag v-dialogChange="{width:this.realWidth}"  :close-on-click-modal="false" :visible="show" :modal="false" :append-to-body="append" :width="realWidth" :id="title" :top="'40px'" @close="onClose">
            <template #title>
                <span style="font-size:14px">{{title}}</span><button @click="changeModalSize()" class="modal-full-screen" v-if="dialogChange"><i class="el-icon-full-screen"></i></button>
            </template>
            <div :class="fullScreen?'modal-body':'modal-body modal-default-height'">
                <div v-if="showInfo" :class="'el-message el-message--'+infoType" style="top:10px;position:absolute;z-index:9999">
                    <i :class="'el-message__icon el-icon-'+infoType"></i>
                    <p class="el-message__content">{{infoContent}}</p>
                </div>
                <slot name="body" :fullScreen="fullScreen" style="height:100%"></slot>
            </div>
        </el-dialog>
    </div>
</template>
<script>
// import $ from 'jquery'
export default {
    name: "modal",
    props: {
        //是否显示（sync)
        show: Boolean,
        //高度
        height: {
            type: String,
            default: null,
        },
        //宽度
        width: {
            type: String,
            default: null,
        },
        //标题
        title: String,
        //关闭刷新
        refresh: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: "xs"
        },
        append: {
            type: Boolean,
            default: true,
        },
        closeCallBack :{
            type: Function,
            default: null,
        },
        //是否可拉伸
        dialogChange:{
            type:Boolean,
            default:true,
        }
    },
    data() {
        return {
            realWidth: "600px",
            infoType: "",
            infoContent: "",
            showInfo: false,
            fullScreen:false,
            init:false,
            clientLeft:0,
            clientTop:0,
        }
    },
    created() {
        if (this.width == null) {
            if (this.size == "sm") {
                this.realWidth = "800px";
            }
            else if (this.size == "md") {
                this.realWidth = "1000px";
            }
            else if (this.size == "lg") {
                this.realWidth = "1200px";
            }
            else if (this.size == "xxs") {
                this.realWidth = "400px";
            }
            else {
                this.realWidth = "600px";
            }
        }
        else {
            this.realWidth = this.width;
        }
    
    },
    methods: {
        onClose() {
            this.$emit("update:show", false);
            this.$emit("on-close");
            // this.$parent.onClose();
            if (this.refresh)
                this.$parent.search();
            this.fullScreen=false;
            
        },
        closeModal() {
            this.$emit("update:show", false);
            if (this.refresh)
                this.$parent.search();
                if (this.closeCallBack != null) {
                    this.closeCallBack();
                }
            this.fullScreen=false;
        },
        openInfo(type, content) {
            this.infoType = type;
            this.infoContent = content;
            this.showInfo = true;
            setTimeout(() => {
                this.showInfo = false;
            }, 3000)
        },
        changeModalSize(){
            if(this.fullScreen==false)
            {
                this.$refs.dialog.style.top=40;
                this.$refs.dialog.style.left="50%";
                this.$refs.dialog.style.height="auto";
                this.fullScreen=true;
            }
            else
            {
                this.fullScreen=false;
            }
        }
    },
}
</script>
<style scoped>
.hm-modal .el-dialog__body {
    padding: 0px 0px;
}
.hm-modal .modal-body {
    padding: 10px 26px;
    color: #606266;
    font-size: 12px;
    word-break: break-all;
    overflow: auto;
}
.el-dialog {
    border-radius: 0.5em;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    margin-bottom: 0px !important;
    max-width: 100vw;
}
.el-dialog__header {
    background-color: rgb(211, 220, 233);
    border-top-right-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    border-bottom: 1px solid #e9ecef;
    padding: 10px 0px 10px 20px;
}
/* .el-dialog__footer {
    background-color: #faf8f8;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top: 1px solid #efe9e9;
} */
.el-dialog__title {
    color: black;
    line-height: 18px;
    font-size: 16px;
}
.el-dialog__headerbtn {
    top: 10px;
}
.el-dialog__headerbtn .el-dialog__close {
    color: black;
}
.el-dialog__headerbtn:hover .el-dialog__close {
    
    color: rgb(47, 73, 221);
}

.modal-label {
    padding-right: 10px;
    text-align: right;
    font-size: 12px;
    margin-bottom: 1rem;
    display: block;
}
.hm-modal .el-main {
    overflow: hidden;
}
.el-form-item__error {
    display: none;
}
.searchForm .error-input .el-input__inner {
    border-color: #f56c6c;
}
.searchForm .error-input .el-textarea__inner {
    border-color: #f56c6c;
}
.modal-full-screen{
    float:right;
    margin-right:40px;
    padding-top:3px;
    padding-bottom:5px;
    color:black;
}
.modal-full-screen :hover{
    color: #1b75ca;
}
.modal-default-height{
    max-height:calc(100vh - 60px);
    min-height: 300px;
}
.modal-max-height{
    height:calc(100vh - 50px)
}
</style>