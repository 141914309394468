<template>
  <el-dialog title="请选择员工" :fullscreen="false" width="80%" @close="handleClose" :destroy-on-close="true">
    
    <!-- 搜索 -->
    <span v-if="companyDeptIds == null || companyDeptIds == ''">部门：</span>
    <el-tree-select v-if="companyDeptIds == null || companyDeptIds == ''" 
      v-model="condition.condition.companyDeptId" :data="deptTree" @change="searchStaff" :render-after-expand="false"/>&nbsp;&nbsp;
    <span>职位：</span>
    <el-tree-select v-model="condition.condition.stationId" :data="stationTree" @change="searchStaff" :render-after-expand="false"/>&nbsp;&nbsp;
    <el-input class="dialog-input" placeholder="请输入昵称或姓名" clearable v-model="condition.condition.staffName"/>&nbsp;&nbsp;
    <el-button type="primary" :icon="Search" @click="search">搜索</el-button>
    <el-button @click="clear">清空</el-button>

    <!-- 员工列表 -->
    <el-table ref="tableRef" class="el-table" height="60vh" :data="staffList" border size="small" @select-all="handleSelectAll" @select="handleSelectionChange">
      <el-table-column fixed type="selection" width="40"/>
      <el-table-column fixed type="index" label="序号" width="50" :show-overflow-tooltip="true"/>
      <el-table-column fixed  prop="nickName" label="昵称" sortable width="150" :show-overflow-tooltip="true"/>
      <el-table-column  prop="staffName" label="姓名" sortable width="150" :show-overflow-tooltip="true"/>
      <el-table-column  prop="gender" label="性别" sortable width="80" #default="scope">
        <span v-text="scope.row.gender == 1? '男' : '女'"></span>
      </el-table-column>
      <el-table-column  prop="companyDeptName" sortable label="所属部门" width="280" :show-overflow-tooltip="true"/>
      <el-table-column  prop="stationName" sortable label="职位" width="280" :show-overflow-tooltip="true"/>
      <el-table-column  prop="mobilePhone" label="电话" width="auto" :show-overflow-tooltip="true"/>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      @current-change="getStaffPage"
      v-model:current-page="condition.pageNum"
      :page-size="condition.pageSize"
      layout="total, prev, pager, next"
      :total="totalSize">
    </el-pagination>

    <!-- 确认或取消 -->
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="handleConfirm">确认</el-button>
        <el-button @click="handleClose">取消</el-button>
      </span>
    </template>
    
  </el-dialog>
</template>

<script>
  import user from "@/api/acl/user"
  import station from '@/api/acl/station'
  import dept from '@/api/acl/company_dept'

  export default {
    name: "staffDialog",
    emits:['update:modelValue', 'handleConfirm'],
    props: {
      isSingleChoice: {
        type: String,
      },
      companyDeptIds: {
        type: String,
      },

      excludeStaffIdList: {
        type: Array,
        default: []
      }
    },
    data() {
      return {
        stationTree: [], //职位树
        deptTree: [], //部门树
        staffList: [], //员工列表
        selectedRow: [], //所选列表
        //员工列表查询条件
        condition: {
          pageSize: 20,
          pageNum: 1,
          condition: {
            staffName: '',
            stationId: '',
            companyDeptId: '',
            companyDeptIds: '',
          }
        },
        totalSize: 0, //员工总数
        
      }
    },
    mounted() {
      //获取部门树
      this.getDeptTree()
      //获取职位树
      this.getStationTree();

      this.search();
      //获取员工列表
      // this.getStaffPage()
    },
    methods: {
      //模糊搜索员工
      search() {
          this.condition.condition.companyDeptIds = this.companyDeptIds
          this.condition.pageNum = 1
          this.getStaffPage()
      },
      //清空搜索条件
      clear() {
        this.condition.condition.staffName = ''
        this.condition.condition.stationId = ''
        this.condition.condition.companyDeptId = ''
      },
      //获取员工列表
      async getStaffPage() {
        await user.getStaffPage(this.condition).then(res => {
          if (res != null && res.code == 200) {
              this.staffList = res.data.staffList
              this.totalSize = res.data.totalSize
          }
        })
      },
      //获取职位树
      async getStationTree() {
        const res = await station.getStation()
        if(res != null && res.code == 200) {
          this.stationList = res.data
          let data = res.data
          this.stationTree = []
          // 创建一个map，存储每个节点的所有子节点  
          let childrenMap = new Map();  
          // 处理根节点  
          for (let i = 0; i < data.length; i++) {  
            let it = data[i];  
            if (it.parentId == '0') {  
              let d = [];  
              d.label = it.stationName;
              d.value = it.stationId;  
              d.children = [];  
              // 把当前节点存储为其自身子节点的父节点  
              childrenMap.set(d.value, d.children);  
              this.stationTree.push(d);  
            }  
          }  
          // 处理非根节点，将每个节点的子节点添加到父节点的children中  
          for (let i = 0; i < data.length; i++) {  
            let it = data[i];  
            if (it.parentId !== '0') {  
              // 获取父节点的children  
              let parentChildren = childrenMap.get(it.parentId);  
              if (parentChildren) {  
                  let d = [];  
                  d.label = it.stationName;
                  d.value = it.stationId;  
                  d.children = [];  
                  // 把当前节点存储为其自身子节点的父节点  
                  childrenMap.set(d.value, d.children);  
                  parentChildren.push(d);
              }  
            }
          } 
        }
      },
      //获取部门树
      async getDeptTree() {
        const res = await dept.selectAllCompanyDept()
        if(res != null && res.code == 200) {
          let data = res.data
          this.deptTree = []
          // 创建一个map，存储每个节点的所有子节点  
          let childrenMap = new Map();  
          // 处理根节点  
          for (let i = 0; i < data.length; i++) {  
              let it = data[i];  
              if (it.parentId == '0') {  
                  let d = [];  
                  d.label = it.deptName;
                  d.value = it.companyDeptId;  
                  d.children = [];  
                  // 把当前节点存储为其自身子节点的父节点  
                  childrenMap.set(d.value, d.children);  
                  this.deptTree.push(d);  
              }  
          }  
          // 处理非根节点，将每个节点的子节点添加到父节点的children中  
          for (let i = 0; i < data.length; i++) {  
            let it = data[i];  
            if (it.parentId !== '0') {  
              // 获取父节点的children  
              let parentChildren = childrenMap.get(it.parentId);  
              if (parentChildren) {  
                  let d = [];  
                  d.label = it.deptName;
                  d.value = it.companyDeptId;  
                  d.children = [];  
                  // 把当前节点存储为其自身子节点的父节点  
                  childrenMap.set(d.value, d.children);  
                  parentChildren.push(d);
              }  
            }
          }
        }
      },
      //确认选择
      handleConfirm() {
        this.$emit('handleConfirm', this.selectedRow)
        this.$refs.tableRef.clearSelection()
      },
      //取消
      handleClose() {
        this.staffList = []
        this.$emit('update:modelValue', false)
      },
      //列表单选实现
      handleSelectionChange(selection,val) {
        if(this.isSingleChoice) {
          this.$refs.tableRef.clearSelection()
          if (selection.length == 0){
              this.selectedRow = []
              return
          };
          this.$refs.tableRef.toggleRowSelection(val, true)
          this.selectedRow = val
        }else {
          this.selectedRow = selection
        }
      },
      //全选时清空选择
      handleSelectAll(selection) {
        if(this.isSingleChoice) {
          this.$refs.tableRef.clearSelection()
          this.selectedRow = []
        }else {
          this.selectedRow = selection
        }
      },
    },
  }
</script>

<style scoped>

  .el-input {
    width: 420px;
  }

  .el-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .el-table {
      margin-top: 10px;
  }

</style>
