import uuidUtils from '@/utils/uuid_utils'
//AES加密
import aes from './aes_utils'
//rsa
import JsEncrypt from 'jsencrypt'
//md5
import md5 from 'blueimp-md5'

//aes密钥在这里不暴露
const aesKey = aes.getRandom32Str()

const rsa = new JsEncrypt()
rsa.setPublicKey(localStorage.getItem("requestPublicKey")||'')

function customTimestamp() {
    return 'kyadmin0' + new Date().getTime()
}
function customUuid() {
    return 'kyadmin1' + uuidUtils.uuid()
}
function customAesKey() {
    return 'kyadmin2' + aesKey
}

function restoreTimestamp(timestamp) {
    return String(timestamp).substring(8)
}
function restoreUuid(uuid) {
    return String(uuid).substring(8)
}
function restoreAesKey(aesKey0) {
    return String(aesKey0).substring(8)
}

const salt = 'kyadmin'

export default {
    TIMESTAMP_HEADER_NAME: "kyena",
    UUID_HEADER_NAME: "kyenb",
    SIGN_HEADER_NAME: "kyenc",
    AES_KEY_HEADER_NAME: "kyend",

    getRequestSecurityData(data) {
      const cTimestamp =   customTimestamp()
      const cUuid =   customUuid()
      const cAesKey =   customAesKey()
      const rawTimestamp = restoreTimestamp(cTimestamp)
      const rawUuid = restoreUuid(cUuid)
      const rawAesKey = restoreAesKey(cAesKey)
     
      let ret = {
          TIMESTAMP: this.encryptByAES(cTimestamp),
          UUID: this.encryptByAES(cUuid),
          AES_KEY: this.encryptByRsa(cAesKey),
          DATA: this.encryptByAES(data),
          SIGN: md5(rawTimestamp + rawUuid + rawAesKey + data + salt)
      }

      return ret;
    },


    encryptByAES : (message) => {
        return aes.encryptByAES(message, aesKey)
    },

    decryptByAES : (ciphertext) => {
        return aes.decryptByAES(ciphertext, aesKey)
    },
    setRsaPublicKey(publicKey) {
        rsa.setPublicKey(publicKey)
    },
    encryptByRsa(text) {
        return  rsa.encrypt(text)
    },
}
