// import request from '@/utils/sale_request'
import request from '@/utils/request'

// 获取客户信息列表
// export const getCustomer = (data) => {
//     return request({
//         url: '/customer/selectCustomers',
//         method: 'get',
//         params: {
//             query: data
//         }
//     })
// }
export const getCustomer = (data) => {
    return request({
        url: '/buiBase/getCustomerByName',
        method: 'get',
        params: {
            name: data
        }
    })
}

// 获取客户地址信息列表
// export const getReceiver = (data) => {
//     return request({
//         url: '/customer/selectReceivers',
//         method: 'get',
//         params: {
//             query: data
//         }
//     })
// }
export const getReceiver = (data,type) => {
    return request({
        url: '/buiBase/getCompanyAddrByContact',
        method: 'get',
        params: {
            contact: data,
            type: type
        }
    })
}

// 根据客户id获取客户信息
export const getCompanyMsg = (id,type) => {
    return request({
        method: 'get',
        url: '/buiBase/getCompanyMsg',
        params: {
            id: id,
            type: type
        }
    })
}