<template>
  <ul class = "treeMoudel">
    <li v-for = "(item,index) in menus" :key = "index">
      <!-- 遍历menus，如果传过来的depth等于0，就添加topNode的class，如果不等于0，就添加noTopNode的class -->
      <div id="apartment" :class = "{'itemTree':true,'topNode':depth == 0,'noTopNode':depth != 0,'active':actId == item.id}">
        
        <!-- 判断如果含有name字段就显示name字段 -->
        <div :style = "transform" v-if = "item.sortName" :class = "{'topSpan':depth == 0,'noTopSpan':depth != 0}" @click="getApartment(item)">
          <!-- 如果item有孩子且item.show为false,那么图标为折叠图标 -->
          <i class = "el-icon-caret-right" v-if = "item.userList && item.userList.length > 0 && !item.show" @click = "selectItem(item)"></i>
          <!-- 如果item有孩子且item.show为true,那么图标为展开图标 -->
          <i class = "el-icon-caret-bottom" v-if = "item.userList && item.userList.length > 0 && item.show" @click = "selectItem(item)"></i>
          <input type="checkBox" class="selectBox" @click="checkItem(item)" :checked="item.select"/>
          <span v-if="item.edit" @dblclick="changeApartment(item,false)">{{item.sortName}}</span>
          
          <label>
             <input type="text" v-model="item.sortName"  autofocus v-if="!item.edit"
             @blur="changeApartment(item,true)" @keyup.enter="updateApartment(item)"/>
          </label>

          <button v-if="!item.show" id="show" @click="showApartment(item)">展示</button>
          <button v-if="item.show" id="show" @click="showApartment(item)">折叠</button>
        </div>

        <!-- 判断如果含有username字段就显示username字段 -->
        <div :style = "transform" v-if = "item.username" :class = "{'topSpan':depth == 0,'noTopSpan':depth != 0}" @click="getStaff(item)">
          <i class = "el-icon-caret-right" v-if = "item.userList && item.userList.length > 0 && !item.show" @click = "selectItem(item)"></i>          
          <!-- 如果item有孩子且item.show为true,那么图标为展开图标 -->
          <i class = "el-icon-caret-bottom" v-if = "item.userList && item.userList.length > 0 && item.show" @click = "selectItem(item)"></i>
          <input type="checkBox" class="selectBox" @click="checkItem(item)" :checked="item.select"/>
          <span v-if="item.edit" @dblclick="changeStaff(item,false)">{{item.username}}</span>
          <label>
             <!--autofocus自动获取焦点
             @blur="changeStaff(item,true) 失去焦点的时候,改为true 就是让它隐藏
             v-if="!item.edit" item.edit 默认是true, !item.edit 就是false
             -->
             <input type="text" v-model="item.username"  autofocus v-if="!item.edit"
             @blur="changeStaff(item,true)" @keyup.enter="updateStaff(item)"/>
         </label>
         <button id="add" @click="addStaff(item)">+</button>
        </div>
      </div>
      <el-collapse-transition>
        <!-- 递归组件就是自己调用自己，这里是在自己的组件内再次调用自己，但是务必要和pages中的tree页面中使用的一模一样才可以，否则树不会生效 -->
        <Tree v-if = "item.userList && item.userList.length > 0 && item.show" 
            :menus = "item.userList" :depth = "depth+4" :actId = "actId" 
            @selectItem = "selectItem" @checkItem = "checkItem"
            @getStaff="getStaff" @changeStaff="changeStaff" 
            @updateStaff="updateStaff" @addStaff="addStaff"
            @getApartment="getApartment" @changeApartment="changeApartment"
            @updateApartment="updateApartment" @addApartment="addApartment"
            @showApartment="showApartment">
        </Tree>
      </el-collapse-transition>
    </li>
  </ul>
</template>
<script>
import axios from 'axios'

export default {
  name:"Tree",
  props:["menus","depth","actId","apartmentName"],
  data(){
    return{
      msg:"这是二级菜单树",
    }
  },
  methods:{
    // 将selectItem方法暴露出去
    selectItem(item){
      this.$emit("selectItem",item);
    },
    // 将checkItem方法暴露出去
    checkItem(item){
      this.$emit("checkItem",item);
    },
    getStaff(item) {
      this.$emit("getStaff", item)
    },
    changeStaff(item, edit) {
      this.$emit("changeStaff", item, edit)
    },
    updateStaff(item) {
      this.$emit("updateStaff", item)
    },
    addStaff(item) {
      this.$emit("addStaff", item)
    },
    showApartment(item) {
      this.$emit("showApartment", item)
    },
    getApartment(item) {
      this.$emit("getApartment", item)
    },
    changeApartment(item, edit) {
      this.$emit("changeApartment", item, edit)
    },
    updateApartment(item) {
      this.$emit("updateApartment", item)
    },
    addApartment(item) {
      this.$emit("addApartment", item)
    }

  },
  computed:{
    //通过传过来的depth计算下级目录的偏移量，这里我用的transform
    transform(){
      return 'transform:translateX(' + this.depth*10 + 'px)';
    }
  }
}
</script>
<style lang = "scss"  scoped>
.treeMoudel{
  li{
    .itemTree{
      width: 100%;
      padding-left:30px;
      position: relative;
      &:hover{
        background:#2B9EEE;
        color:#fff;
      }
      .selectBox{
        display: inline-block;
        width: 16px;
        height:16px;
        border:1px solid #ccc;
        border-radius: 3px;
        position: relative;
        background: #fff;
        top:2px;
        .checkName{
          position: absolute;
          top:-16px;
          left:0px;
          color:#333;
        }
        .checkUsername{
          position: absolute;
          top:-12px;
          left:0px;
          color:#333;
        }
      }
      span{
        display: inline-block;
        position: absolute;
        font-size:14px;
      }
      .topSpan{
        font-size:16px;
      }
      .noTopSpan{
        font-size:14px;
      }
    }
    .topNode{
      height:55px;
      line-height: 55px;
      font-size:16px;
      cursor: pointer;
    }
    .active{
      background:#2B9EEE;
      color:#fff;
    }
    .noTopNode{
      height:45px;
      line-height:45px;
      &:hover{
        background:#2B9EEE;
        cursor: pointer;
        color:#fff;
      }
    }
    #add{
      margin-left: 50px;
    }
    #show{margin-left: 100px;}
  }
}


</style>

