import request from '@/utils/request'

const qs = require('qs')

export default {
    //获取组信息
    getGroupList() {
        return request({
            method: 'get',
            url: '/sysbase/group/getAllGroup'
        })
    },

    //新增业务组
    addGroup(group) {
        return request({
            method:'post',
            url: '/sysbase/group/addGroup',
            data: group
        })
    },

    //更新业务组信息
    updateGroup(group) {
        return request({
            method: 'post',
            url: '/sysbase/group/updateGroup',
            data: group
        })
    },

    //根据id删除业务组
    deleteByGroupId(groupId){
        return request({
            method:'post',
            url: '/sysbase/group/deleteByGroupId',
            params: {
                groupId: groupId
            }
        })
    },

}
