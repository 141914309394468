const state = {
    authorities: ''
}
const mutations = {
  //保存数据
  CHANGE_ACTIVE_LI(state, data ) {
    state.authorities = data.authorities
  },
   //清除数据
   SET_CLEAR_DATA(state, data ){
    state.authorities = data
  }
}
const actions = {
  //保存数据
  changeSetting({ commit }, data ) {
    commit('CHANGE_ACTIVE_LI', data )
  },
  //清除数据
  clearVuex({ commit }) {
    commit("SET_CLEAR_DATA", null);
  },

}
export default {
  //解决模块名冲突
  namespaced: true,
  state,
  mutations,
  actions
}
