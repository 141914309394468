<template>
  <span :class="['iconfont',icon,'menu-item-icon']"></span>
  <span>{{title}}</span>
</template>

<script>
export default {
  name: "Item",
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: 'Clock'
    }
  }
}
</script>

<style scoped lang="scss">
.menu-item-icon {
  font-size: 20px;
  line-height: 20px;
  margin-right: 4px;
}
</style>
