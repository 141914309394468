import {
  login,
  getLoginInfo,
  logout,
  getInfo,
  currentUserPermission,
  updateLastLoginTime,
  updateLastLogoutTime
} from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/token-manage'
import { resetRouter } from '@/router'
import { ElMessage } from "element-plus";

import Md5_utils from '@/utils/md5_utils'
import moment from 'moment';
const state = {
  token: getToken(),
  name: '',
  avatar: '',
  permissions: [],
  roles: []
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  }
}

const actions = {
  login({ commit }, userInfo) {
    // debugger
    const { username, password } = userInfo

    return new Promise((resolve, reject) => {
      login({
        username: username.trim(),
        // 加密
        password: Md5_utils.passwordMd5(Md5_utils.passwordMd5(password)),
        grant_type: 'password',
        redirect_uri: 'https://ky.kaiyunsoft.com',
        client_id: 'c1',
        client_secret: '123456',
        notNeedToken: true
      }).then(response => {
      //  console.log("store.response", response)
        setToken(response.data.access_token)

        const obj = {
          staffId: response.data.user_id,
          lastLoginTime: moment(Date()).format("yyyy-MM-DD HH:mm:ss")
        }
      //  console.log("obj:", obj)
        updateLastLoginTime(obj)

        setToken(response.data.access_token)

        resolve()
      }).catch(error => {
        console.log("这里是login的error", error)
        ElMessage({
          message: error.response.data.msg || 'Error',
          type: 'error',
        })
        reject(error)
      })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo().then(response => {
        const { data } = response

        if (!data) {
          reject('Verification failed, please Login again.')
        }
        const { name, avatar } = data

        commit('SET_NAME', name)
        commit('SET_AVATAR', avatar)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },


  getLoginInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getLoginInfo().then(response => {
        const { data } = response

        if (!data) {
          reject('Verification failed, please Login again.')
        }
        const { authorities } = data;
        // console.log("auth",authorities)
        if (!authorities || authorities.length <= 0) {
          reject('permissions can not be empty, please Login again.')
        }
        commit('SET_ROLES', authorities)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  currentUserPermission({ commit, state }) {
    return new Promise((resolve, reject) => {
      currentUserPermission().then(response => {
        const { data } = response

        if (!data) {
          reject('Verification failed, please Login again.')
        }

        // permissions must be a non-empty array
        if (!data || data.length <= 0) {
          reject('getInfo: permissions must be a non-null array!')
        }

        commit('SET_PERMISSIONS', data)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state, dispatch }, obj) {
    return new Promise((resolve, reject) => {
      logout(state.token)
        .then((res) => {
          updateLastLogoutTime(obj)
          resolve(res)
        })
        .catch((err) => resolve(err))
        .finally(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          commit('SET_PERMISSIONS', [])
          removeToken()
          resetRouter()
        })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      commit('SET_PERMISSIONS', [])
      removeToken()
      resolve()
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

