import request from '@/utils/request'

export default  {
    getCorporation() {
        return request({
            method: 'get',
            url: '/buiBase/getCorporation',
        })
    },
    saveEdit(id, name, code) {
        return request({
            method: 'GET',
            url: '/buiBase/editCorporation',
            params: {
                id: id,
                name: name,
                code: code,
            },
        })
    },
    addCorporation(data) {
        return request({
            method: 'POST',
            url: '/buiBase/createCorporation',
            data: data
        })
    },

    delCorporation(id) {
        return request({
            method: 'GET',
            url: '/buiBase/delCorporation',
            params: {
                id: id,
            },
        })
    },

}
