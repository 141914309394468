<template>
  <div style="border: 1px solid #cccf;margin-top: 10px;" :style="styleObj">
    <div style="background-color: #f4f4f4;">
      <slot name="action"></slot>
    </div>
    <el-table
        :stripe="true" :border="true" :highlight-current-row="true"
        :header-cell-style="() => {return {background: '#f4f4f4'}}"
        ref="el_table"
        style="width: 100%;"
        v-bind="$attrs">
      <slot/>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "ky-table",
  inheritAttrs: false,
  props: {
    styleObj: {
      type: Object,
      default: {}
    },
  }
}
</script>

<style scoped>

</style>
