<template>
  <el-dialog title="请选择厂商" width="90%" @close="handleClose" >

    <el-input placeholder="请输入客户简称" clearable v-model="name" style="margin-left: 0px;"/>

    <el-button type="primary" style="margin-left:20px;" :icon="Search" @click="initGetCustomerList">搜索</el-button>

    <el-table ref="myTable" :highlight-current-row="!multipleSelect" :data="tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)" stripe border
       @current-change="handleCurrentChange" @selection-change="selectionChange" @row-click="rowClick" height="60vh">

      <el-table-column type="selection" width="40" v-if="multipleSelect"/>

      <el-table-column :prop="item.prop" :label="item.label" v-for="(item, index) in options" :key="index"></el-table-column>

    </el-table>

    <el-pagination :currentPage="currentPage" :page-size="pageSize"
        @current-change="handlePageChange"
        layout=" ->, prev, pager, next, total" :total="tableData.length">
    </el-pagination>
    
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { isNull } from '@/utils/filters'
import { getCompanyByName } from "@/api/sale/company";

export default{
  emits: ['update:modelValue','afterClose', 'setCustomerInfo'],
  props:{
    multipleSelect: {
      type: Boolean,
      default: true
    },
  },
  components: {
    
  },
  data(){
    return{
     // 定义表格表头
      options: [
        {
          label: '客户编号',
          prop: 'companyCode'
        },
        {
          label: '客户简称',
          prop: 'companyShortName'
        },
        {
          label: '客户注册名称',
          prop: 'companyName'
        },
        {
          label: '业务员',
          prop: 'salesManId'
        },
        {
          label: '联系人',
          prop: 'contact'
        },
        {
          label: '联系电话',
          prop: 'telephone'
        },
        {
          label: '联系地址',
          prop: 'address'
        }
      ],
      name: '',
      // 分页器
      currentPage: 1,
      pageSize: 20,
      tableData: [],
      myTable: [],
      selectData:[],
      singgleCustomer: null,
    
    }
  },

   mounted(){
    
  },
  created: function() {
    this.initGetCustomerList();
  },

  methods:{
    handlePageChange(val) {
      this.currentPage = val;
    },

    async initGetCustomerList() {
      const res = await getCompanyByName(this.name,102)
      this.tableData = res.data
    },

    selectionChange(val) {
      // 将选中的数据存储起来
      this.selectData = val;
    },

    rowClick(row) {
      let hasSelect = false;
      this.selectData.forEach(item => {
        // 判断 如果当前的一行被勾选, 再次点击的时候就会取消选中
        if (item === row) {
          hasSelect = true;
          this.$refs.myTable.toggleRowSelection(row, false)
        } 
      })

      if (!hasSelect) {
        this.$refs.myTable.toggleRowSelection(row, true);
      }
    },

    // 对话框-取消
    handleClose() {
      // this.$emit('afterClose',null)
      this.$emit('update:modelValue', false);
    },

    // 对话框-确认
    handleConfirm() {
      if (this.multipleSelect) {
        this.$emit('afterClose',this.selectData)
      } else {
        this.$emit('afterClose',this.singgleCustomer)
      }
      this.handleClose();
    },

    handleCurrentChange(val) {
      this.singgleCustomer = val;
    }



  }
}
</script>

<style scoped>
 
  .el-input {
    margin: 0 0 10px 50px;
    width: 420px;
  }

  /* ::v-deep .el-dialog__body {
    padding-bottom: 0px;
  } */

</style>
