export default {

    //开启网络传输加密解密
    enableRequestCrypt: false,
    enableResponseCrypt: false,
    serverAddr: 'ky.kaiyunsoft.com',

    // 开启权限控制
    enablePermissionControl: true


}
