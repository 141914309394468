// import request from '@/utils/sale_request'
import request from "@/utils/request";

// 获取所有商品信息列表
export const getGoods = () => {
    return request({
        url: '/buiBase/goods/getAllGoods',
        method: 'get'
    })
}

// 获取所有商品分类列表
export const getGoodType = () => {
    return request({
        url: '/buiBase/goods/getAllGoodType',
        method: 'get'
    })
}

// 获取所有商品分类列表
export const getBrandDef = () => {
    return request({
        url: '/buiBase/goods/getAllBrandDef',
        method: 'get'
    })
}

// 根据商品名称查询商品信息
export const searchGoodsByName = (name, kindDef, brandDef) => {
    return request({
        url: '/buiBase/goods/searchGoodsByConditions',
        params: {
            name: name,
            kindDef: kindDef,
            brandDef: brandDef
        }
    })
}

export const getGoodsList = (relateGoodsList) => {
    return request({
        url: '/buiBase/goods/getGoodsList',
        params: {
            goodsList: relateGoodsList
        } 
    })
}