import axios from 'axios'
import {getToken} from '@/utils/token-manage'
//setting
import setting from '@/setting'
import {ElMessage} from "element-plus";
import request_security from './request_security'

const service = axios.create({
    baseURL: '/api',
    // baseURL: 'https://'+setting.serverAddr,
    timeout: 4000000

})

service.defaults.retry = 4;
service.defaults.retryDelay = 1000;

service.interceptors.request.use(
    config => {
        //是否要加token
        
        if (!(config.data && (config.data.notNeedToken === true || config.url === '/sysbase/oauth/token'))) {
            config.headers['Authorization'] = 'Bearer ' + getToken()
            // config.headers['Authorization'] = 'access_token ' + getToken()
        }
        //若开启加密
        if (setting.enableRequestCrypt) {
            if (config.params) {
                let paramsArr = [];
                for (const key in config.params) {
                    paramsArr.push(key + "=" + config.params[key])
                }
                
                config.params = {params: request_security.encryptByAES(paramsArr.join("&") || '')}
            }
            let rawData = "";
            if (typeof config.data === 'string') {
                rawData = config.data
            } else {
                rawData = JSON.stringify(config.data || '')
            }
            const requestSecurityData = request_security.getRequestSecurityData(rawData)
            config.headers[request_security.AES_KEY_HEADER_NAME] = requestSecurityData.AES_KEY
            config.headers[request_security.TIMESTAMP_HEADER_NAME] = requestSecurityData.TIMESTAMP
            config.headers[request_security.UUID_HEADER_NAME] = requestSecurityData.UUID
            config.data = requestSecurityData.DATA
            config.headers[request_security.SIGN_HEADER_NAME] = requestSecurityData.SIGN
        }
        if (!config.headers['content-type']) {
            config.headers['content-type'] = 'application/json';
        }
        return config
    },
    error => {
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
      if (setting.enableResponseCrypt) {
        const decrypt = request_security.decryptByAES(response.data)
        response.data = JSON.parse(decrypt)
      }
    //   if (response.data.code && response.data.code !== 200 && response.data.code !== 1009) {
    //     ElMessage({
    //       message: response.data.msg || 'Error',
    //       type: 'error',
    //     })
    //     return Promise.reject(new Error(response.data.msg || 'Error'))
    //   }
  
      let needHead = response.headers['content-disposition'];
  
      if (needHead != null && needHead != '' && needHead != 'undefined') {
        return response;
      }
      return response.data;
    },
    error => {
      console.log('err' + error) // for debug
  
      return Promise.reject(error)
    }
  )
  
  export default service
  