import request from '@/utils/request'

export default {
    getMiniProgramSettingByGroupCode() {
        return request({
            method: 'get',
            url: '/buiBase/sysdata/getMiniProgramSettingByGroupCode'
        })
    },

    saveSysParamValues(formData) {
        return request({
            method: 'POST',
            url: '/buiBase/sysdata/saveSysParamValues',
            data: formData
        })
    },

    getMpIconFile(fileName) {
        return request({
            method: 'POST',
            url: '/buiBase/sysdata/getMpIconFile',
            params: {
                fileName: fileName
            },
            responseType: 'blob'
        })
    },

    deleteMpIconFile(fileName) {
        return request({
            method: 'get',
            url: '/buiBase/sysdata/deleteMpIconFile',
            params: {
                fileName: fileName
            },
        })
    },


}
