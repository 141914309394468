<template>
  <div style="padding: 10px;" class="add-goods-root">
    <ky-view :title="'产品详情'">
      <el-descriptions :column="3" border>

        <el-descriptions-item label="产品编码">
          {{goods.goodsCode}}
        </el-descriptions-item>

        <el-descriptions-item label="产品名称">
          {{goods.goodsname}}
        </el-descriptions-item>

        <el-descriptions-item label="分类">
          {{goods.kindName}}
        </el-descriptions-item>

        <el-descriptions-item label="产品描述">
          {{goods.goodsDesc}}
        </el-descriptions-item>

        <el-descriptions-item label="基本价格">
          {{goods.basePrice}}
        </el-descriptions-item>

        <el-descriptions-item label="进货价格" >
          {{goods.inPrice}}
        </el-descriptions-item>

        <el-descriptions-item label="销售价格" >
          {{goods.salePrice}}
        </el-descriptions-item>

        <el-descriptions-item label="是否原料" >
          {{goods.isRawMaterial===1?'是':'否'}}
        </el-descriptions-item>

        <el-descriptions-item label="是否付费处置" >
          {{goods.isPayToHandle===1?'是':'否'}}
        </el-descriptions-item>

        <el-descriptions-item label="规格" >
          {{goods.specType}}
        </el-descriptions-item>

        <el-descriptions-item label="进货单位" >
          {{goodsUnitMap.get(goods.purchaseUnit) }}
        </el-descriptions-item>

        <el-descriptions-item label="销售单位" >
          {{goodsUnitMap.get(goods.baseUnit) }}
        </el-descriptions-item>

      </el-descriptions>
    </ky-view>
  </div>
</template>

<script>
import goodsReq from '@/api/buiBase/goods'
export default {
  name: "ky-detail-goods",
  props: {
    goodsId: {
      type: Number,
      required: true
    }
  },
  watch: {
    goodsId: async function () {
      await this.getGoods()
    }
  },
  data() {
    return {
      createStaffDialogVis: false,
      goodsUnitMap: new Map([[1,'千克'],[2,'吨']]),
      goodsUnitList: [
        {
          label: '千克',
          value: 1
        },
        {
          label: '吨',
          value: 2
        },
      ],
      goods: {

      },
    }
  },
  async created() {
    await this.getGoods()
  },
  methods: {
    async getGoods() {
      try {
        const res = await goodsReq.getGoodsDTOById(this.goodsId)
        if (res.code && res.code===200) {
          this.goods = res.data || {}
        }
      } catch (e) {
        console.log(e)
      }
    },
    confirmChooseCreateStaff(staffInfo) {
      this.goods.createStaffId = staffInfo.staffId
      this.goods.createStaffName = staffInfo.staffName
      this.createStaffDialogVis = false
    },
    async saveCompanyDept(){
      const res = await goodsReq.updateById(this.goods)
      if (res.code && res.code===200) {
        this.ElMessage({
          type: 'success',
          message: '更新成功'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.add-goods-root {
  :deep(.el-input__wrapper) {
    box-shadow: 0 0 0 0px var(--el-input-border-color, var(--el-border-color)) inset;
    cursor: default;

    .el-input__inner {
      cursor: default !important;
    }
  }
}
</style>
