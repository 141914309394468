<template>
    <div>
        <div style="border: 1px solid #ccc">
            <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="editorRef"
                :defaultConfig="toolbarConfig"
                :mode="mode"
            />
            <Editor
                v-loading="loading"
                element-loading-text="图片上传中..."
                :style="{'height': height, 'overflow-y': 'hidden'}"
                v-model="valueHtml"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="handleCreated"
                @onChange="handleChange"
            />
        </div>

        <div style="margin: 10px; display: flex; justify-content: flex-end;">
            <el-button type="primary" @click="handleSave">保存</el-button>
        </div>

        <!-- <textarea v-model="content" style="width: 50vw; height: 50vh;"></textarea> -->
        <!-- <div v-html="content" style="width: 50vw; height: 50vh;"></div> -->
    </div>
</template>

<script>
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { onBeforeUnmount, ref, shallowRef, onMounted, getCurrentInstance } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import {getToken} from '@/utils/token-manage';
import uploaderRequest from '@/api/fileUploader/uploader.js'
import path from 'path';
export default {

    components: { Editor, Toolbar },

    props: {
        height: {
            type: String,
            default: '75vh'
        },
        content: {
            type: String,
            default: ''
        }
    },

    emits: [ 'close'],

    setup(props, { emit }) {
        const { proxy } = getCurrentInstance();
        // 这里使用 proxy.$globalUtils 来访问全局变量
        const globalUtils = proxy.$globalUtils; 

        // 编辑器实例，必须用 shallowRef
        const editorRef = shallowRef()

        // 内容 HTMLhtmlString.replace(/\/api/g, '/api:')
        const valueHtml = ref((props.content==null? '' : props.content.replace(/\/api:/g, '/api')));

        const loading = ref(false)

        const imgList = ref([])

        const oldImgList = ref([])

        const hasSaved = ref(false)

        // 模拟 ajax 异步获取内容
        onMounted(() => {
            
        })

        const toolbarConfig = {}
        const editorConfig = { 
            placeholder: '请输入内容...',
            MENU_CONF: {
                // 配置上传图片
                uploadImage: {
                    // 小于该值就插入 base64 格式（而不上传），默认为 0
                    base64LimitSize: 5 * 1024, // 5k
                    // 最多可上传几个文件，默认为 100
                    maxNumberOfFiles: 10,
                    timeout: 5 * 1000, // 5 秒
                    fieldName: 'file', // 上传文件名
                    // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
                    allowedFileTypes: ['image/*'],
                    server: '/api/buiBase/appendix/uploadRichImage', // 上传接口地址
                    methods: 'post',
                    // 自定义增加 http  header
                    headers: {
                        'Authorization': 'Bearer ' + getToken() // 在此处添加 token，假设 token 格式为 Bearer token
                    },
                    metaWithUrl: true, // 参数拼接到 url 上
                    // 单个文件上传成功之后
                    onBeforeUpload(file) {
                        console.log(`上传中`);
                        loading.value = true;
                    },

                    // onInsertedImage: handleImageInsert,

                    onSuccess(file, res) {
                        console.log(`上传成功`);
                        loading.value = false;
                    },

                     // 单个文件上传失败
                    onFailed(file, res) {           // JS 语法
                        console.log(`${file.name} 上传失败`, res);
                        loading.value = false;
                    },

                    // 上传错误，或者触发 timeout 超时
                   
                    onError(file, err, res) {               // JS 语法
                        console.log(`${file.name} 上传出错`, err, res);
                        loading.value = false;
                    },
                },

                insertImage: {
                    // onInsertedImage(imageNode: ImageElement | null) {  // TS 语法
                    onInsertedImage(imageNode) {                    // JS 语法
                        if (imageNode == null) return;
                        const { src, alt, url, href } = imageNode;
                        let imgName = getFileNameFromSrc(src);
                        if (imgName != null && imgName != '') {
                            imgList.value.push(imgName);
                        }    
                    },
                }
            }
        }


        // 组件销毁时，也及时销毁编辑器
        onBeforeUnmount(() => {
            console.log('before unmount')
            handleDeleteImg();
            const editor = editorRef.value
            if (editor == null) return
            editor.destroy()
        })

    

        const handleCreated = (editor) => {
            editorRef.value = editor // 记录 editor 实例，重要！
            oldImgList.value = handleGetAllImgName();
            console.log("oldImgList", oldImgList.value);
        }

        const handleChange = (editor) => {

            // console.log('change:', valueHtml.value);
        }

        function handleDeleteImg() {
            let currentImgList = handleGetAllImgName();
            
            let deletedImgList = [];
            if (hasSaved.value == true) {
                for (let i=0; i<imgList.value.length; i++) {
                    let imgPath = imgList.value[i];
                    if (!currentImgList.includes(imgPath)) {
                        deletedImgList.push(imgPath);
                    }
                }
            } else {
                deletedImgList = imgList.value;
            }

            if (deletedImgList.length > 0) {
                uploaderRequest.delFileList(deletedImgList, (res) => {
                    if (res != null && res.code != null && res.code == 200) {
                        console.log('删除成功');
                    } else {
                        console.log('删除失败');
                    }
                })
            }
            
        }

        function handleGetAllImgName() {
            const currentImageSrcList = editorRef.value.getElemsByType("image").map((item) => item.src);
            let currentImgList = [];
            if (currentImageSrcList != null) {
                for (let i=0; i<currentImageSrcList.length; i++) {
                    let imgName = getFileNameFromSrc(currentImageSrcList[i]);
                    if (imgName != null && imgName !== '') {
                        currentImgList.push(imgName);   
                    }
                }
            }

            return currentImgList;
        }

        function getFileNameFromSrc(src) {
            if (src == null || src == '') {
                return src;
            }

            if (!src.startsWith('data:image/')) {
                let imgName = null;
                if (src.startsWith('http://') || src.startsWith('https://')) {
                    let list = src.split(':');
                    if (list.length > 1) {
                        let paramStr = list[list.length - 1];
                        imgName = paramStr.substring(paramStr.indexOf('/') + 1);
                    }
                } else {
                    let list = src.split('?');
                    if (list.length > 1) {
                        let params = list[1].split('&');
                        if (params != null && params.length > 0) {
                            let filePath = '';
                            let fileName = '';
                            for (let i = 0; i < params.length; i++) {
                                if (params[i].startsWith('filePath=')) {
                                    filePath = params[i].split('=')[1];
                                } else if (params[i].startsWith('fileName=')) {
                                    fileName = params[i].split('=')[1];
                                }
                            }
                            
                            imgName = filePath + path.sep + fileName;
                        }    
                    }
                }
                return imgName;
            } else {
                return null;
            }

        }
        const handleSave = () => {
            hasSaved.value = true;
            
            if (oldImgList.value.length > 0) {
                imgList.value.push(...oldImgList.value);
            }
           
            // let htmlString = DOMPurify.sanitize(valueHtml.value);
            let htmlString = valueHtml.value;
            console.log('htmlString:', htmlString)
            if (htmlString != null) {
                htmlString =  globalUtils.purifyHtml(htmlString)
            }


            htmlString = processRichText(htmlString);
            htmlString = htmlString.replace(/\/api/g, '/api:');
            // console.log('htmlString:', htmlString)
            
            emit('update:content', htmlString); // 触发父组件的 update:content 事件  
            emit('close'); // 触发父组件的 close 事件
        }

        function processRichText(richTextStr) {
            // 定义正则表达式匹配 <img> 标签
            const imgRegex = /<img([^>]*)>/g; 
            // 使用 replace 方法替换匹配到的 <img> 标签
            return richTextStr.replace(imgRegex, (match, attrs) => {
                // 再次使用正则表达式匹配 style 属性
                const styleRegex = /style="([^"]*)"/; 
                const hasStyle = attrs.match(styleRegex); 
                
                if (hasStyle) {
                    // 如果有 style 属性，则添加 max-height: 100%
                    return match.replace(styleRegex, `style="${hasStyle[1]}; max-width: 100%"`);
                } else {
                    // 如果没有 style 属性，则添加 style="max-height: 100%"
                    return match.replace(/<img/, `<img style="max-width: 100%"`);
                }
            });
        }


        function transformImgTag(html) {
            
             return html.replace(/<img\s+style="([^"]*)"\s*([^>]*)/gi, function(match, styleContent, otherAttributes) {
                
                let widthMatch = styleContent.match(/width:\s*(\d+\.?\d*|\d*\.?\d+)(%|px)/);
                let heightMatch = styleContent.match(/height:\s*(\d+\.?\d*|\d*\.?\d+)(%|px)/);
               
                let newImgTag = `<img `;
                if (widthMatch) {
                    newImgTag += `width="${widthMatch[1]}${widthMatch[2]}" ` ;
                }
                if (heightMatch) {
                    newImgTag += `height="${heightMatch[1]}${heightMatch[2]}" `;
                }

                if (widthMatch == null) {
                    newImgTag += `width="100%" `;
                }

                if (otherAttributes) {
                   newImgTag += otherAttributes; 
                }
                
                return newImgTag;
            });
            
        }

        return {
            hasSaved,
            imgList,
            loading,
            editorRef,
            valueHtml,
            mode: 'default', // 或 'simple'
            toolbarConfig,
            editorConfig,
            handleCreated,
            handleChange,
            handleSave,
            getFileNameFromSrc
        };
    }
}
</script> 

