<template>
    <div id="Analysis"> 
        <div v-if="listType == 'picture-card'" style="margin: 10px 10px 20px 10px">
            <el-button size="small" type="success" @click="submitUpload">上传到服务器</el-button>
        </div> 
            
        <div v-if="listType == 'picture-card'" 
            :style="{
                'margin': '10px 10px 10px 10px',
                'padding':'20px',
                'height': (height==null || height == '') ?'450px' : height,
                'border':'1px solid black', 
                'overflow': 'scroll'
                }">
            <el-upload
                class="upload-demo"
                ref="upload"
                action="#"
                :before-upload="handleBeforeUpload" 
                :accept="accept" 
                :on-error="handleUploadError"
                :on-exceed="handleExceed"
                :on-success="handleUploadSuccess"
                :on-change="handChange" 
                :http-request="httpRequest"
                :on-remove="onRemove"
                :on-preview="handlePreview"
                :multiple="multiple"
                :limit="limit"
                :file-list="fileList"
                :list-type="listType"
                :auto-upload="false"
                >
                <i class="el-icon-plus" ></i>
                <!-- <div slot="file" slot-scope="{file}" >
                    <img class="el-upload-list__item-thumbnail" :src="file.url" :alt="file.url">

                    <span class="el-upload-list__item-actions">
                        <span class="el-upload-list__item-preview" @click="handlePreview(file)">
                            <i class="el-icon-zoom-in"></i>
                        </span>

                        <span class="el-upload-list__item-delete" @click="handleDownload(file)">
                            <i class="el-icon-download"></i>
                        </span>

                        <span class="el-upload-list__item-delete" @click="handleDelete(file)">
                            <i class="el-icon-delete"></i>
                        </span>
                    </span>    
                </div> -->
            </el-upload>
        </div> 

        <div v-if="listType != 'picture-card'" 
            :style="{
            'margin': '10px 10px 10px 10px', 
            'padding':'20px', 
            'height':(height == null || height == '') ? '300px' : height, 
            'border':'1px solid black',
            'overflow': 'auto'
            }">
            <el-upload
                class="upload-demo"
                ref="upload"
                action="#"
                :before-upload="handleBeforeUpload" 
                :accept="accept" 
                :on-error="handleUploadError"
                :on-exceed="handleExceed"
                :on-success="handleUploadSuccess"
                :on-change="handChange" 
                :http-request="httpRequest"
                :on-remove="onRemove"
                :on-preview="handlePreview"
                :multiple="multiple"
                :limit="limit"
                :file-list="fileList"
                :list-type="listType"
                :auto-upload="false"
                >
                <template #trigger>
                    <el-button size="small" type="primary">选取文件</el-button>
                </template>
                
                <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传</el-button>
                
                <template #tip>
                    <div class="el-upload__tip">{{tip}}</div>       
                </template>
                
            </el-upload>
        </div>         

        <el-dialog :visible="dialogVisible" :before-close="handleClose">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>

         <modal :show="dialogVisible" class="progress_modal" size="md" :dialogChange="false" title="图片预览">
            <template #body>
                <div>
                    <img width="100%" :src="dialogImageUrl" alt="">
                </div>
            </template>
        </modal>
    </div>
</template>
<script>
    import qs from 'qs'

    import moment from 'moment'
    import modal from '../components/KyModal'

    export default {
        name: "FileUpload",
        components: {   
            modal
        },
        props: {
           // 关联ID
           id: Number, 
        
            // 是否允许多文件
            multiple: {
                type: Boolean,
                default: false
            },
           // 文件的类型
            accept: {
               type: String,
               default: ''
            },
            limit:{
               type:Number,
               default: 10
            },
            tip: {
               type:String,
               default: '只能上传jpg/png文件，且不超过500kb'
            },
            httpRequest: {
               type: Function,
               default: function(){
                   console.log("default httpRequest function");
               }
           },
           upLoadFileList: {
               type: Array,
               default: []
           },
           listType:{
               type: String,
               default: ''
           },
           onRemove: {
               type: Function,
               default: function(file,fileList){
                   console.log("default onRemove function")
               }
           },

           doClear: {
            type: Function,
            default: function() {
                console.log("default doClear function");
            }
           },

           onPreview: {
               type: Function,
               default: function(file){
                   console.log("preview",file);
                   console.log("default onPreview function");
                   this.dialogVisible = true;
               }
           },
           onDownload: {
              type: Function,
               default: function(file){
                   console.log("default onDownload function")
               } 
           },

           height: {
            type: String,
            default: '300px'
           }
        },
       
        data() {
            return {
               
                action: '',

                // 是否显示文件上传弹窗
                visible: false,

                fileType: '',
                dialogImageUrl: '',
                dialogVisible: false,
                fileList: []
            }
        },
        created() {
            this.fileList = this.upLoadFileList;
           this.fileType = this.accept;

        },
        methods: {

            onClose() {
                this.$emit("on-close");
            },
            
            handlePreview(file) {
                console.log(file);
                this.dialogVisible = true;
                this.dialogImageUrl = file.url
            },
            handleExceed(files, fileList) {
                let mesg = "只允许上传";
                mesg += this.limit;
                mesg += "个文件";
                this.$message.error(mesg);
            },

            // 上传失败
            handleUploadError(err) {
                this.$message.error("上传失败, 请重试");
            },

             // 上传成功回调
            handleUploadSuccess(res, file) {
                console.log("upload-res",res);
                // this.$message.success("上传成功");
            // this.cancel()
            },
              /** 文件状态改变时调用 */
            handChange(file, fileList) {
                console.log(fileList, fileList.value);
                // this.fileList = fileList;
            },

            beforeRemove(file, fileList) {
                return this.$confirm(`确定移除 ${ file.name }？`);
            },

            // 删除文件
            handleDelete(file) {
               
                let fileList = this.$refs.upload.uploadFiles;
                
                let index = fileList.findIndex( fileItem => {
                    return fileItem.uid === file.uid
                })
                console.log("index",index);
                
                fileList.splice(index, 1);
                this.onRemove(file,this.fileList);
                // this.fileList.splice(index, 1);
            },

            // 获取文件名称
            getFileName(name) {
                if (name.lastIndexOf("/") > -1) {
                    return name.slice(name.lastIndexOf("/") + 1).toLowerCase();
                } else {
                    return "";
                }
            },

            submitUpload() {
                
               if(this.fileList.length <= 0) {
                   return false;
               }
               this.$refs.upload.submit();
               this.doClear();
            },

            handleDownload(file){
                console.log("download",file);
            },

             // 上传前校检格式和大小
            handleBeforeUpload(file) {
                
                // 校检文件类型
                if (this.fileType) {
                    let fileExtension = "";
                    if (file.name.lastIndexOf(".") > -1) {
                    fileExtension = file.name.slice(file.name.lastIndexOf("."));
                    }
                    const isTypeOk = this.fileType.some((type) => {
                    if (file.type.indexOf(type) > -1) return true;
                    if (fileExtension && fileExtension.indexOf(type) > -1) return true;
                    return false;
                    });
                    if (!isTypeOk) {
                    this.$message.error(`文件格式不正确, 请上传${this.fileType.join("/")}格式文件!`);
                    return false;
                    }
                }
                // 校检文件大小
                if (this.fileSize) {
                    const isLt = file.size / 1024 / 1024 < this.fileSize;
                    if (!isLt) {
                    this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`);
                    return false;
                    }
                }
                return true;
            },

            handleClose() {

            }

        },

        filters: {
           
        },
        watch: {
            upLoadFileList: {
                immediate: true,
                handler(newId, oldId) {
                    this.fileList = this.upLoadFileList;
                }
                
            }
        }
    }
</script>

<style scoped>
   

</style>
