import { createStore } from 'vuex'
import permission from './modules/permission'
import tagsView from './modules/tagsView'
import user from './modules/user'
import authorities from './modules/authorities'
import staffInfo from './modules/staffInfo'

export default createStore({
  state: {
  },
  getters: {

  },
  mutations: {
  },
  actions: {
  },
  modules: {
    permission,
    tagsView,
    user,
    authorities,
    staffInfo,
  }
})
