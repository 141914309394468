var CryptoJS = require("crypto-js");

export default {

     encryptByAES : (message, key) => {
        var keyHex = CryptoJS.enc.Utf8.parse(key);
        var encrypted = CryptoJS.AES.encrypt(message, keyHex, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.ciphertext.toString(CryptoJS.enc.Base64).replace(/[\r\n]/g, '');
    },

     decryptByAES : (ciphertext, key) => {
        var keyHex = CryptoJS.enc.Utf8.parse(key);
        var decrypted = CryptoJS.AES.decrypt({
            ciphertext: CryptoJS.enc.Base64.parse(ciphertext.replace(/[\r\n]/g, ''))
        }, keyHex, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
        return decrypted.toString(CryptoJS.enc.Utf8);
    },


    getRandom32Str() {
        let letters = 'abcdefghijklmnopqrstuvwxyz0123456789'
        let str = "";
        for(let i=0;i<32;i++){
           str += letters.substr(parseInt(Math.random()*letters.length),1)
        }
        return str
    }



}

