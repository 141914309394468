<template>
  <div class="tagsview-root">
    <el-scrollbar class="scrollbar">
      <div class="scrollbar-body">
        <router-link
            class="tag-item"
            v-for="tag in visitedViews"
            :key="tag.path"
            :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }"
        >
          {{ tag.title }}
          <el-icon :size="10" @click.prevent.stop="closeSelectedTag(tag)">
            <Close />
          </el-icon>
        </router-link>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  name: "TagsView",
  data() {
    return {

    }
  },
  computed: {
    ...mapState('tagsView',['visitedViews'])
  },
  watch: {
    $route() {
      this.addTags()
    },
  },
  methods: {
    addTags() {
      const { name } = this.$route
      if (name) {
        this.$store.dispatch('tagsView/addView', this.$route)
      }
      return false
    },
    async closeSelectedTag(view) {
      await this.$store.dispatch('tagsView/delView', view)
    },
  }

}
</script>

<style scoped lang="scss">
.tagsview-root {
  margin: 5px;
  border-top: 1px solid #0002;
  border-bottom: 1px solid #0002;
  .scrollbar-body {
    display: flex;
    align-items: center;
    height: 30px;
    .tag-item {
      padding: 2px 4px;
      margin: 0 10px;
      background-color: #47af6c;
      text-decoration: none;
      color: white;
    }
  }

}
</style>
