<template>
    <div class="function-process">

      <div class="function-bar">
        <div class="content-block" v-if="useSave">
          <el-button link @click="handleSave" :disabled="(state & disabledSaveState) == disabledSaveState">
            <span>
              <div><span class="iconfont icon-save"></span></div>
              <div>保存</div>
            </span>
          </el-button>
          <el-divider direction="vertical"></el-divider>
        </div>

        <div class="content-group" v-for="(group,groupIndex) in functionList" v-bind:key="groupIndex"> 
          <div v-for="(item, index) in group" v-bind:key="index" class="content-block">
            <!-- :disabled="(hasStopAbolish && !stopAbolish.includes(item.code)) || !((item.preCode&state)==item.preCode && (item.code & state) != item.code && (item.nextCode&state) == 0)"  -->
            <el-button link :disabled="needDisabled(item)"
              @click="handleClick(item.code,item.name)" style="margin: 0px 5px 0px 5px;">
              <span>
                <div>
                  <span :class="item.icon === null? '' : ('iconfont ' + item.icon)"></span>
                </div>
                <div>{{item.name}}</div>
              </span>
            </el-button>
          </div>
          <el-divider direction="vertical"></el-divider>
        </div>  
      </div>

      <div class="status-bar" >
        <div v-for="(item, index) in statusList" v-bind:key="index" >  
          <div class="status-block">
            <div v-if="(item.receiptStatusCode==0 && state==0) || (item.receiptStatusCode!=0 && (item.receiptStatusCode&state)==item.receiptStatusCode)">
              <el-tag :type="(item.colorName!=name && item.colorName != '')? item.colorName : 'success'" class="tag-with-overlay">
                {{item.buttonName}}
                <div class="overlay-text">
                  <el-icon style="color: #337ecc;"><CircleCheck /></el-icon>
                </div> 
              </el-tag>
            </div>

            <div v-else>
              <el-tag type="info">{{item.buttonName}}</el-tag>
            </div>
          </div>
        </div>
      </div>


    </div>
</template>

<script>
import functionRequest from '@/api/buiBase/functionRequest';
// import '@/assets/icomoon/style.css';

export default{
  emits: ['afterSubmit','saveClick'],
  props:{
    // 用于识别业务类型
    modelType: {
      type: String,
      default: '0'
    },
    model: {
      type: Number,
      default: 0
    },

    propList: {
      type: Array,
      default: []
    },

    useSave: {
      type: Boolean,
      default: false
    },

    disabledSaveState: {
      type: Number,
      default: 1
    }

  },

  components: {
    
  },

  data(){
    return{
      state: 0,

      statusList: [
        {
          receiptStatusCode: 0,
          receiptStatusDesc: '编辑中'
        },
        {
          receiptStatusCode: 1,
          receiptStatusDesc: '已提交'
        },
        {
          receiptStatusCode: 2,
          receiptStatusDesc: '审核中'
        },
        {
          receiptStatusCode: 4,
          receiptStatusDesc: '已退回'
        },
        {
          receiptStatusCode: 8,
          receiptStatusDesc: '已审核'
        },
        {
          receiptStatusCode: 16,
          receiptStatusDesc: '审核不通过'
        },

        {
          receiptStatusCode: 32,
          receiptStatusDesc: '已反审核'
        },
        {
          receiptStatusCode: 32,
          receiptStatusDesc: '已暂停'
        },

        {
          receiptStatusCode: 64,
          receiptStatusDesc: '付款中'
        },

        {
          receiptStatusCode: 128,
          receiptStatusDesc: '已完成'
        }
      ],
      functionList: []
    }
  },

  mounted(){
    
  },

  created: function() {
    this.initFunctionList();
  },

  methods:{
    initFunctionList() {
      
      functionRequest.getFunctions(this.modelType).then(res=>{
        if (res != null && res.code == 200) {
          this.functionList = res.data.function;
          this.statusList = res.data.receiptStatus;
          console.log("statusList",this.statusList)
         
        }
      })
    },

   
    handleClick(code, name) {
      this.$emit('afterSubmit', {code: code, name:name});
    },

    handleSave() {
      this.$emit('saveClick');
    },

    needDisabled(item) {
      
      let needDisabled = true;
      let preCodeList = item.preCode;
      let code = item.code;
      let nextCodeList = item.nextCode;
      let state = this.state;
      let hasPreProcess = false;
      let hasNextProcess = false;
      let hasCurrentProcess = false;
      
      for (let i=0; i<preCodeList.length; i++) {
        let preCode = preCodeList[i];
        
        if ((preCode & state) == preCode || preCode == 0) {
          hasPreProcess = true;
          break;
        }
      }

      for (let i=0; i<nextCodeList.length; i++) {
        let nextCode = nextCodeList[i];
        
        if ((nextCode & state) == nextCode) {
          hasNextProcess = true;
          break;
        }
      }
      
      
      if ((code & state) == code) {
        hasCurrentProcess = true;
      }

      if (hasPreProcess && !hasCurrentProcess && !hasNextProcess) {
        needDisabled = false;
      }
      
      return needDisabled;
    }
  },

  computed: {
    
  },

  watch: {
      model: {
        immediate: true,
        handler(newId, oldId) {
          this.state = newId;
        } 
      }
      
  }
}
</script>

<style scoped>

  .function-process {
    /* background-color: aliceblue; */
    padding: 0;
    /* height: 8vh; */
    /* display: flex; 
    align-items: center; */
    /* justify-content: center; */
  }

  .content-group {
    /* width: 50px; */
    display: flex; 
    align-items: center;
    justify-content: center;
  }

  .content-block {
    /* margin-right: 20px; */
    /* width: 50px; */
    /* display: flex; 
    align-items: center;
    justify-content: center; */
    /* margin: 0px 3px 0px 3px; */
  }

  .function-bar {
    display: flex; 
    align-items: center;
    background-color: #ecf5ff;
    padding: 5px 10px 5px 10px;
    height: 5vh;
    display: flex; 
    align-items: center;
  }

  .status-bar {
    background-color: #ecf5ff;
    display: flex; 
    align-items: center; 
    padding: 5px 10px 5px 10px;
    height: 4vh;
    display: flex; 
    align-items: center;
    border-top: 1px solid #c6e2ff;
    /* margin-left: 50px; */
  }

  .status-block {
     margin: 0px 3px 0px 3px;
  }

 .tag-with-overlay {  
    position: relative;  
    display: flex;  
  }  
    
  .tag-with-overlay::after {  
      content: '';  
      position: absolute;  
      top: 0;  
      left: 0;  
      width: 100%;  
      height: 100%;  
      /* background-color: rgba(241, 238, 238, 0.5);   */
  }  
    
  .overlay-text {  
      position: absolute;  
      top: 30%;  
      left: 88%;  
      transform: translate(-50%, -50%);  
      color: #fff;  
      /* font-size: 16px;   */
      z-index: 1; /* 确保文本位于遮罩层之上 */  
      
  } 
</style>
