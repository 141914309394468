<template>
  <el-dialog title="请选择收货人信息" @close="handleClose" width="70vw">

    <el-input placeholder="请输入收货人名称" clearable v-model="contact"/>

    <el-button type="primary" :icon="Search" @click="initGetReceiverList">搜索</el-button>

    <el-table ref="myTable" 
      :data="tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)" 
      stripe 
      border
      @selection-change="selectionChange" 
      @select="select" 
      @row-click="rowClick" 
      height="60vh">

      <el-table-column type="selection" width="40"/>

      <el-table-column :prop="item.prop" :label="item.label" v-for="(item, index) in options" :key="index">
      </el-table-column>

    </el-table>

    <el-pagination :currentPage="currentPage" :page-size="pageSize" @current-change="handleCurrentChange"
        layout=" ->, prev, pager, next, total" :total="tableData.length">
    </el-pagination>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </template>

  </el-dialog>
</template>

<script setup>

import { defineEmits, ref } from 'vue'
import { Search } from '@element-plus/icons-vue'
import { isNull } from '@/utils/filters'
import { getReceiver } from "@/api/sale/customer";

const emits = defineEmits(['update:modelValue', 'handleSubmitChange'])

// 定义查询表格的属性
// const queryForm = ref({
//   query: '',
//   pagenum: 1,
//   pagesize: 5
// })

const contact = ref('')

// 分页器
const currentPage = ref(1)
const pageSize = 20
const handleCurrentChange = (val) => {
  currentPage.value = val;
}

// 收货人信息列表
const tableData = ref([])
// 初始化收货人信息 & 查询收货人信息
const initGetReceiverList = async () => {
  const res = await getReceiver(contact.value,102)
  tableData.value = res.data;
}
initGetReceiverList()

// 实现表格单选功能
const myTable = ref([])
const selectData = ref([])
const select = async (selection, row) => {
  // 清除 所有勾选项
  await myTable.value.clearSelection()
  // 当表格数据都没有被勾选的时候 就返回
  // 主要用于将当前勾选的表格状态清除
  if (selection.length === 0) return
  myTable.value.toggleRowSelection(row, true)
}
const selectionChange = (val) => {
  // 将选中的数据存储起来
  selectData.value = val
}
const rowClick = (row) => {
  myTable.value.clearSelection()
  if (selectData.value.length === 1) {
    selectData.value.forEach(item => {
      // 判断 如果当前的一行被勾选, 再次点击的时候就会取消选中
      if (item === row) {
        myTable.value.toggleRowSelection(row, false)
      } else {
        // 不然就让当前的一行勾选
        myTable.value.toggleRowSelection(row, true)
      }
    })
  } else {
    myTable.value.toggleRowSelection(row, true)
  }
}

// 对话框-取消
const handleClose = () => {
  emits('update:modelValue', false)
}
// 对话框-确认
const handleConfirm = () => {
  if (!isNull(selectData.value)) {
    // const info = JSON.parse(JSON.stringify(selectData.value[0]))
    emits('handleSubmitChange', selectData.value[0])
  } else {
    emits('handleSubmitChange', null)
  }
  handleClose()
}

// 定义表格表头
const options = [
  
  
  {
    label: '收货人',
    prop: 'contact'
  },
  {
    label: '手机号码',
    prop: 'mobilePhone'
  },
  {
    label: '客户名称',
    prop: 'companyName'
  },
  {
    label: '电话号码',
    prop: 'telephone'
  },
  {
    label: '所在地区',
    prop: 'addressArea'
  },
  {
    label: '详细地址',
    prop: 'addressDetail'
  },
  {
    label: '标签',
    prop: 'addressLabel'
  },
  {
    label: '备注',
    prop: 'remark'
  },
  // {
  //   label: '使用状态',
  //   prop: 'statusFlag'
  // }
]
</script>

<style scoped>
.el-input {
  margin: 0 0 10px 50px;
  width: 420px;
}
</style>
