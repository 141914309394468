import request from '@/utils/request'

export default {

    getAllBarcode(id) {
        return request({
            url: '/buiBase/goods/getAllBarcode',
            method: 'get',
            params: {
                goodsId: id
            }
        })
    },

    deleteBarcode(id, barcode) {
        return request({
            url: '/buiBase/goods/deleteBarcode',
            method: 'post',
            data: {
                goodsId: id,
                barcode: barcode
            }
        })
    },

    addBarcode(id, barcode) {
        return request({
            url: '/buiBase/goods/addBarcode',
            method: 'post',
            data: {
                goodsId: id,
                barcode: barcode
            }
        })
    },




    pageGoodsListCondition(data) {
        return request({
            url: '/buiBase/goods/pageGoodsListCondition',
            method: 'post',
            data: data
        })
    },

    updateById(data) {
        return request({
            url: '/buiBase/goods/updateById',
            method: 'post',
            data: data
        })
    },

    getGoodsById(goodsId) {
        return request({
            url: '/buiBase/goods/getGoodsById',
            method: 'get',
            params: {
                goodsId
            }
        })
    },
    deleteById(goodsId) {
        return request({
            url: '/buiBase/goods/deleteById',
            method: 'post',
            params: {
                goodsId: goodsId
            }
        })
    },
    uploadImg(data) {
        return request({
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            method: 'post',
            url: 'buiBase/uploadImg',
            data: data
        })
    },

    uploadFile(data) {
        return request({
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            method: 'post',
            url: 'buiBase/goods/uploadGoodsAppendix',
            data: data
        })
    },

    getAppendixListByOwnerId(ownerId) {
        return request({
            method: 'get',
            url: 'buiBase/goods/getAppendixByOwnerId',
            params: {
                ownerId: ownerId
            }
        })
    },
    getGoodTypeName(goodsTypeId) {
        return request({
            url: '/buiBase/sort/getSortNameById/' + goodsTypeId,
            method: 'get',
        })
    },
    getImgName(pictureId) {
        return request({
            method: 'get',
            url: 'buiBase/goods/getImgName',
            params: {
                pictureId: pictureId,
            },
        })
    },

    saveGoods(data) {
        return request({
            // url: '/buiBase/addGood',
            url: '/buiBase/goods/saveGoods',
            method: 'post',
            data: data
        })
    },

    getGroupId(goodsId) {
        return request({
            url: '/buiBase/goods/getGroupIdList',
            method: 'post',
            params: {
                goodsId: goodsId
            }
        })
    },

    getSortPropertyListBySortId(goodsId, sortId) {
        return request({
            url: '/buiBase/goods/getSortPropertyListBySortId',
            method: 'post',
            params: {
                sortId: sortId,
                goodsId: goodsId
            }
        })
    }

}
