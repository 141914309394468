<template>
  <div v-if="!item.hidden">
    <template v-if="!item.children">
      <el-menu-item :index="indexPath" >
        <item  :title="item.meta.title" :icon="item.meta.icon"/>
      </el-menu-item>
    </template>
    <template v-else-if="item.children && item.children.length===1">
      <el-menu-item :index="resolvePath(indexPath,item.children[0].path) " >
        <item  :title="item.children[0].meta.title" :icon="item.children[0].meta.icon"/>
      </el-menu-item>
    </template>


    <el-sub-menu v-else :index="item.path">
      <template #title>
        <item v-if="item.meta && item.meta.title"  :title="item.meta.title" :icon="item.meta.icon"/>
        <item v-else  :title="'未设置title'"/>
      </template>
      <sidebar-item v-for="child in item.children" :key="child" :item="child" :base-path="indexPath"/>
    </el-sub-menu>
  </div>
</template>

<script>
import Item from './Item'
import path from 'path'
export default {
  name: "SidebarItem",
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  components: {Item},
  computed: {
    indexPath: function () {
      return path.resolve(this.basePath, this.item.path)
    }
  },
  methods: {
    resolvePath(base_path, path_param) {
      return path.resolve(base_path, path_param)
    }
  }
}
</script>

<style scoped>

</style>
