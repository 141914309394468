
import Vue from 'vue'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import checkPermission from '@/utils/permission' // 权限判断函数
//iconfont
import './assets/iconfont/iconfont.css'

//element plus
import './styles/element/index.scss'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

// 引入axios
import axios from 'axios'
import moment from "moment"
import {ElMessage} from "element-plus";
import ky_components from './components'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import './styles/global.css'
import uploader from 'vue-simple-uploader';
import 'vue-simple-uploader/dist/style.css';

import globalUtils from '@/api/global.js';

import ckeditor from '@ckeditor/ckeditor5-vue';


const app = createApp(App);
app.use(uploader);
app.use(ckeditor);
app.use(ElementPlus, { size: 'small', zIndex: 0,locale: zhCn }).use(store).use(router).mount('#app');


//element plus icon

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}



/*
axios.defaults.retry = 4;
axios.defaults.retryDelay = 1000;

axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
  var config = err.config;
  // If config does not exist or the retry option is not set, reject
  if(!config || !config.retry) return Promise.reject(err);
  
  // Set the variable for keeping track of the retry count
  config.__retryCount = config.__retryCount || 0;
  
  // Check if we've maxed out the total number of retries
  if(config.__retryCount >= config.retry) {
      // Reject with the error
      return Promise.reject(err);
  }
  
  // Increase the retry count
  config.__retryCount += 1;
  
  // Create new promise to handle exponential backoff
  var backoff = new Promise(function(resolve) {
      setTimeout(function() {
          resolve();
      }, config.retryDelay || 1);
  });
  
  // Return the promise in which recalls axios to retry the request
  return backoff.then(function() {
      return axios(config);
  });
});

重复连的例子
axios.get('/some/endpoint', { retry: 5, retryDelay: 1000 })
    .then(function(res) {
        console.log('success', res.data);
    })
    .catch(function(err) {
        console.log('failed', err);
    });
*/

//moment
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$globalUtils = globalUtils;
//checkPermission
app.config.globalProperties.checkPermission = checkPermission
//ElMessage

app.config.globalProperties.ElMessage = ElMessage
//注册全局组件

app.use(ky_components)




/* 全局函数 */
app.config.globalProperties.$filters = {
  timeFormat(value, formatStr){
    formatStr = formatStr || 'YYYY-MM-DD hh:mm:ss';
    return moment(value).format(formatStr);
  }
}
// Vue.filter('dateFormat', function (value) {
//   if(value!=null){
//     var formatString = 'YYYY-MM-DD'
//     return moment(value).format(formatString);
//   }
//   return ""
// })




