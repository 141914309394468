const state = {
    staffId: '',
    username: '',
    nickName: '',
    staffName: '',
    deptName: '',
    lastLoginTime: '',
    stationId: '',
}
const mutations = {
  //保存数据
  CHANGE_ACTIVE_LI(state, data ) {
    sessionStorage.setItem("staffId", JSON.stringify(data.staffId));
    sessionStorage.setItem("username", JSON.stringify(data.username));
    sessionStorage.setItem("nickName", JSON.stringify(data.nickName));
    sessionStorage.setItem("staffName", JSON.stringify(data.staffName));
    sessionStorage.setItem("deptName", JSON.stringify(data.deptName));
    sessionStorage.setItem("lastLoginTime", JSON.stringify(data.lastLoginTime));
    sessionStorage.setItem("stationId", JSON.stringify(data.stationId));
    // state.staffId = data.staffId
    // state.username = data.username
    // state.nickName = data.nickName
    // state.staffName = data.staffName
    // state.deptName = data.deptName
    // state.lastLoginTime = data.lastLoginTime
  },
   //清除数据
   SET_CLEAR_DATA(state, data ){
    state.staffId = data
    state.username = data
    state.nickName = data
    state.staffName = data
    state.deptName = data
    state.lastLoginTime = data
    state.stationId = data
  }
}
const actions = {
  //保存数据
  changeSetting({ commit }, data ) {
    commit('CHANGE_ACTIVE_LI', data )
  },
  //清除数据
  clearVuex({ commit }) {
    commit("SET_CLEAR_DATA", null);
  },

}
export default {
  //解决模块名冲突
  namespaced: true,
  state,
  mutations,
  actions
}
