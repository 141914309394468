import request from '@/utils/request'

export default  {

    //根据id，type查询
    getContractById(contractId) {
        return request({
            method: 'GET',
            url: '/buiBase/contract/getContractById',
            params: {
                contractId: contractId
            }
        })
    },

    //分页查询
    getContractPage(condition) {
        return request({
            method: 'POST',
            url: '/buiBase/contract/getContractPage',
            data: condition
        })
    },

    //新增
    saveContract(data) {
        return request({
            method: 'POST',
            url: '/buiBase/contract/saveContract',
            data: data
        })
    }, 

    //编辑
    editContract(data) {
        return request({
            method: 'POST',
            url: '/buiBase/contract/editContract',
            data: data
        })
    },

    //删除
    deleteContract(id, type) {
        return request({
            method: 'POST',
            url: '/buiBase/contract/deleteContract',
            params: {
                contractId: id,
                companyType: type
            }
        })
    },

    //批量上传文件
    uploadFileList(data) {
        return request({
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            method: 'POST',
            url: 'buiBase/contract/uploadFileList',
            data: data
        })
    },
   


    //导出合同模板
    generateContractProper(contractId, templateId) {
        return request({
            method:'GET',
            url:'buiBase/contract/generateContractProper',
            params: {
                contractId: contractId,
                contractTemplateId: templateId
            }
        })
    },

    

}
