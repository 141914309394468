<template>
    
  <div style="margin: 0px 20px 0px 20px;">
    <el-row v-for="(iItem, i) in Math.ceil(searchList.length/colCount)" v-bind:key="i">
      <el-col :span="colSpan" v-for="(jItem,j) in colCount" v-bind:key="j">
        <div v-if="i*colCount+j<searchList.length" style="display: flex;align-items: center;">

          <el-select v-if="i*colCount+j!==0" style="width: 50px;" v-model="searchList[i*colCount+j]['logicMode']" placeholder="模式">
            <el-option :label="'或'" :value="'or'"/>
            <el-option :label="'且'" :value="'and'"/>
          </el-select>

          <span v-else style="width: 50px;"></span>

          <el-select style="width:150px; margin-left:2px;" v-model="searchList[i*colCount+j]['propertyName']" placeholder="字段">
            <el-option v-for="item in conditionList" :key="item.propertyName" :label="item.label" :value="item.propertyName"/>
          </el-select>

          <el-select style="width:100px; margin-left:2px;" v-model="searchList[i*colCount+j]['matchType']" placeholder="匹配">
            <el-option v-for="item in supportMatchTypeList(searchList[i*colCount+j]['propertyName'])" :key="item.value"
            :label="item.label" :value="item.value"/>
          </el-select>

          <div v-if="searchList[i*colCount+j]['matchType']==='between'">

            <el-input class="margin-left" style="width: 200px;" v-if="inputShow(searchList[i*colCount+j]['propertyName'],'string')"
                v-model="searchList[i*colCount+j]['leftValue']">
            </el-input>
            
            <el-input-number class="margin-left" style="width: 200px;" v-if="inputShow(searchList[i*colCount+j]['propertyName'],'number')" 
              v-model="searchList[i*colCount+j]['leftValue']">
            </el-input-number>

            <el-date-picker style="width: 200px;" v-if="inputShow(searchList[i*colCount+j]['propertyName'],'datetime')" type="datetime"
                v-model="searchList[i*colCount+j]['leftValue']" format="YYYY-MM-DD hh:mm:ss" value-format="YYYY-MM-DD hh:mm:ss">
            </el-date-picker>

            <el-select v-if="inputShow(searchList[i*colCount+j]['propertyName'],'select')" filterable
                style="width: 200px;" v-model="searchList[i*colCount+j]['leftValue']">
              <el-option v-for="item in selectData(searchList[i*colCount+j]['propertyName'])"
                  :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>

            <el-button v-if="chooseShow(searchList[i*colCount+j]['propertyName'])" type="info" 
              @click="$emit('choose',searchList[i*colCount+j]['propertyName'], true)" class="margin-left">
              选择
            </el-button>

            <el-input-number class="margin-left" style="width: 200px;" v-if="inputShow(searchList[i*colCount+j]['propertyName'],'number')"
                v-model="searchList[i*colCount+j]['rightValue']">
            </el-input-number>

            <el-date-picker style="width: 200px;" v-if="inputShow(searchList[i*colCount+j]['propertyName'],'datetime')"
              v-model="searchList[i*colCount+j]['rightValue']" type="datetime" format="YYYY-MM-DD hh:mm:ss" value-format="YYYY-MM-DD hh:mm:ss">
            </el-date-picker>

            <el-input style="width: 200px;" v-if="inputShow(searchList[i*colCount+j]['propertyName'],'string')"
                v-model="searchList[i*colCount+j]['rightValue']" class="margin-left">
            </el-input>

            <el-select v-if="inputShow(searchList[i*colCount+j]['propertyName'],'select')" filterable
                style="width: 200px;" v-model="searchList[i*colCount+j]['rightValue']">
              <el-option v-for="item in selectData(searchList[i*colCount+j]['propertyName'])" 
                :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>

            <el-button v-if="chooseShow(searchList[i*colCount+j]['propertyName'])" type="info"
              @click="$emit('choose',searchList[i*colCount+j]['propertyName'], false)" class="margin-left">
                选择
            </el-button>
          </div>

          <div v-else>
            <el-input-number class="margin-left" style="width: 200px;"
                v-if="inputShow(searchList[i*colCount+j]['propertyName'],'number')" v-model="searchList[i*colCount+j]['value']">
            </el-input-number>

            <el-input class="margin-left" style="width: 200px;"
                v-if="inputShow(searchList[i*colCount+j]['propertyName'],'string')" v-model="searchList[i*colCount+j]['value']">
            </el-input>

            <el-date-picker style="width: 200px;"
                v-if="inputShow(searchList[i*colCount+j]['propertyName'],'datetime')"
                v-model="searchList[i*colCount+j]['value']" type="datetime"
                format="YYYY-MM-DD hh:mm:ss" value-format="YYYY-MM-DD hh:mm:ss">
            </el-date-picker>

            <el-select v-if="inputShow(searchList[i*colCount+j]['propertyName'],'select')" filterable
                style="width: 200px;" v-model="searchList[i*colCount+j]['value']">
              <el-option v-for="item in selectData(searchList[i*colCount+j]['propertyName'])"
                  :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>

            <el-button v-if="chooseShow(searchList[i*colCount+j]['propertyName'])" type="info"
              @click="$emit('choose',searchList[i*colCount+j]['propertyName'])" class="margin-left">
              选择
            </el-button>
          </div>

          <!-- <div class="iconfont icon-shanchu margin-left" @click="deleteOne(i*colCount+j)"></div> -->

          <div class="button-style">
            <el-button style="float: right;" class="deleteButtton" size="small" type="text" @click="deleteOne(i*colCount+j)">删除</el-button>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row style="margin-top: 5px; margin-left:50px;">
      <el-button key="primary" type="primary" text @click="searchList.push({'logicMode': 'and'})">增加条件</el-button>
      <el-button key="success" type="success" text @click="search">搜索</el-button>
      <!-- <el-button type="success" plain @click="search">搜索</el-button> -->
      <el-button type="info" text @click="initSearchList">清空条件</el-button>
    </el-row>
  </div>
      
</template>


<script>
export default {
  name: "ky-list-condition-one-add",
  props: {
    conditionList: {
      required: true,
      type: Array
    },
    colSpan: {
      type: Number,
      required: true,
      default: 4
    },
    colCount: {
      type: Number,
      required: true,
      default: 2
    }
  },
  emits: ['search','choose'],
  data() {
    return {
      selectedPropertyName: {},
      searchList: Array,
    }
  },
  created() {
    this.initSearchList();
  },
  methods: {
    search() {
      if (JSON.stringify(this.searchList)==='[{}]') {
        this.$emit('search',[]);
      } else {
        this.$emit('search',this.searchList);
      }
    },
    setValue(propertyName, value, isLeft) {
      for (let item of this.searchList) {
        if (item.propertyName===propertyName) {
          if (isLeft===true) {
            item['leftValue'] = value;
          } else if (isLeft===false) {
            item['rightValue'] = value;
          } else {
            item['value'] = value;
          }
        }
      }
    },
    chooseShow(propertyName) {
      for (let item of this.conditionList) {
        if (item.propertyName===propertyName) {
          return item.chooseBtnVisible
        }
      }
      return false;
    },
    inputShow(propertyName, requiredPropertyType) {
      for (let item of this.conditionList) {
        if (item.propertyName===propertyName) {
          return item.propertyType===requiredPropertyType
        }
      }
      return false;
    },
    supportMatchTypeList(propertyName) {
      for (let item of this.conditionList) {
        if (item.propertyName===propertyName) {
          return item.supportMatchTypeList
        }
      }
      return [];
    },
    selectData(propertyName ) {
      for (let item of this.conditionList) {
        if (item.propertyName===propertyName) {
          return item.selectData
        }
      }
      return [];
    },
    initSearchList() {
      this.searchList = [{}]
    },
    deleteOne(index) {
      let a = [];
      for (let i = 0; i < this.searchList.length; i++) {
        if (i!==index) {
          a.push(this.searchList[i]);
        }
      }
      this.searchList = a;
    },

  }
}
</script>

<style scoped>
  .margin-left {
    margin-left: 2px;
  }

  .button-style {
    height: 20px;
    margin: 10px;
  }
  .deleteButtton {
    color: red;
  }
</style>
