<template>
    <div>
        <el-tree-select
            :check-strictly="checkStrictly"
            :collapse-tags="collapseTags"
            v-model="groupList"
            :data="buyerGroups"
            show-checkbox
            :props="{ value: 'groupId', label: 'groupName'}"
            value-key="groupId"
            node-key="groupId"
            highlight-current
            multiple
            filterable
            @check="selectBuyerGroup"
            style="width:100%;"/>
    </div>
</template>

<script>
import company from "@/api/buiBase/company"

export default {
    name: "groupSelectTree",
    emits:['update:modelValue'],
    props: {
        groups: {
            type: Array,
        },
        checkStrictly: {
            type: Boolean,
            default: true,
        },
        collapseTags: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            buyerGroups: [],
            groupList: this.groups,
        }
    },
    mounted() {
        this.getGroup()
    },
    methods: {
        async getGroup() {
            const res = await company.getAllGroup()
            this.buyerGroups = res.data
        },
        async selectBuyerGroup(checkedNodes,checkedKeys,halfCheckedNodes,halfCheckedKeys){
            this.$emit('update:modelValue',checkedKeys.checkedKeys)
        },
    },
}
</script>

<style scoped>

</style>
