import request from '@/utils/request'

export default  {

    //根据id，type查询
    getGoodsItemByContract(id, type) {
        return request({
            method: 'GET',
            url: '/buiBase/contractItem/getGoodsItemByContract',
            params: {
                contractId: id,
                companyType: type
            }
        })
    },

    // 根据主键删除合同明细
    deleteByContractGoodsItemsId(contractGoodsItemsId) {
        return request({
            method: 'GET',
            url: '/buiBase/contractItem/deleteByContractGoodsItemsId',
            params: {
                contractGoodsItemsId: contractGoodsItemsId
            }
        })
    },


}
