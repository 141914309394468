<template>
  <div>
    <el-table
      ref="sysParamValue"
      :data="sysParamValues"
      :border="true"
      style="width: 100%"
      row-key="valueId"
      :row-class-name="tableRowClass"
    >
      <el-table-column label="参数名称" prop="descLabel"></el-table-column>
      <el-table-column label="参数值" prop="paramValue"></el-table-column>
      <el-table-column label="开始日期" prop="startDate"></el-table-column>
      <el-table-column label="结束日期" prop="endDate"></el-table-column>
      <el-table-column label="是否可修改" prop="isFix">
        <template #default="scope">{{ scope.row.isFix === 1? '不可修改':'可修改' }}</template>
      </el-table-column>
      <el-table-column label="状态" prop="paramStatus">
        <template #default="scope">{{ scope.row.paramStatus === 7? '正常':'停用' }}</template>
      </el-table-column>
      <el-table-column label="操作" :align="left" :width="300">
        <template #default="scope">
          <el-button
            :disabled="scope.row.isFix === 1"
            type="primary"
            @click="handleEdit(scope.row)"
          >修改</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>

  <!--  -->
  <el-dialog ref="editDialog" v-model="editDialogVisible" :title="修改参数" :destroy-on-close="true">
    <el-form
      ref="formRef"
      :rules="rules"
      :model="sysvalueitem"
      style="max-width: 800px"
      :label-position="left"
      label-width="auto"
    >
      <div class="goods-classfication">
        <el-row :gutter="5" class="row">
          <el-col :span="23">
            <el-form-item label="参数名称" prop="descLabel">
              <el-input v-model="sysvalueitem.descLabel" disabled/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="5" class="row">
          <el-col :span="23">
            <el-form-item label="参数值" prop="paramValue">
              <el-input-number v-if="sysvalueitem.dataType === 1" v-model="sysvalueitem.paramValue"></el-input-number>
              <el-input
                v-if="sysvalueitem.dataType === 2"
                v-model="sysvalueitem.paramValue"
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 6 }"
              ></el-input>
              <el-date-picker
                v-if="sysvalueitem.dataType === 3"
                v-model="sysvalueitem.paramValue"
                type="date"
                placeholder="Pick a day"
              />
              <el-input-number
                v-if="sysvalueitem.dataType === 4"
                v-model="sysvalueitem.paramValue"
                :precision="2"
                :step="0.1"
              />
              <div class="sys-image" v-if="sysvalueitem.dataType === 5">
                <el-aside width="110px">
                  <el-upload
                    class="avatar-uploader"
                    :http-request="httpRequest"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                  >
                    <img
                      v-if="imageUrl != null && imageUrl != ''"
                      :src="imageUrl"
                      style="width: 100px; height: 100px"
                      :fit="contain"
                      class="avatar"
                    >
                    <el-icon v-else class="avatar-uploader-icon">
                      <Plus/>
                    </el-icon>
                  </el-upload>

                  <el-button
                    v-if="imageUrl != null && imageUrl != ''"
                    @click="delImg(sysvalueitem.paramValue) ;"
                    type="danger"
                  >删除</el-button>
                </el-aside>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="5" class="row">
          <el-col :span="16">
            <el-form-item label="有效期：" prop="effectDateList">
              <el-date-picker
                type="daterange"
                range-separator="至"
                start-placeholder="起始日期"
                end-placeholder="结束日期"
                @change="changeEffectDateList"
                value-format="YYYY-MM-DD HH:mm:ss"
                v-model="effectDateList"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="editDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submit">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import request from "@/api/buiBase/fileDirectory";
import globalRequest from "@/api/global";
import Uploader from "@/components/Upload/Index";
import sysdataReq from "@/api/buiBase/sysdata";

export default {
  emits: ["afterSubmit"],
  components: {},
  props: {
    sysSetGroupCode: String
  },
  data() {
    return {
      // sysSetGroupCode:"",
      sysParamValues: [],
      effectDateList: [],
      editDialogVisible: false,
      imageUrl: "",
      showImg: false,
      rules: {
        // customerId:{required:true,message: '所属客户不能为空！', trigger: 'blur'},
        paramValue: {
          required: true,
          message: "参数值不能为空！",
          trigger: "blur"
        }
        //  effectDateList:{required:true,message: '起止时间不能为空！', trigger: 'blur'}
      }
    };
  },

  mounted() {},
  created: function() {
    //console.log("sysSetGroupCode",this.sysSetGroupCode);
    this.getSysParamValuesList();
  },

  methods: {
    async getSysParamValuesList() {
      // console.log("sysSetGroupCode",this.sysSetGroupCode);
      if (this.sysSetGroupCode === "miniProgram") {
        await sysdataReq
          .getMiniProgramSettingByGroupCode()
          .then(res => {
            //    console.log("this.sysParamValues111",this.sysParamValues)
            if (res.code && res.code === 200) {
              this.sysParamValues = res.data;
              //    console.log("this.sysParamValues",this.sysParamValues)
            }
          })
          .catch(e => {
            console.log(e);
          });
      }
    },

    tableRowClass(row, rowIndex) {
      let curClass = this.$globalUtils.tableRowClassName(
        row,
        rowIndex,
        row.row.paramStatus,
        ",128,"
      );
      return curClass;
    },

    handleEdit(row) {
      //  console.log(row);
      this.sysvalueitem = Object.assign({}, row);
      this.effectDateList[0] = row.startDate;
      this.effectDateList[1] = row.endDate;
      this.loadPicture(row);
      //  this.httpRequest(row);
      this.editDialogVisible = true;
    },
    //更新履约日期
    changeEffectDateList() {
      // this.goodsItemsList.forEach(item => {
      //   if(this.appointedDateList == null || this.appointedDateList.length <= 0) {
      //     item.validityStart = '';
      //     item.validityEnd = '';
      //   } else if(item.validityStart < this.appointedDateList[0] || item.validityEnd > this.appointedDateList[1]) {
      //     ElMessage({
      //         message: '产品 ' + item.goodsName + ' 超出履约日期范围',
      //         type: 'warning',
      //     })
      //     item.validityStart = '';
      //     item.validityEnd = '';
      //   }
      // })
    },

    async submit() {
      let valid = await this.$refs["formRef"].validate().catch(err => {
        return err;
      });
      if (valid == true) {
        this.sysvalueitem.startDate = this.effectDateList[0];
        this.sysvalueitem.endDate = this.effectDateList[1];
        if (this.formData == null) {
          this.formData = new FormData();
          this.formData.append("file", null);
        }
        this.formData.set("sysDataValue", JSON.stringify(this.sysvalueitem));
        //    const res = await sysdataReq.saveSysParamValues(this.sysvalueitem);
        const res = await sysdataReq.saveSysParamValues(this.formData);
        if (res.code && res.code === 200) {
          this.formData = new FormData();
          this.$message({
            type: "success",
            message: res.msg
          });
          this.$emit("afterSubmit");
        } else {
          this.$message({
            type: "warning",
            message: res.msg
          });
        }
      }
    },

    changeImg() {
      this.showImg = true;
    },

    async httpRequest(data) {
      let formData = new FormData();
      formData.append("file", data.file);
      this.formData = formData;
      //  console.log("data.file",data.file)
      var blob = new Blob([data.file], { type: "image/jepg" });
      this.imageUrl = URL.createObjectURL(blob);
    },

    async loadPicture(thisitem) {
      this.imageUrl = "";
      let res = await sysdataReq.getMpIconFile(thisitem.paramValue).catch(e => {
        this.maskLoading = false;
      });
      //console.log(res,res.Blob )
      if (res != null) {
        const blob = new Blob([res], { type: "image/jpeg" });
        // console.log(blob)

        this.imageUrl = URL.createObjectURL(blob);
        this.showImg = true;
        //     console.log(this.imageUrl)
      }
    },

    async delImg(filename) {
      this.showImg = false;
      //console.log("filename",filename)
      const res = await sysdataReq.deleteMpIconFile(filename);
      if (res.code === 200) {
        // thisitem.filename = ""
        this.imageUrl = "";
      }
    }
  },

  computed() {},

  watch: {
    // taskId: {
    //     immediate: true,
    //     handler(newId, oldId) {
    //         // this.getTaskByTaskId();
    //     }
    // }
  }
};
</script>

<style scoped>
.el-form-item {
  margin-bottom: 15px;
}

.text-container {
  white-space: pre-wrap; /* 或者使用 pre-line */
}

.sys-image .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  display: inline-block;
  width: 20%;
  box-sizing: border-box;
  vertical-align: top;
}
.sys-image .block:last-child {
  border-right: none;
}
.sys-image .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}
</style>