//md5
import md5 from 'blueimp-md5'

export default {

    passwordSalt: 'kyadmin',

    passwordMd5(password) {
        return md5(password+this.passwordSalt)
    }

}
