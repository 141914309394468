import request from '@/utils/request'

export default {

    getSonNodeByParentId(data) {
        return request({
            method: 'POST',
            url: '/buiBase/directory/getSonNodeByParentId',
            data: data
        })
    },

    getFileDirectoryByParentId(id) {
        return request({
            method: 'get',
            url: '/buiBase/directory/getFileDirectoryByParentId',
            params: {
                id: id
            }
        })
    },

    getSelectTreeDataByParentId(id) {
        return request({
            method: 'get',
            url: '/buiBase/directory/getSelectTreeDataByParentId',
            params: {
                id: id
            }
        })
    },

    saveFileDirectory(data) {
        return request({
            method: 'POST',
            url: '/buiBase/directory/saveFileDirectory',
            data: data
        })
    },

    updateFileDirectory(fileDirectory) {
        return request({
            method: 'POST',
            url: '/buiBase/directory/update',
            data: fileDirectory
        })
    },

    deleteFileDirectory(id) {
        return request({
            method: 'GET',
            url: '/buiBase/directory/deleteFileDirectory',
            params: {
                id: id,
            },
        })
    },

    uploadFile(data) {
        return request({
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            method: 'POST',
            url: 'buiBase/directory/uploadFile',
            data: data
        })
    },

    uploadFileList(data) {
        return request({
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            method: 'POST',
            url: 'buiBase/directory/uploadFileList',
            data: data
        })
    },

    getFile(filePath, fileName, showFilePath, originalFileName) {
        return request({
            method: 'POST',
            url: 'buiBase/directory/getFile',
            params: {
                filePath: filePath,
                fileName: fileName,
                showFilePath: showFilePath,
                originalFileName: originalFileName
            },
            responseType: 'blob'
        })
    },

    downloadFile(filePath, fileName, showFilePath, originalFileName) {
        return request({
            method: 'post',
            url: 'buiBase/directory/downloadFile',
            params: {
                filePath: filePath,
                fileName: fileName,
                showFilePath: showFilePath,
                originalFileName: originalFileName
            },
            responseType: 'blob'
        })
    },

    // deleteFile(id){
    //     return request({
    //         method:'POST',
    //         url:'buiBase/directory/deleteFile',
    //         params:{
    //             contractTemplateId: id
    //         }
    //     })
    // },

    // 根据文件名模糊匹配查询对应的文件
    getFileDirectoryByFileName(fileName) {
        return request({
            method: 'POST',
            url: 'buiBase/directory/getByFileName',
            params: {
                fileName: fileName
            }
        })
    },

    // 获取文件的权限
    getPropertiesByAppendixId(appendixId) {
        return request({
            method: 'POST',
            url: 'buiBase/fileProperty/getByAppendixId',
            params: {
                appendixId: appendixId
            }
        })
    },


    // 获取文件的权限
    saveFileProperty(propertyList) {
        return request({
            method: 'POST',
            url: 'buiBase/fileProperty/saveFileProperty',
            data: propertyList
        })
    },

    deleteFileProperty(propertyId) {
        return request({
            method: 'POST',
            url: 'buiBase/fileProperty/deleteFileProperty',
            params: {
                propertyId: propertyId
            }
        })
    },

    transferPermission(propertyId, isGroup, ownerIdList) {
        return request({
            method: 'POST',
            url: 'buiBase/fileProperty/transferPermission',
            params: {
                propertyId: propertyId,
                isGroup: isGroup
            },
            data: ownerIdList
        })
    },

    mergeAndUploadFile(chunkDTO) {
        return request({
            method: 'POST',
            url: 'buiBase/directory/mergeAndUpload',
            data: chunkDTO
        })
    },



}
