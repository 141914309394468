// import request from '@/utils/sale_request'
import request from "@/utils/request";

// 添加调拨单记录
export const addTransfer = (params) => {
    return request({
        url: '/sales/transfer/addTransfer',
        method: 'post',
        data: params
    })
}

// 更新调拨单据状态
export const updateTransferReceiptStatus = (params) => {
    return request({
        url: '/sales/transfer/updateTransferReceiptStatus',
        method: 'post',
        data: params
    })
}

// 更新调拨后的库存
export const updateTransferStocks = (params) => {
    return request({
        url: '/sales/transfer/updateTransferStocks',
        method: 'post',
        data: params
    })
}

// 运输单页面获取调拨单
export const getTransferReceipts = (params) => {
    return request({
        url: '/sales/transfer/getTransferReceipts',
        method: 'post',
        data: params
    })
}