<template>
 <div id="ckeditor">
    <Ckeditor
      ref="editorRef"
      v-model="editorDesign"
      :editor="editor"
      :config="editorConfig"
      :disabled="disabled"
      @ready="onEditorReady"
      @focus="onEditorFocus"
      @blur="onEditorBlur"
      @input="onEditorInput"
      @destroy="onEditorDestroy">
    </Ckeditor>
  </div>
</template>

<script>
import { ref, reactive } from 'vue'
// import DocumentEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/zh-cn.js' //引入中文包

export default{
    components: {
  
    },

    props: {
        disabled: {
            type: Boolean,
            default: false, //是否禁用
        }, 
    },

    data(){
        return{
            editor: ClassicEditor, //编辑器
            editorDesign: '',
            editorConfig: {
                height: '400px',
                language: 'zh-cn',
                toolbar: {
                    items: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'imageUpload', 'blockQuote', 'insertTable', 'mediaEmbed', 'undo', 'redo'],
                },
                language: 'zh',
                image: {
                    toolbar: ['imageTextAlternative', 'toggleImageCaption', 'imageStyle:inline', 'imageStyle:block', 'imageStyle:side'],
                },
                table: {
                    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
                },
                ckfinder: {
                    uploadUrl: `/uploadfile`, // 上传图片接口
                    options: {
                    resourceType: 'Images',
                    },
                },
            },

        }
    },

    mounted(){
        
    },

    created: function() {
        
    },

    methods:{
        onEditorReady() {
            console.log('准备好了')
           
        },

        onEditorFocus() {
            console.log('聚焦')
            
        },

        onEditorBlur() {
            console.log('失去焦点')
           
        },

        onEditorInput() {
            console.log('正在录入')
            
        },

        onEditorDestroy() {
            console.log('销毁')
        }

    }
}
</script>

<style scoped>
 

    ::v-deep .ck-editor__editable_inline {
        height: 500px;
    }
</style>
