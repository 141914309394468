<template>
  <el-dialog title="请选择运输单位" @close="handleClose">
    <el-input placeholder="请输入运输单位简称" clearable v-model="name"/>
    <el-button type="primary" :icon="Search" @click="initGetDeliveryList">搜索</el-button>
    <el-table ref="myTable" :data="tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)" stripe border
        @selection-change="selectionChange" @select="select" @row-click="rowClick">
      
      <el-table-column type="selection" width="40"/>
      <el-table-column :prop="item.prop" :label="item.label" v-for="(item, index) in options" :key="index"></el-table-column>
    </el-table>

    <el-pagination :currentPage="currentPage" :page-size="pageSize" @current-change="handleCurrentChange"
        layout=" ->, prev, pager, next, total" :total="tableData.length">
    </el-pagination>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>

import { defineEmits, ref } from 'vue'
import { Search } from '@element-plus/icons-vue'
import { isNull } from '@/utils/filters'
import { getCompanyByName } from "@/api/sale/company";

const emits = defineEmits(['update:modelValue', 'setDeliveryInfo'])

// 查询条件
const name = ref('')

// 分页器
const currentPage = ref(1)
const pageSize = 5
const handleCurrentChange = (val) => {
  currentPage.value = val;
}

// 送货单位信息
const tableData = ref([])
// 初始化送货单位信息 & 查询送货单位信息
const initGetDeliveryList = async () => {
  const res = await getCompanyByName(name.value,103)
  tableData.value = res.data
}
initGetDeliveryList()

// 实现表格单选功能
const myTable = ref([])
const selectData = ref([])
const select = async (selection, row) => {
  // 清除 所有勾选项
  await myTable.value.clearSelection()
  // 当表格数据都没有被勾选的时候 就返回
  // 主要用于将当前勾选的表格状态清除
  if (selection.length === 0) return
  myTable.value.toggleRowSelection(row, true)
}
const selectionChange = (val) => {
  // 将选中的数据存储起来
  selectData.value = val
}
const rowClick = (row) => {
  myTable.value.clearSelection()
  if (selectData.value.length === 1) {
    selectData.value.forEach(item => {
      // 判断 如果当前的一行被勾选, 再次点击的时候就会取消选中
      if (item === row) {
        myTable.value.toggleRowSelection(row, false)
      } else {
        // 不然就让当前的一行勾选
        myTable.value.toggleRowSelection(row, true)
      }
    })
  } else {
    myTable.value.toggleRowSelection(row, true)
  }
}

// 对话框-取消
const handleClose = () => {
  emits('update:modelValue', false)
}

// 对话框-确认
const handleConfirm = () => {
  if (!isNull(selectData.value)) {
    const info = JSON.parse(JSON.stringify(selectData.value[0]))
    emits('setDeliveryInfo', info.companyId, info.companyName, info.contact, info.telephone, info.address)
  } else {
    emits('setDeliveryInfo', '', '', '', '', '')
  }
  handleClose()
}

// 定义表格表头
const options = [
  {
    label: ' ',
    prop: 'selection'
  },
  {
    label: '编号',
    prop: 'companyCode'
  },
  {
    label: '简称',
    prop: 'companyShortName'
  },
  {
    label: '名称',
    prop: 'companyName'
  },
  {
    label: '业务员',
    prop: 'salesManId'
  },
  {
    label: '联系人',
    prop: 'contact'
  },
  {
    label: '联系电话',
    prop: 'telephone'
  },
  {
    label: '联系地址',
    prop: 'address'
  }
]
</script>

<style scoped>
.el-input {
  margin: 0 0 10px 50px;
  width: 420px;
}
</style>
