import request from '@/utils/request'

export default {


    // 批量删除文件
    delFileList(fileNameList) {
        return request({
            method: 'post',
            url: '/fileUploader/upload/deleteFileList',
            data: fileNameList
        })
    },

    // 下载文件
    getPublishFile(filePath, fileName) {
        return request({
            method: 'post',
            url: '/fileUploader/upload/getPublishFile',
            params: {
                fileName: fileName,
                filePath: filePath,
            },
        })
    },

}
