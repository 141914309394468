<template>
  <el-dialog title="请选择单据" @close="handleClose" width="80%" :companyId="companyId" :companyName="companyName">
    <el-form v-model="queryForm">
      <el-row>
        <el-col :span="10" style="margin-left: 20px; margin-right: 50px;">
          <el-form-item label="提货地址">
            <el-input v-model="queryForm.getGoodsAddress" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="送货日期">
            <el-date-picker type="date" placeholder="请选择" style="width: 100%;" v-model="queryForm.deliveryDate"/>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="运输公司" style="margin-left: 15px;">
            <el-input v-model="companyName" clearable style="width: 100%;" @clear="handleClear" @click="handleTest"></el-input>
<!--            <el-input v-model="companyName" clearable style="width: 100%;" @clear="handleClear" @click="handleDeliveryDialogValue"></el-input>-->
          </el-form-item>
        </el-col>
<!--        <el-col :span="6">-->
<!--          <div class="grid-content">-->
<!--            <el-form-item label="运输公司"-->
<!--                          label-width="70px">-->
<!--              <el-select v-model="queryForm.companyId" placeholder="请选择"-->
<!--                         style="width: 200px;">-->
<!--                <el-option-->
<!--                    v-for="item in companyOptions"-->
<!--                    :key="item.value"-->
<!--                    :label="item.label"-->
<!--                    :value="item.value"-->
<!--                />-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </div>-->
<!--        </el-col>-->
      </el-row>

      <el-row>
        <el-col :span="10" style="margin-left: 20px; margin-right: 50px;">
          <el-form-item label="送货地址">
            <el-input v-model="queryForm.receiveAddress" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="单据类型">
            <el-checkbox-group v-model="checkList">
              <el-checkbox label="1">1 采购单</el-checkbox>
              <el-checkbox label="2">2 销售单</el-checkbox>
              <el-checkbox label="3">3 调拨单</el-checkbox>
            </el-checkbox-group>
            <el-button style="margin-left: 50px; width: 217px;" type="primary" @click="initGetReceiptsList">搜索</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table :data="transportationItemDTOList.slice((currentPage - 1) * pageSize, currentPage * pageSize)" stripe
      border style="width: 100%; margin-top: 10px;" @selection-change="selectionChange">
      <el-table-column :prop="item.prop" :label="item.label" v-for="(item, index) in options" :key="index" :width="item.width">
        <template v-if="item.prop === 'selection'">
          <el-table-column type="selection" width="40"/>
        </template>
        <template #default="score" v-if="item.prop === 'no'">
          {{ score.$index + (currentPage - 1) * pageSize + 1 }}
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
        :currentPage="currentPage"
        :page-size="pageSize"
        @current-change="handleCurrentChange"
        layout=" ->, prev, pager, next, total"
        :total="transportationItemDTOList.length">
    </el-pagination>
    
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import {defineEmits, defineProps, ref} from "vue";
import {isNull} from "@/utils/filters";
import {getSaleReceipts} from "@/api/sale/sales";
import {getTransferReceipts} from "@/api/sale/transfer";
import {getPurchaseReceipts} from "@/api/sale/purchase";
import moment from 'moment';


const emits = defineEmits(['update:modelValue', 'setTransportationItemsDTOList'])

// 分页器
const currentPage = ref(1)
const pageSize = 5
const handleCurrentChange = (val) => {
  currentPage.value = val;
}

// 定义接收的参数
const props = defineProps({
  companyId: {
    type: Object,
    default: null
  },
  companyName: {
    type: String,
    default: ''
  }
})

// 查询条件表单
const queryForm = ref({
  getGoodsAddress: null,
  receiveAddress: null,
  deliveryDate: null,
  receiptDate: null,
  companyId: null
})

const companyName = ref('')
const initCompInfo = () => {
  if (props.companyId != '') {
    companyName.value = props.companyName
    queryForm.value.companyId = props.companyId
  }
}
initCompInfo()



// 多选框被选中值
const checkList = ref([])

// 单据信息列表
const transportationItemDTOList = ref([])
// 初始化单据信息 & 查询单据信息
const initGetReceiptsList = async () => {
  // 格式化时间
  if (queryForm.value.deliveryDate !== null) {
    queryForm.value.deliveryDate = moment(queryForm.value.deliveryDate).format("yyyy-MM-DD HH:mm:ss");
  }

  let res = null
  transportationItemDTOList.value = []
  if (checkList.value.length === 0) {
    // 采购单
    res = await getPurchaseReceipts(queryForm.value)
    transportationItemDTOList.value = transportationItemDTOList.value.concat(res.data)
    // 销售单
    res = await getSaleReceipts(queryForm.value)
    transportationItemDTOList.value = transportationItemDTOList.value.concat(res.data)
    // 调拨单
    res = await getTransferReceipts(queryForm.value)
    transportationItemDTOList.value = transportationItemDTOList.value.concat(res.data)
  } else {
    for (let i = 0; i < checkList.value.length; i++) {
      switch (checkList.value[i]) {
        case '1':
          res = await getPurchaseReceipts(queryForm.value)
          transportationItemDTOList.value = transportationItemDTOList.value.concat(res.data)
          break
        case '2':
          res = await getSaleReceipts(queryForm.value)
          console.log("queryForm.value",queryForm.value);
          transportationItemDTOList.value = transportationItemDTOList.value.concat(res.data)
          break
        case '3':
          res = await getTransferReceipts(queryForm.value)
          transportationItemDTOList.value = transportationItemDTOList.value.concat(res.data)
          break
      }
    }
  }
}
initGetReceiptsList()

// 定义运输单明细表头
const options = [
  {
    label: '',
    prop: 'selection'
  },
  {
    label: '序号',
    prop: 'no',
    width: '50px'
  },
  {
    label: '单据编号',
    prop: 'receiptNo',
    width: '200px'
  },
  {
    label: '单据类型',
    prop: 'receiptType',
    width: '50px'
  },
  {
    label: '客户名称',
    prop: 'customerName'
  },
  {
    label: '收货人',
    prop: 'customerContacts',
    width: '70px'
  },
  {
    label: '联系电话',
    prop: 'telphone'
  },
  {
    label: '数量',
    prop: 'qty',
    width: '50px'
  },
  {
    label: '提货地址',
    prop: 'stockAddress',
    width: '200px'
  },
  {
    label: '送货地址',
    prop: 'customerAddress',
    width: '200px'
  },
  {
    label: '预约提货时间',
    prop: 'estimatedDeliveryTime',
    width: '90px'
  },
  {
    label: '预约送货时间',
    prop: 'estimatedReceiptTime',
    width: '90px'
  },
  {
    label: '备注',
    prop: 'remarks'
  }
]

// 将选中的数据存储起来
const selectData = ref([])
const selectionChange = (val) => {
  selectData.value = val
  
}

// 对话框-取消
const handleClose = () => {
  emits('update:modelValue', false)
}
// 对话框-确认
const handleConfirm = () => {
  if (!isNull(selectData.value)) {
    const info = JSON.parse(JSON.stringify(selectData.value))
    emits('setTransportationItemsDTOList', info)
  }
  handleClose()
}

// 运输公司输入框清除事件
const handleClear = () => {
  queryForm.value.companyId = null
}
</script>

<style scoped>
.el-form-item {
  margin-bottom: 5px;
}
</style>
