<template>
  <el-dialog title="销售明细" @close="handleClose" :data="selectedItems">

    <el-table :data="selectedItems.slice((currentPage - 1) * pageSize, currentPage * pageSize)">
      <el-table-column :prop="item.prop" :label="item.label" v-for="(item, index) in options" :key="index">
        <template #default="score" v-if="item.prop === 'no'">
          {{ score.$index + (currentPage - 1) * pageSize + 1 }}
        </template>
      </el-table-column>
    </el-table>
    
    <el-pagination
        v-model:currentPage="currentPage"
        v-model:page-size="pageSize"
        @current-change="handleCurrentChange"
        layout="->, total, prev, pager, next"
        :total="selectedItems.length">
    </el-pagination>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import {defineEmits, defineProps, ref} from "vue";

const emits = defineEmits(['update:modelValue'])

// 定义表头
const options = [
  {
    label: '序号',
    prop: 'no',
    width: '50px'
  },
  {
    label: '销售明细ID',
    prop: 'saleItemsId'
  },
  {
    label: '销售单ID',
    prop: 'saleId'
  },
  {
    label: '商品ID',
    prop: 'goodsId'
  },
  {
    label: '数量',
    prop: 'qty'
  },
  {
    label: '实际数量',
    prop: 'realQty'
  },
  {
    label: '金额',
    prop: 'amount'
  },
  {
    label: '实际金额',
    prop: 'realAmount'
  },
  {
    label: '税率',
    prop: 'saleTax'
  },
  {
    label: '价格',
    prop: 'salePrice'
  },
  {
    label: '折扣',
    prop: 'discount'
  },
  {
    label: '保修期',
    prop: 'warrenty'
  },
  {
    label: '备注',
    prop: 'remarks'
  },
  {
    label: '批号',
    prop: 'bacthCode'
  },
  {
    label: '配置',
    prop: 'feature'
  },
  {
    label: '保修单路径',
    prop: 'warrentyCardPath'
  }
]

// 从父组件接收的销售明细
const props = defineProps({
  selectedItems: {
    type: Object,
    default: null
  }
})

// 分页器
const currentPage = ref(1)
const pageSize = ref(5)
const handleCurrentChange = (val) => {
  currentPage.value = val
}

// 对话框-取消
const handleClose = () => {
  emits('update:modelValue', false)
}
// 对话框-确认
const handleConfirm = () => {
  // if (!isNull(selectData.value)) {
  //   // const info = JSON.parse(JSON.stringify(selectData.value))
  //   const info = selectData.value
  //   emits('setSaleItemsDTOList', info)
  // }
  handleClose()
}
</script>