import request from "@/utils/request";

export default {


    // 获取流程功能
    getFunctions(modelType) {
        return request({
            url: '/buiBase/function/getFunction',
            method: 'get',
            params: {
                modelType: modelType
            }
        })
    }

}
