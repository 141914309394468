<template>
  <el-dialog title="请选择产品信息" :fullscreen="false" width="80vw" @close="handleClose">
    <span>品牌：</span>
    <el-tree-select v-model="brandDef" :data="brandDefs" :render-after-expand="false"/>
    &nbsp;&nbsp;&nbsp;&nbsp;
    <span>产品类型：</span>
    <el-tree-select v-model="kindDef" :data="kindDefs" :render-after-expand="false"/>&nbsp;&nbsp;
    <el-input placeholder="请输入产品名称" clearable v-model="name"/>
    &nbsp;&nbsp;
    <el-button type="primary" :icon="Search" @click="initGetGoodsList">搜索</el-button>
    <el-button @click="clearCompany">清空</el-button>

    <el-table 
      :data="saleItemsDTOList.slice((currentPage - 1) * pageSize, currentPage * pageSize)" 
      stripe 
      :border="true"
      @selection-change="selectionChange" 
      @select="select" 
      @row-click="rowClick"
      height="60vh">

      <el-table-column type="selection" width="40"/>

      <!-- <el-table-column  prop="goodsId" label="id" /> -->

      <el-table-column  prop="goodsname" label="产品名称" width="200" />

      <el-table-column  prop="kindName" label="产品类型" />

      <el-table-column  prop="brandDefName" label="品牌" />

      <el-table-column  prop="isRawMaterial" label="产品分类" >
        <template #default="scope">
          {{ scope.row.isRawMaterial == 0? '产品': scope.row.isRawMaterial == 1? '原料' : scope.row.isRawMaterial == 3? '耗材' : '' }}
        </template>
      </el-table-column>

      <el-table-column  prop="goodsDesc" label="产品描述" />

      <el-table-column  prop="specTypeName" label="规格型号" />

      <el-table-column  prop="salePrice" label="销售单价" />

      <!-- <el-table-column  prop="saleTax" label="销售税率" /> -->

      <el-table-column  prop="baseName" label="销售单位" />

      <!-- <el-table-column  prop="purchaseUnitName" label="基本单位" /> -->

      <!-- <el-table-column  prop="vendorId" label="默认供应商" /> -->

      <!-- <el-table-column  prop="lifeTime" label="质保期(2)" /> -->
    </el-table>

    <el-pagination
      @current-change="handleCurrentChange"
      v-model:current-page="currentPage"
      :page-size="pageSize"
      layout="total, prev, pager, next"
      :total="saleItemsDTOList.length">
    </el-pagination>

    <template #footer>
      <span class="dialog-footer" >
        <el-button type="primary" @click="handleConfirm">确认</el-button>
        <el-button @click="handleClose">取消</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>

import { defineEmits, ref } from 'vue'
import { Search } from '@element-plus/icons-vue'
import { isNull } from '@/utils/filters'
import {getBrandDef, getGoodType, getGoods, searchGoodsByName} from "@/api/sale/goods";
import sort from "@/api/buiBase/sort"


const emits = defineEmits(['update:modelValue', 'handleSubmitChange'])

// 分页器
const currentPage = ref(1)
const pageSize = 20
const handleCurrentChange = (val) => {
  currentPage.value = val;
}

// 查询条件
const name = ref(null)
const kindDef = ref(null)
const brandDef = ref(null)

// 产品信息列表
const saleItemsDTOList = ref([])

// 产品分类信息
const kindDefs = ref([])

// 品牌信息
const brandDefs = ref([])

const sortList = ref([])
const sortTree = ref([])

const getSortTree = async () => {
  // const res2 = await getBrandDef()
  // brandDefs.value = res2.data
  const resSort = await sort.getSort()
  sortList.value = resSort.data
  // console.log('sortList',sortList.value)
  let data = sortList.value
  sortTree.value = []
  let childrenMap = new Map();
  for (let i = 0; i < data.length; i++) {  
      let it = data[i];  
      if (it.parentId == null) {  
          let d = [];  
          d.label = it.sortName;  
          d.value = it.sortId;  
          d.children = [];  
          childrenMap.set(d.value, d.children);  
          sortTree.value.push(d);  
      }  
  }  
  for (let i = 0; i < data.length; i++) {  
    let it = data[i];  
    if (it.parentId !== null) {   
      let parentChildren = childrenMap.get(it.parentId);  
      if (parentChildren) {  
        let d = [];  
        d.label = it.sortName;  
        d.value = it.sortId;  
        d.children = [];  
        childrenMap.set(d.value, d.children);   
        parentChildren.push(d);
      }  
    }
  } 
  if(sortTree.value[0] != null) {
    kindDefs.value = sortTree.value[0].children
  }
  if(sortTree.value[1] != null) {
    brandDefs.value = sortTree.value[1].children
  }
  initGetGoodsList()
}
getSortTree()

// 初始化产品信息 & 查询产品信息
const initGetGoodsList = async () => {
  if (name.value === null && kindDef.value === null && brandDef.value === null) {
    // const res1 = await getGoodType()
    // kindDefs.value = res1.data.goodType
    // console.log('kindDefs',kindDefs.value)
    const res = await getGoods()
    // console.log("sortList.value",sortList.value);
    saleItemsDTOList.value = res.data
    saleItemsDTOList.value.forEach(it=>{
      it.sortId = ''
      it.basePrice = it.basePrice.toFixed(2)
      it.inPrice = it.inPrice.toFixed(2)
      it.salePrice = it.salePrice.toFixed(2)
      let sortItem = sortList.value.find(item => item.sortId == it.kindDefId)
      it.kindName = sortItem ? sortItem.sortName : ''
      sortItem = sortList.value.find(item => item.sortId == it.brandDefId)
      it.brandDefName =  sortItem ? sortItem.sortName : ''
      sortItem = sortList.value.find(item => item.sortId == it.specType)
      it.specTypeName =  sortItem ? sortItem.sortName : ''
      sortItem = sortList.value.find(item => item.sortId == it.purchaseUnit)
      it.purchaseUnitName = sortItem ? sortItem.sortName : ''
      sortItem = sortList.value.find(item => item.sortId == it.baseUnit)
      it.baseName = sortItem ? sortItem.sortName : ''
      sortItem = sortList.value.find(item => item.sortId == it.goodsType)
      it.sortName = sortItem ? sortItem.sortName : ''
      sortItem = sortList.value.find(item => item.sortId == it.saleTax)
      it.saleTax = sortItem ? sortItem.sortName : 0.00
    })
    // console.log("saleItemsDTOList",saleItemsDTOList.value)
  } else {
    const res = await searchGoodsByName(name.value, kindDef.value, brandDef.value)
    saleItemsDTOList.value = res.data
    saleItemsDTOList.value.forEach(it=>{
      it.sortId = ''
      it.basePrice = it.basePrice.toFixed(2)
      it.inPrice = it.inPrice.toFixed(2)
      it.salePrice = it.salePrice.toFixed(2)
      let sortItem = sortList.value.find(item => item.sortId == it.kindDefId)
      it.kindName = sortItem ? sortItem.sortName : ''
      sortItem = sortList.value.find(item => item.sortId == it.brandDefId)
      it.brandDefName =  sortItem ? sortItem.sortName : ''
      sortItem = sortList.value.find(item => item.sortId == it.specType)
      it.specTypeName =  sortItem ? sortItem.sortName : ''
      sortItem = sortList.value.find(item => item.sortId == it.purchaseUnit)
      it.purchaseUnitName = sortItem ? sortItem.sortName : ''
      sortItem = sortList.value.find(item => item.sortId == it.baseUnit)
      it.baseName = sortItem ? sortItem.sortName : ''
      sortItem = sortList.value.find(item => item.sortId == it.goodsType)
      it.sortName = sortItem ? sortItem.sortName : ''
      sortItem = sortList.value.find(item => item.sortId == it.saleTax)
      it.saleTax = sortItem ? sortItem.sortName : 0.00
    })
  }
}

const clearCompany = async () => {
  brandDef.value = ''
  kindDef.value = ''
  name.value = ''
}

// 实现表格单选功能
// const myTable = ref([])
const selectData = ref([])
// const select = async (selection, row) => {
//   // 清除 所有勾选项
//   await myTable.value.clearSelection()
//   // 当表格数据都没有被勾选的时候 就返回
//   // 主要用于将当前勾选的表格状态清除
//   if (selection.length === 0) return
//   myTable.value.toggleRowSelection(row, true)
// }
const selectionChange = (val) => {
  // 将选中的数据存储起来
  selectData.value = val
}
// const rowClick = (row) => {
//   myTable.value.clearSelection()
//   if (selectData.value.length === 1) {
//     selectData.value.forEach(item => {
//       // 判断 如果当前的一行被勾选, 再次点击的时候就会取消选中
//       if (item === row) {
//         myTable.value.toggleRowSelection(row, false)
//       } else {
//         // 不然就让当前的一行勾选
//         myTable.value.toggleRowSelection(row, true)
//       }
//     })
//   } else {
//     myTable.value.toggleRowSelection(row, true)
//   }
// }

// 对话框-取消
const handleClose = () => {
  emits('update:modelValue', false)
}
// 对话框-确认
const handleConfirm = () => {
  if (!isNull(selectData.value)) {
    // const info = JSON.parse(JSON.stringify(selectData.value))
    const info = selectData.value
    emits('handleSubmitChange', info)
  }
  handleClose()
}


// 定义表格表头
const options = [
  
  {
    label: '产品编号',
    prop: 'goodsId'
  },
  {
    label: '产品名称',
    prop: 'goodsname'
  },
  {
    label: '产品描述',
    prop: 'goodsDesc'
  },
  {
    label: '是否为原料',
    prop: 'isRawMaterial'
  },
  {
    label: '规格型号',
    prop: 'specType'
  },
  {
    label: '销售单价',
    prop: 'salePrice'
  },
  {
    label: '销售税率',
    prop: 'saleTax'
  },

  {
    label: '单位',
    prop: 'baseName'
  },
  {
    label: '基本单位',
    prop: 'baseName'
  },
  {
    label: '默认供应商',
    prop: 'vendorId'
  },
  {
    label: '质保期（天）',
    prop: 'lifeTime'
  }
]
</script>

<style scoped>
.el-dialog {
  width: 50%;
}

.el-input {
  margin: 0 0 10px 50px;
  width: 420px;
}

.el-table {
  width: 100%;
}

#pagination-container {
  margin: 10px auto 0 auto;
  width: fit-content;
}

.el-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
