<template>
    <div id="pagenation">
        <el-pagination   
            :small="small"
            :popper-append-to-body="false" 
            :append-to-body="false"              
            v-model:current-page="condition.pageNum"
            :page-sizes="[20, 50, 100, 500,1000]"
            v-model:page-size="condition.pageSize"
            :layout="layout"
            :total="totalCount"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
        </el-pagination>
    </div>
</template>
<script>
import qs from 'qs';
export default {
    //分页插件
    name:"pagenation",
    emits:['change'],
    props:{
        //表格查询条件(查询条件中必须包含currentPage,pageSize)(sync)
        customerCondition:Object,
        //查询总数
        totalCount:Number,
        showSize:{
            type:Boolean,
            default:true,
        },
        customSearch:{
            type:Boolean,
            default:false,
        },

        small: {
            type:Boolean,
            default: false
        }
        
    },
    data(){
        return{
            layout:"total, sizes, prev, pager, next, jumper",
            condition: {
                pageSize: 20,
                pageNum: 1
            }
        }
    },
    created(){
        if(!this.showSize){
            this.layout="total, prev, pager, next, jumper"
        }
        if (this.customerCodition != null) {
            this.condition = this.customerCondition;
        }
    },
    //父组件需要实现search()函数查询表格数据
    methods:{
        handleSizeChange(){
            this.condition.pageNum = 1;
            this.$emit('update:condition',this.condition);
            this.$emit('change', {
                condition: this.condition
            });
            
        },
        handleCurrentChange(){
            
            this.$emit('update:condition',this.condition);   
            this.$emit('change', {
                condition: this.condition
            });
        },
    },

    watch: {
        customerCondition: {
            immediate: true,
            handler(newId, oldId) {
                this.condition = this.customerCondition;
            }
            
        }
    }
}
</script>
<style>
.el-pager li.active {
    color: #17171b
}
.el-pagination {
    color: #8084ea;
}
</style>