// import request from '@/utils/sale_request'
import request from '@/utils/request'

// 获取部门信息列表
// export const getCompanyDept = (data) => {
//     return request({
//         url: '/company/selectCompanyDepts',
//         method: 'get',
//         params: {
//             query: data
//         }
//     })
// }
export const getCompanyDept = (data) => {
    return request({
        url: '/sysbase/company_dept/companyDeptCondition',
        method: 'post',
        data: data
    })
}

export const getCompanyByName = (name, type) => {
    return request({
        method: 'get',
        url: '/buiBase/getCompanyByName',
        params: {
            name: name,
            type: type,
        },
    })
}

export const getCompanyMsg = (id, type) => {
    return request({
        method: 'get',
        url: '/buiBase/getCompanyMsg',
        params: {
            id: id,
            type: type,
        },
    })
}