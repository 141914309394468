<template>
  <el-dialog :title="companyDeptDialogTitle" @close="handleClose">

    <el-input placeholder="请输入部门简称" clearable v-model="queryForm.deptShortName" style="width: 20vw"/>

    <el-button type="primary" :icon="Search" @click="initGetCompanyDeptList">搜索</el-button>

    <el-table ref="myTable" :data="tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)" stripe :border="true"
      @selection-change="selectionChange" @select="select" @row-click="rowClick">
      
      <el-table-column type="selection" width="40"/>

      <el-table-column :prop="item.prop" :label="item.label" v-for="(item, index) in options" :key="index">
      </el-table-column>

    </el-table>

    <el-pagination :currentPage="currentPage" :page-size="pageSize" @current-change="handleCurrentChange"
        layout=" ->, prev, pager, next, total" :total="tableData.length">
    </el-pagination>
    
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { defineProps, defineEmits, ref } from 'vue'
import { Search } from '@element-plus/icons-vue'
import { isNull } from '@/utils/filters'
import { getCompanyDept } from "@/api/sale/company";

export default{
  emits:['update:modelValue','afterSubmit'],
  components: {

  },

  props:{
    companyDeptDialogTitle: {
      type: String,
      default: '',
      required: true
    }
  },

  data(){
    return {
      // 查询条件表单
      queryForm: {
        deptShortName: null,
        deptName: null
      }, 
      // 分页器
      currentPage: 1,
      pageSize: 5,
      // 部门信息列表
      tableData: [],

      // 实现表格单选功能
      myTable: [],
      selectData: [],

      // 定义表格表头
      options: [
        
        {
          label: '部门编号',
          prop: 'deptCode'
        },
        {
          label: '部门简称',
          prop: 'deptShortName'
        },
        {
          label: '部门名称',
          prop: 'deptName'
        },
        {
          label: '联系人',
          prop: 'contacts'
        },
        {
          label: '联系电话',
          prop: 'telPhone'
        },
        {
          label: '部门性质',
          prop: 'deptLevel'
        },
        {
          label: '状态',
          prop: 'statusFlag'
        }
      ]

    }
  },

  mounted(){
    
  },
  created: function() {
    this.initGetCompanyDeptList();
  },

  methods:{
    handleCurrentChange(val) {
      this.currentPage = val;
    },

    // 初始化部门信息 & 查询部门信息
    async initGetCompanyDeptList() {
      const res = await getCompanyDept(this.queryForm);
      this.tableData = res.data
    },

    async select (selection, row) {
      // 清除 所有勾选项
      await this.$refs.myTable.clearSelection()
      // 当表格数据都没有被勾选的时候 就返回
      // 主要用于将当前勾选的表格状态清除
      if (selection.length === 0) return
        this.$refs.myTable.toggleRowSelection(row, true)
      },

      selectionChange(val) {
        // 将选中的数据存储起来
        this.selectData = val
      },

    rowClick(row) {
      this.$refs.myTable.clearSelection()
      if (this.selectData.length === 1) {
        this.selectData.forEach(item => {
          // 判断 如果当前的一行被勾选, 再次点击的时候就会取消选中
          if (item === row) {
            this.$refs.myTable.toggleRowSelection(row, false)
          } else {
            // 不然就让当前的一行勾选
            this.$refs.myTable.toggleRowSelection(row, true)
          }
        })
      } else {
        this.$refs.myTable.toggleRowSelection(row, true)
      }
    },

    // 对话框-取消
    handleClose() {
      this.$emit('update:modelValue', false)
    },

    // 对话框-确认
    handleConfirm() {
      this.$emit('afterSubmit',this.selectData)
      this.handleClose();
    }
  }
}
</script>

<style scoped>
 
  .action-bar {
    width: 100%;
    height: 6vh;
    padding: 10px 20px 10px 20px;
    /* display: flex; */
    align-items: center;
 
    
  }
 

  .main-content {
    height: 92vh;
    
  }

  .content-title {
    height: 6vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content-filter {
    height: 5vh;
    
  }

  .content-table {
    height: 80vh;
    
  }

  .title-style {
    font-size: 20px;
    font-weight: bold; 
  }

  .search-action {
    width: 400px;
    float: right;
  }

  .is-disabled {
    color: #a8abb2;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
    margin-top: 4.5px;
  }
  .el-icon-arrow-down {
    font-size: 13px;
    margin-right: 2px;
    
  }
  /* .is-disabled:hover {
    background-color: #8caadf;

    color: #a8abb2;
  } */

</style>
