<template>
  <el-dialog title="请选择业务组" width="80%" @close="handleClose" >

    <el-table 
      ref="myTable" 
      :highlight-current-row="!multipleSelect" 
      :data="tableData" 
      stripe 
      border
      @current-change="handleCurrentChange" 
      @selection-change="selectionChange" 
      @row-click="rowClick" 
      row-key="groupId"
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
      height="60vh">

      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column prop="groupName" label="名称"/>
      <el-table-column prop="groupType" label="类型" :formatter="formatRoleType"/>
    </el-table>
    
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import request from "@/api/acl/group";

export default{
  emits: ['update:modelValue','afterClose'],
  props:{
    multipleSelect: {
      type: Boolean,
      default: true
    },

    excludeGroupIdList: {
      type: Array,
      default: []
    }
  },
  components: {
    
  },
  data(){
    return{
      name: '',
      // 分页器
      currentPage: 1,
      pageSize: 20,
      tableData: [],
      myTable: [],
      selectData:[],
      singgleGroup: null,
      
    
    }
  },

   mounted(){
    
  },
  created: function() {
    this.initGetGroupList();
  },

  methods:{
    handlePageChange(val) {
      this.currentPage = val;
    },

    async initGetGroupList() {
      //获取数据
      await request.getGroupList().then(res=>{
        if (res.code === 200) {
          this.tableData = res.data;
        }
      })
      
    },

    selectionChange(val) {
      // 将选中的数据存储起来
      this.selectData = val;
    },

    rowClick(row) {
      let hasSelect = false;
      this.selectData.forEach(item => {
        // 判断 如果当前的一行被勾选, 再次点击的时候就会取消选中
        if (item === row) {
          hasSelect = true;
          this.$refs.myTable.toggleRowSelection(row, false)
        } 
      })

      if (!hasSelect) {
        this.$refs.myTable.toggleRowSelection(row, true);
      }
    },

    // 对话框-取消
    handleClose() {
      this.$emit('update:modelValue', false)
    },

    // 对话框-确认
    handleConfirm() {
      if (this.multipleSelect) {
        this.$emit('afterClose',this.selectData)
      } else {
        this.$emit('afterClose',this.singgleGroup)
      }
    },

    handleCurrentChange(val) {
      this.singgleGroup = val;
    },

    formatRoleType(row, column, cellValue) {
      if (cellValue == '0') {  
        return ''; 
      }else if(cellValue == '100') {
        return '系统内部权限';
      }else if(cellValue == '101') {  
        return '供应商权限'; 
      }else if(cellValue == '102') {  
        return '客户权限'; 
      }else if(cellValue == '103') {
        return '运输公司权限';
      }else {
        return '未选择权限类型';
      }
    },



  },

  computed: {
    businessGroupList() {
      if (this.excludeGroupIdList == null || this.excludeGroupIdList.length == 0) {
        return this.tableData;
      } 
      for (let i=0; i<this.tableData.length; i++) {

      }

    }
  }
}
</script>

<style scoped>
 
  .el-input {
    margin: 0 0 10px 50px;
    width: 420px;
  }

  /* ::v-deep .el-dialog__body {
    padding-bottom: 0px;
  } */

</style>
