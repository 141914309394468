<template>
  <div>
    <div v-if="formulaArray == null || formulaArray.length==0">
      <span class="add-formula">
        <el-button 
          size="small" 
          type="text" 
          @click="handleAddFormula(null,-1)">
          <el-icon><Plus /></el-icon>
        </el-button>

        <el-button 
          size="small" 
          type="text" 
          @click="handleAddChildFormula(null,-1)">
          ()+
        </el-button>
      </span>
    </div>
    <div 
      v-for="(formula,index) in formulaArray" 
      v-bind:key="index" 
      class="formula-style" 
      v-else>
      <div 
        v-if="formula.parentheses != null && formula.parentheses != ''" 
        :style="spaceStyle(formula.parentLevel)">
        {{formula.parentheses}}
        <el-select 
          v-model="formula.operator" 
          :filterable="false" 
          style="width:80px" 
          class="hidden-input" 
          v-if="formula.operator != null && formula.operator != ''">
          <el-option
          v-for="(operator,operatorIndex) in operatorList"
          :key="operatorIndex"
          :label="operator.operatorName"
          :value="operator.operatorName">
          </el-option>
        </el-select>

        <span class="delete-formula" v-if="formula.parentheses==')'">
          <el-button 
            size="mini" 
            type="text" 
            class="deleteButtton" 
            @click="handleDeleteChildFormula(index)">
            <el-icon><Delete /></el-icon>
          </el-button>
        </span>

        <span class="add-formula" v-if="formula.lastParameter">
          <el-button 
            size="small" 
            type="text" 
            @click="handleAddFormula(formula,index)">
            <el-icon><Plus /></el-icon>
          </el-button>

          <el-button 
            size="small" 
            type="text" 
            @click="handleAddChildFormula(formula,index)">
            ()+
          </el-button>
        </span>
      </div>
          
      <div v-else :style="spaceStyle(formula.parentLevel)">
        <el-select 
          v-model="formula.parameter" 
          placeholder="参数" 
          filterable 
          style="width:150px" 
          @change="changeParameter($event, formula)">
          <el-option
            v-for="(summary,summaryIndex) in parameterList"
            :key="summaryIndex"
            :label="summary.summaryName"
            :value="summary.summaryCode">
          </el-option>
        </el-select>

        
        <el-dropdown 
          @command="handleCommand($event, formula)"
          style="height: 24px; align-items: center; margin: 0 10px;">
          <span class="el-dropdown-link">
            {{formula.compareOperator}}
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <div 
                v-for="(operator, operatorIndex) in operatorList" 
                :key="operatorIndex">
                <el-dropdown-item :command="operator.operatorName">
                  {{operator.operatorName}}
                </el-dropdown-item>
              </div>
            </el-dropdown-menu>
          </template>
        </el-dropdown>

        <el-select 
          v-model="formula.compareParameter" 
          placeholder="参数" 
          filterable 
          allow-create 
          style="width:150px" 
          @change="changeParameter($event, formula)">
          <el-option
            v-for="(summary,summaryIndex) in parameterList"
            :key="summaryIndex"
            :label="summary.summaryName"
            :value="summary.summaryCode">
          </el-option>
        </el-select>

        <span style="margin-left: 10px;">
          <el-button 
            link
            @click="handleChangeOperator(formula)">
            {{formula.operator}}
          </el-button>
        </span>

        <span class="delete-formula">
          <el-button 
            size="mini" 
            type="text" 
            class="deleteButtton" 
            @click="handleDeleteFormula(index)">
            <el-icon><Delete /></el-icon>
          </el-button>
        </span>

        <span class="add-formula" v-if="formula.lastParameter">
          <el-button 
            size="small" 
            type="text" 
            @click="handleAddFormula(formula,index)">
            <el-icon><Plus /></el-icon>
          </el-button>
          <el-button 
            size="small" 
            type="text" 
            @click="handleAddChildFormula(formula,index)">
            ()+
          </el-button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>

export default{
  emits:['submitCondition', 'updateCoditionName'],
  components: {},
  props:{
      originalFormula: String,
      formulaList: {
          type: Array,
          default: []
      },
      operatorList:{
          type: Array,
          default: []
      },
      parameterList:{
          type: Array,
          default: []
      },

  },
  data(){
    return{
      
      formulaArray:[],

      size: 'mini',
      labelSpan: 2,
      inputSpan: 5,
      expression: '',
      formulaName: '',
      selectOperator: '<?>'
      
    }
  },

  mounted(){
    
  },

  created: function() {
    // this.formulaArray = this.formulaList;
  },


  methods:{

    spaceStyle(level) {
      return {  
        'padding-left': level * 15 + 'px'  
      };  
    },
   
    handleAddFormula(formula, index) {
      
      let parentLevel = 0;
      if (formula != null) {
        let level = formula.parentLevel;
        if (level == null || level == '') {
          level = 0;
        }
        parentLevel = level;
      }
      
      if (formula != null) {
        formula.lastParameter = false;
        if (formula.parentheses == null || (formula.parentheses != null && formula.parentheses != '(')) {
          formula.operator = 'and';
        }
        
      }

      let addFormula = {
        formulaId: 0,
        formulaName: '',
        operator: '',
        parameter: '',
        parentheses: '',
        parentLevel: parentLevel,
        lastParameter: true,
        compareOperator: '>',
        compareParameter: ''
      };
      
      this.formulaArray.splice(index+1, 0, addFormula);
      let message = this.getFormulaExpression();
      console.log("errorMessage", message)
    },

    handleAddChildFormula(formula,index) {
      let parentLevel = 1;
      if (formula != null) {
        let level = formula.parentLevel;
        if (level == null || level == '') {
          level = 0;
        }
        parentLevel = level + 1;
      }

      if (formula != null) {
        formula.lastParameter = false;
        if (formula.parentheses == null || (formula.parentheses != null && formula.parentheses != '(')) {
          formula.operator = 'and';
        }
      }

      let openParentheses = {
        formulaId: 0,
        formulaName: '',
        operator: '',
        parameter: '',
        parentheses: '(',
        parentLevel: parentLevel - 1,
        lastParameter: false
      };

      let parameter = {
        formulaId: 0,
        formulaName: '',
        operator: '',
        parameter: '',
        parentheses: '',
        parentLevel: parentLevel,
        lastParameter: true,
        compareOperator: '>',
        compareParameter: ''
      };

      let closeParentheses = {
        formulaId: 0,
        formulaName: '',
        operator: '',
        parameter: '',
        parentheses: ')',
        parentLevel: parentLevel - 1,
        lastParameter: true

      };
      
      
      this.formulaArray.splice(index+1, 0, openParentheses);
      this.formulaArray.splice(index+2, 0, parameter);
      this.formulaArray.splice(index+3, 0, closeParentheses);
      this.getFormulaExpression();
    },

    handleDeleteFormula(index) { 
      
      if (this.formulaArray != null) {
        let deleteFormula = this.formulaArray[index];
        let level = deleteFormula.parentLevel;
        let lastParameter = deleteFormula.lastParameter;
        let length = this.formulaArray.length;
        let last = index-1;
        let next = index+1;
        let start = -1;
        let end = -1;
        while (last>=0 && next <= (length-1) ){
          let lastFormula = this.formulaArray[last];
          let nextFormula = this.formulaArray[next];
          if (lastFormula.parentheses == '(' && nextFormula.parentheses == ')') {
            start = last;
            end = next;
            last --;
            next ++;
          } else {
            break;
          }
          
        }

        if (start == -1 && end == -1) {
          this.formulaArray.splice(index,1);
        } else {
          for (let i=end; i>=start; i--) {
            this.formulaArray.splice(i,1);
          }
        }

        if (lastParameter) {
          this.resetLastParameters(level);
        }
        
      }
      this.getFormulaExpression();
    },

    handleDeleteChildFormula(index) {
      
      if (this.formulaArray != null) {
        let deleteFormula = this.formulaArray[index];
        let level = deleteFormula.parentLevel;
        let lastParameter = deleteFormula.lastParameter;
        let length = this.formulaArray.length;
        let num = 0;
        while (index >= 0) {
          let parameter = this.formulaArray[index].parentheses;
          if (parameter != null && parameter == '(') {
            num --;
            this.formulaArray.splice(index,1);
            if (num == 0) {
              break;
            }

          } else {
            this.formulaArray.splice(index,1);
            if (parameter != null && parameter == ')') {
              num ++;
            }
          }
          index --;
        }

        if (index > 0) {
          length = this.formulaArray.length;
          let last = index-1;
          let next = index;
          let start = -1;
          let end = -1;
          while (last>=0 && next <= (length-1) ){
            let lastFormula = this.formulaArray[last];
            let nextFormula = this.formulaArray[next];
            if (lastFormula.parentheses == '(' && nextFormula.parentheses == ')') {
              start = last;
              end = next;
              last --;
              next ++;
            } else {
              break;
            }
          }

          if (start != -1 && end != -1) {
            for (let i=end; i>=start; i--) {
              this.formulaArray.splice(i,1);
            }
          }

        }
        
        if (lastParameter) {
          this.resetLastParameters(level);
        }
      }
      this.getFormulaExpression();
    },

    changeParameter(selectOption, formula) {
      let isParameter = false;
      let parameterList = this.parameterList;
      formula.formulaName = '';
      if (parameterList != null) {
        for (let i=0; i<parameterList.length; i++) {
          
          let parameter = parameterList[i];
          if (parameter.summaryCode == selectOption) {
            isParameter = true;
            formula.formulaName = parameter.summaryName;
            break;
          }
          
        }
      }
      if (!isParameter) {
        formula.formulaName = selectOption;
        formula.constant = true;
      }
      this.getFormulaExpression();
    },

    /**
     * 提交
     */
    submit() {
      let message = this.getFormulaExpression();
      if (message != null && message != '') {
       this.$message({
              type: 'error',
              message: message
          });
      } else {
        
        this.$emit('submitCondition', {
          calculationFormula: this.expression,
          formulaName: this.formulaName
        });
      }

      
    },

    /**
     * 根据选择的结果获取算式表达式
     */
    getFormulaExpression() {
      let errorMessage = null;
      let expression = '';
      let readName = '';
      let formulaArray = this.formulaArray;
      if (formulaArray != null) {
        for (let i=0; i<formulaArray.length; i++) {
          let formula = formulaArray[i];
          if (formula.parentheses != null && formula.parentheses != '') {
            expression += formula.parentheses;
            readName += formula.parentheses;
          } else {
            let parameter = formula.parameter;
            let compareParameter = formula.compareParameter;
            let compareOperator = formula.compareOperator;
            let isConstant = formula.constant;
            
            let formulaName = this.getParameterNameByCode(parameter);
        
            if (isConstant) {
              if (isNaN(Number(compareParameter))) {
                errorMessage = "参数" + parameter + "为自定义的常量，常量参数需要设置成数值";
                return errorMessage;
              }
              expression += "[" + parameter + "] " + compareOperator + " [" + compareParameter + "]";
              readName += formulaName  + " " + compareOperator + " " + compareParameter; 

            } else {
              let compareParamName = this.getParameterNameByCode(compareParameter);
              expression += "[" + parameter + "] " + compareOperator + " [" + compareParameter + "]";
              readName += formulaName + " " + compareOperator + " " + compareParamName; 
            }
            
          }

          let operator = formula.operator;
          if (operator != null && operator != '') {
            expression += " " + operator + " ";
            readName += " " + operator + " ";
          }
        }
      }
    
      this.expression = expression;
      this.formulaName = readName;
      this.$emit('updateCoditionName', this.expression, this.formulaName);
      return errorMessage;
    },

    /**
     * 根据参数的code获取参数的name
     */
    getParameterNameByCode(code) {
      let parameterName = null;
      let parameterList = this.parameterList;
      if (parameterList != null) {
        for (let i=0; i<parameterList.length; i++) {
          let parameter = parameterList[i];
          if (parameter.summaryCode == code) {
            parameterName = parameter.summaryName;
            break;
          }
        }
      }
      return parameterName;
    },

    

    /**
     * 删除一个算式后如果删掉的是每一级中的最后一个参数，则需要重新找到该级别的新的最后一个元素
     */
    resetLastParameters(level) {
      
      if (this.formulaArray != null) {
        for (let i=0; i<this.formulaArray.length; i++) {
          let parentLevel = this.formulaArray[i].parentLevel;
          // if (parentLevel == level) {
            if (i == (this.formulaArray.length - 1) || this.formulaArray[i+1].parentLevel < parentLevel) {
              this.formulaArray[i].lastParameter = true;
              this.formulaArray[i].operator = '';
            }
          // }
        }
      }
      
    },

    handleChangeOperator(formula) {
      if (formula.operator == 'and') {
        formula.operator = 'or';
      } else {
        formula.operator = 'and';
      }
    },

    handleCommand(command, formula) {
      formula.compareOperator = command;
    }
   
  },

  computed () {
    
  },

  watch: {
      formulaList: {
          immediate: true,
          handler(newId, oldId) {
            this.formulaArray = this.formulaList;
            let message = this.getFormulaExpression();
            console.log("errorMessage", message)
          } 
      }
      
  }
}
</script>

<style scoped>
 
  
  .formula-style {
    margin: 10px 10px 10px 10px;
  }
  
  .add-formula {
    margin: 10px 10px 10px 30px;
  }

  .deleteButtton {
    color: red;
  }
  .delete-formula {
    margin: 10px 10px 10px 10px;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: var(--el-color-primary);
    display: flex;
    align-items: center;
  }
 

</style>
