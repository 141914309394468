<template>
  <div class="layout-root">
    <div class="sideBar">
      <sidebar/>
    </div>

    <div class="head-content">
      <div class="head">
        <div class="head-navBar">
          <navbar/>
        </div>
        <!-- <div class="head-tags">
          <tags-view/>
        </div> -->
      </div>

      <div class="content">
        <app-main/>
      </div>
    </div>


  </div>
</template>

<script>
import AppMain from './components/AppMain'
import Navbar from './components/Navbar'
import Sidebar from './components/Sidebar/index'
import TagsView from './components/TagsView/index'
export default {
  name: "Layout",
  components: {
    AppMain,Navbar,Sidebar,TagsView
  }
}
</script>

<style scoped lang="scss">
.layout-root {
  display: flex;
  height: 100vh;
  width: 100vw;
  // min-height: 100vh;
  // .layout-sidebar {
  //   width: 200px;
  //   height: 100%;
  //   min-height: 100vh;
  // }
  // .right {
  //   width: calc(100vw - 200px);
  // }
}


.sideBar {
  width: 13%;
  float: left;

}

.head-content {
  width: 87%;
  height: 100%;

}

.head {
  height: 6vh;
  width: 100%;

}

.head .head-navBar {
  height: 6vh;
  width: 100%;

}

.head .head-tags {
  height: 6vh;
  width: 100%;

}

.content {
  width: 100%;
  height: 94vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  // padding-top: 10px;
  // margin-top: 10px;
}

.content::after {
  content: '';
  display: block;
  clear: both;

}

</style>
