export default {
    uuid: function() {
        const chars = "AS2DF3GHJK4LZXCV1BNMQWE5RTYU6IOPzxcvb7nmqwe0rty8uiopas9dfghjkl";
        let ran = '';	// 随机两个字符
        for(let i = 0; i<2; i++){
            ran += chars.charAt(Math.ceil(Math.random()*61));
        }
        const flag = 'W';	// 小程序标识

        const nowdate = new Date();

        const year = (nowdate.getFullYear()).toString().slice(2, 4),

            month = this.formatFun(nowdate.getMonth() + 1, 2),

            date = this.formatFun(nowdate.getDate(), 2),

            h = this.formatFun(nowdate.getHours(), 2),

            m = this.formatFun(nowdate.getMinutes(), 2),

            s = this.formatFun(nowdate.getSeconds(), 2),

            S = this.formatFun(nowdate.getMilliseconds(), 3);

        const dateStr = year + month + date + h + m + s + S;

        const uuid = flag + dateStr + ran;

        return uuid;
    },

    formatFun: function (number,type) {
        if (type == 2 && number < 10) {
            number = '0' + number
        }
        if (type == 3 && number < 10) {
            number = '00' + number
        } else if (type == 3 && number < 100) {
            number = '0' + number
        }
        return number.toString();
    }
}
