// import request from '@/utils/sale_request'
import request from "@/utils/request";

// 获取销售单信息列表
export const getSales = (data) => {
    return request({
        url: '/sales/sales/selectSales',
        method: 'post',
        data: data
    })
}





// // 获取销售单信息列表
// export const getSales = (data) => {
//     return request({
//         url: '/sales/selectByPageCondition',
//         method: 'post',
//         data: data
//     })
// }

// 根据销售单流水号获取销售明细信息列表
export const getSaleItems = (saleId) => {
    return request({
        url: '/sales/sales/selectSaleItems',
        method: 'get',
        params: {
            saleId: saleId
        }
    })
}



// 添加销售单记录
export const addSale = (data) => {
    return request({
        url: '/sales/sales/saveSale',
        method: 'post',
        data: data
    })
}

// 订单转化为销售单
export const orderTransformSale = (data) => {
    return request({
        url: '/sales/sales/orderTransformSale',
        method: 'post',
        data: data
    })
}



// 更新销售单据状态
export const updateSaleReceiptStatus = (params) => {
    return request({
        url: '/sales/sales/updateSaleReceiptStatus',
        method: 'post',
        data: params
    })
}

// 更新库存
export const updateStocks = (params) => {
    return request({
        url: '/sales/sales/updateStocks',
        method: 'post',
        data: params
    })
}

// 运输单页面获取销售单
export const getSaleReceipts = (params) => {
    return request({
        url: '/sales/sales/getSaleReceipts',
        method: 'post',
        data: params
    })
}

export const  deleteSaleId = (saleId) => {
    return request({
        url: '/sales/sales/deleteSale',
        method: 'post',
        params: {
            saleId: saleId
        }
    })
}



// 运输单页面获取销售单
export const submitProcess = (sale, code, reviewComments) => {
    return request({
        url: '/sales/sales/submitProcess',
        method: 'post',
        data: sale,
        params: {
            code: code,
            reviewComments: reviewComments
        }
    })
}
