<template>
    <div id="RemoveMask"> 
        <div class="image-with-overlay" :style="height">
            <el-button link type="danger" class="overlay-text" :style="'left: ' + (preview.preview == 'true'? '57%;' : '50%;')" @click="delImg">
                <!-- <el-icon><Delete /></el-icon> -->
                <span style="font-size: 15px;">删除</span>
            </el-button>
            <el-button v-if="preview.preview == 'true'" link type="primary" class="overlay-text" style="left: 43%" @click="previewImg">
                <span style="font-size: 15px;">预览</span>
            </el-button>
            <slot name="body"></slot>
        </div>
    </div>
</template>
<script>
   

    export default {
        name: "RemoveMask",
        emits:['onRemove','onPreview'],
        components: {   
         
        },
        props: {
            height: {
                type: String,
                default: '100%',
            },
            preview: {
                type: String,
                default: 'false',
            },
        },
       
        data() {
            return {
               
            }
        },
        created() {
            
        },
        methods: {
            delImg() {
                this.$emit('onRemove');
            },
            previewImg() {
                this.$emit('onPreview');
            }
        },
        computed: {  
            height() {  
                return {  
                    height: this.height
                }
            },
            preview() {
                return {
                    preview: this.preview
                }
            }
        },

        filters: {
           
        },
        watch: {
           
        }
    }
</script>

<style scoped>

.image-with-overlay {
  position: relative;
  display: inline-block;
}

.image-with-overlay::after {
  border-radius: 5px; /*调节圆周程度*/
  opacity: 0;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  transition: all 0.3s ease;
}

.overlay-text {
  opacity: 0;
  border: none; 
  position: absolute;
  top: 50%;
  /* left: 50%; */
  transform: translate(-50%, -50%);
 
  z-index: 1;
}

.image-with-overlay:hover.image-with-overlay::after,

.image-with-overlay:hover .overlay-text{
  opacity: 1;
}
   

</style>
