<template>
    <el-dialog title="请选择公司信息" :fullscreen="false" width="90%" @close="handleClose">

        <!-- 搜索 -->
        <span>公司分类：</span>
        <el-tree-select v-model="condition.condition.kindId" :data="kindTree" @change="searchCompany" :render-after-expand="false"/>&nbsp;&nbsp;
        <span>公司来源：</span>
        <el-tree-select v-model="condition.condition.sourceId" :data="sourceTree" @change="searchCompany" :render-after-expand="false"/>&nbsp;&nbsp;
        <el-input class="dialog-input" placeholder="请输入公司名称" clearable v-model="condition.condition.companyName"/>&nbsp;&nbsp;
        <el-button type="primary" @click="searchCompany">搜索</el-button>
        <el-button @click="clearCompany">清空</el-button>

        <!-- 公司列表 -->
        <el-table ref="tableRef" class="el-table" height="300" :data="companyList" border size="small" @select-all="handleSelectAll" @select="handleSelectionChange">
            <el-table-column fixed type="selection" width="40"/>
            <el-table-column fixed type="index" label="序号" width="50"/>
            <el-table-column fixed prop="companyCode" label="编码" sortable width="100" :show-overflow-tooltip="true"/>
            <el-table-column prop="parentCompanyName" label="主公司名称" sortable width="180" :show-overflow-tooltip="true"/>
            <el-table-column prop="companyShortName" label="公司简称" sortable width="120" :show-overflow-tooltip="true"/>
            <el-table-column prop="companyName" label="公司名称" sortable width="180" :show-overflow-tooltip="true"/>
            <el-table-column prop="taxCode" label="统一社会信用代码" sortable width="180" :show-overflow-tooltip="true"/>
            <el-table-column prop="registerType" label="类型" sortable width="120" :show-overflow-tooltip="true"/>
            <el-table-column prop="kindName" label="分类" sortable width="120" :show-overflow-tooltip="true"/>
            <el-table-column prop="sourceName" label="来源" sortable width="120" :show-overflow-tooltip="true"/>
            <el-table-column prop="registerAddress" label="地址" width="auto" :show-overflow-tooltip="true"/>
        </el-table>

        <!-- 分页 -->
        <el-pagination
            @current-change="getCompanyPage"
            v-model:current-page="condition.pageNum"
            :page-size="condition.pageSize"
            layout="total, prev, pager, next"
            :total="totalCount">
        </el-pagination>

        <!-- 确认或取消 -->
        <template #footer>
            <span class="dialog-footer">
                <el-button type="primary" @click="handleConfirm">确认</el-button>
                <el-button @click="handleClose">取消</el-button>
            </span>
        </template>
        
    </el-dialog>
</template>

<script>
    import company from "@/api/buiBase/company"
    import corporation from '@/api/buiBase/corporation'

    export default {
        name: "companyDialog",
        emits:['update:modelValue', 'handleConfirm'],
        props: {
            isSingleChoice: {
                type: String,
            },
            companyType: {
                type: String,
            },
            title: {
                type: String,
            },
        },
        data() {
            return {
                kindTree: [], //类型树
                sourceTree: [], //来源树
                selectedRow: [], //选中行
                companyList: [], //公司列表
                clientButton: false, //委托客户确认按钮
                //公司列表查询条件
                condition: {
                    pageSize: 10,
                    pageNum: 1, 
                    condition: {
                        statusFlag: 126,
                        companyType: '',
                        companyName: '',
                        kindId: '',
                        sourceId: '',
                    }
                },
                totalCount: 0, //公司总数
            }
        },
        mounted() {
            //获取公司属性树
            this.getCorporationTree()
        },
        methods: {
            //模糊搜索公司
            searchCompany() {
                this.condition.pageNum = 1
                this.getCompanyPage()
            },
            //清空搜索条件
            clearCompany() {
                this.condition.condition.companyName = ''
                this.condition.condition.kindId = ''
                this.condition.condition.sourceId = ''
            },
            //获取公司列表
            async getCompanyPage() {
                this.condition.condition.companyType = this.companyType
                await company.getCompanyPage(this.condition).then(res=>{
                    if (res != null && res.code == 200) {
                        this.totalCount = res.data.totalSize
                        this.companyList = res.data.companyList
                    }
                })
            },
            //获取公司属性树
            async getCorporationTree(){
                let res = await corporation.getCorporation()
                let data = res.data
                let corporationTree = []
                // 创建一个map，存储每个节点的所有子节点  
                let childrenMap = new Map();  
                // 处理根节点  
                for (let i = 0; i < data.length; i++) {  
                    let it = data[i];  
                    if (it.parentId == '0') {  
                        let d = {};  
                        d.label = it.corporationName;  
                        d.value = it.corporationId; 
                        d.children = [];  
                        // 把当前节点存储为其自身子节点的父节点  
                        childrenMap.set(d.value, d.children);  
                        corporationTree.push(d);  
                    }  
                }  
                // 处理非根节点，将每个节点的子节点添加到父节点的children中  
                for (let i = 0; i < data.length; i++) {  
                    let it = data[i];  
                    if (it.parentId !== '0') {  
                        // 获取父节点的children  
                        let parentChildren = childrenMap.get(it.parentId);  
                        if (parentChildren) {  
                            let d = {};  
                            d.label = it.corporationName;  
                            d.value = it.corporationId; 
                            d.children = [];  
                            // 把当前节点存储为其自身子节点的父节点  
                            childrenMap.set(d.value, d.children);  
                            parentChildren.push(d);  
                        }  
                    }
                }
                if(this.companyType == 101) {
                    this.kindTree = corporationTree[0].children
                    this.sourceTree = corporationTree[3].children
                }else if(this.companyType == 102) {
                    this.kindTree = corporationTree[1].children
                    this.sourceTree = corporationTree[4].children
                }else if(this.companyType == 103) {
                    this.kindTree = corporationTree[2].children
                    this.sourceTree = corporationTree[5].children
                }
            },
            //确认选择
            handleConfirm() {
                this.$emit('handleConfirm', this.selectedRow)
                this.$refs.tableRef.clearSelection()
            },
            //关闭公司弹窗
            handleClose() {
                this.selectedRow = []
                this.$emit('update:modelValue', false)
            },
            //列表单选实现
            handleSelectionChange(selection, val) {
                if(this.isSingleChoice) {
                    this.$refs.tableRef.clearSelection()
                    if (selection.length == 0){
                        this.selectedRow = []
                        return
                    };
                    this.$refs.tableRef.toggleRowSelection(val, true)
                    this.selectedRow = val
                }else {
                    this.selectedRow = selection
                }
            },
            //全选时清空选择
            handleSelectAll(selection) {
                if(this.isSingleChoice) {
                    this.$refs.tableRef.clearSelection()
                    this.selectedRow = []
                }else {
                    this.selectedRow = selection
                }
            },
        },
    }
</script>

<style scoped>

  .dialog-input {
    width: 460px;
  }

  .el-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .el-table {
      margin-top: 10px;
  }

</style>
