
export function getToken() {
  const now = new Date();
  const currentTime = now.toLocaleString();
  if(localStorage.getItem("token") == null) {
    return null;
  }
  if(currentTime >= localStorage.getItem("timeout")) {
    localStorage.removeItem("timeout")
    localStorage.removeItem("tokenCreateTime")
    localStorage.removeItem("requestPublicKey")
    localStorage.removeItem("token")
    return null;
  }else {
    let tokenCreateTime = '1800'
    if(localStorage.getItem("tokenCreateTime") !== null) {
      tokenCreateTime = localStorage.getItem("tokenCreateTime")
    }
    const now = new Date();
    now.setTime(now.getTime() + tokenCreateTime * 1000);
    const timeout = now.toLocaleString();
    localStorage.setItem("timeout",timeout)
  }
  return localStorage.getItem("token")
}

export function setToken(token) {
  let tokenCreateTime = '1800'
  if(localStorage.getItem("tokenCreateTime") !== null) {
    tokenCreateTime = localStorage.getItem("tokenCreateTime")
  }
  const now = new Date();
  now.setTime(now.getTime() + tokenCreateTime * 1000);
  const timeout = now.toLocaleString()
  localStorage.setItem("timeout",timeout)
  return localStorage.setItem("token",token)
}

export function removeToken() {
  localStorage.removeItem("timeout")
  localStorage.removeItem("tokenCreateTime")
  localStorage.removeItem("requestPublicKey")
  return localStorage.removeItem("token")
}
