import request from '@/utils/request'

export default  {
    getStation() {
        return request({
            method: 'get',
            url: '/buiBase/getStation',
        })
    },
    saveEdit(id, name, code, certificateIds) {
        return request({
            method: 'POST',
            url: '/buiBase/editStation',
            data: {
                stationId: id,
                stationName: name,
                stationCode: code,
                certificateIds: certificateIds,
            },
        })
    },
    addStation(data) {
        return request({
            method: 'POST',
            url: '/buiBase/createStation',
            data: data
        })
    },

    delStation(id) {
        return request({
            method: 'GET',
            url: '/buiBase/delStation',
            params: {
                id: id,
            },
        })
    },

}
