<template>
  <el-dialog v-model="visibleDialog" :show-close="false" :fullscreen="fullScreen" 
    custom-class="dialog-for-ky" ref="el_dialog" v-bind="$attrs">

    <template #header="{ close, titleId, titleClass }">
      <div style="display: flex; justify-content: space-between;align-items: center; font-size: 12px;">
        <div :id="titleId" :class="titleClass" style="font-size: 12px;font-weight: bold">{{title}}</div>
        <div style="display: flex;">
          <div v-if="showFullScreen" class="iconfont icon-quanping" style="margin-right: 10px;" type="danger" @click="fullScreen = !fullScreen"></div>
          <div v-if="showClose" @click="close" class="iconfont icon-guanbi"></div>
        </div>
      </div>
    </template>
    
    <template #default>
      <div style="background-color: white;border: 1px solid #cccf">
        <slot></slot>
      </div>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "ky-dialog",
  inheritAttrs: false,
  props: {
    // 对外暴露visible属性，用于显示隐藏弹框
    modelValue: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '默认标题'
    },
    showFullScreen: {
      type: Boolean,
      default: true
    },
    showClose: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    // 通过计算属性，对.sync进行转换，外部也可以直接使用visible.sync
    visibleDialog: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue",val);
      }
    }
  },
  data() {
    return {
      fullScreen: false,
    }
  }
}
</script>

<style lang="scss" scoped>



  
</style>
